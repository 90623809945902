<template>
    <div class="vuetify sub-account-cost-summary" data-testid="sub-account-cost-summary">
        <div class="sub-account-header">
            <div v-if="subAccount.facilityName" class="header-provider-location">{{ subAccount.facilityName }}</div>
            <div class="header-mobile">
                <div v-if="patientName" class="header-label-and-name">
                    <div class="header-services-for">{{ $translate('labels.servicesFor') }}</div>
                    <div class="header-patient-name fs-block">{{ patientName }}</div>
                </div>
                <div v-else class="placeholder"> </div>
                <div v-if="truncatedSubAccountNumber" class="header-account-number vuetify">
                    <base-tooltip location="top" :tip="subAccount.formattedSubAccountNumber">{{  truncatedSubAccountNumber }}</base-tooltip>
                </div>
            </div>
        </div>
        <div class="sub-account-balance">
            <div class="balance-header">
                <div class="balance-header-left">
                    <div class="balance-label-total-amount-due">{{ $translate('labels.totalAmountDue') }}</div>
                    <div v-if="dueDate" class="balance-label-due-by">{{ $translate('labels.by') }} {{ $formatDate(dueDate, 'MM/DD/YYYY') }}</div>
                </div>
                <base-tooltip v-if="showViewBill" location="bottom" :tip="$translate('actions.viewBill')">
                    <div class="view-bill" @click="$emit('viewbill')" :aria-label="$translate('actions.viewBill')">
                        <base-icon class="balance-bill-icon" icon="mdi-file-document-outline"></base-icon>
                    </div>
                </base-tooltip>
            </div>
            <div class="balance-amount-due">{{ $formatCurrency(subAccount.amountDue) }}</div>
        </div>
        <div v-if="visitSummary && visitSummary.totalCharges > 0" class="sub-account-cost-summary-breakdown data-grid">
            <div class="row" :class="patientResponsibilityExpandable || servicesExpandable ? 'expand-icon-padding' : null" v-for="(value, label) in visitSummary" :key="label">
                <div class="col-left col-left-padding">
                    <base-tooltip location="top" :header="$translate(('labels.' + label))" :tip="$translate(('glossary.' + label))">
                            <div class="label">{{ $translate(('labels.' + label)) }}</div>
                    </base-tooltip>
                </div>
                <div class="col-right">{{ $formatCurrency(Number(value)) }}</div>
            </div>
            <div class="row patient-responsibility" :class="[(patientResponsibilityExpandable || servicesExpandable ? 'expandable' : null), (!patientResponsibilityExpandable && servicesExpandable ? 'expand-icon-padding' : null)]" v-if="insuranceBreakdown && Object.keys(insuranceBreakdown).length">
                <div class="col-left" :class="!patientResponsibilityExpandable ? 'col-left-padding' : null">
                    <div v-if="patientResponsibilityExpandable" class="expand-icon-container" @click="expandPatientResponsibility">
                        <base-icon :icon="patientResponsibilityExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"></base-icon>
                    </div>
                    <base-tooltip location="top" :header="$translate('labels.patientResponsibility')" :tip="$translate('glossary.patientResponsibility')">
                        <div @click="expandPatientResponsibility" :class="patientResponsibilityExpandable ? 'clickable' : null" class="label patient-responsibility-text">
                            {{ $translate(('labels.patientResponsibility')) }}
                        </div>
                    </base-tooltip> 
                    <base-icon v-if="hasInsuranceUncoveredAmount" class="alert" icon="mdi-alert-circle-outline"></base-icon>
                </div>
                <div class="col-right col-right-padding">{{ $formatCurrency(patientResponsibility?Number(patientResponsibility):0) }}</div>
            </div>
        </div>
        <div class="sub-account-cost-summary-breakdown data-grid expanded" v-if="patientResponsibilityExpanded">
            <div class="row" :class="label=='labels.patientResponsibility'?'patient-responsibility':''" v-for="(value, label, index) in insuranceBreakdown" :key="value">
                <div class="col-left">
                    <base-tooltip location="top" :header="$translate(label)" :tip="$translate(('glossary.' + label.slice(7)))">
                        <div class="breakdown-label">{{ $translate(label) }}</div>
                    </base-tooltip>
                </div>
                <div class="col-right breakdown-amount">{{ $formatCurrency(Number((value) ? value : 0.00)) }}</div>
            </div>
            <div class="notCovered" v-if="hasInsuranceUncoveredAmount">
                <base-icon class="alert" icon="mdi-alert-circle-outline"></base-icon>
                <div>{{ $translate('labels.notCoveredExplanation') }}</div>
            </div>
            <div class="divider"></div>
            <div class="row patient-responsibility" :key="patientResponsibility">
                <div class="col-left breakdown-label">{{ $translate('labels.patientResponsibility') }}</div>
                <div class="col-right breakdown-amount">{{ $formatCurrency(Number((patientResponsibility) ? patientResponsibility : 0.00)) }}</div>
            </div>
        </div>
        <base-btn v-if="subAccountPaymentsEnabled && subAccount.amountDue && subAccount.amountDue > 0" @click="$emit('pay-sub-account')" class="payment-options-button pay-this-visit button-bigger-text balance-btn v-button-primary" :x-large="$vuetify.display.lgAndUp" :width="$vuetify.display.lgAndUp ? 'unset' : '100%'" tabindex="1">
            {{ $translate('actions.payThisVisit') }}
        </base-btn>
        <base-divider
            class="sub-account-details-sheet-divider"
            data-testid="sub-account-details-sheet-services-divider"
        ></base-divider>
    </div>
</template>

<script>
import _ from 'lodash';
import BaseTooltip from './BaseTooltip.vue';
import BaseIcon from './BaseIcon.vue';
import BaseBtn from './BaseBtn.vue';
import BaseDivider from './BaseDivider.vue';

export default {
    name: 'SubAccountCostSummary',
    components: {
        BaseTooltip,
        BaseIcon,
        BaseBtn,
        BaseDivider,
    },
    props: {
        subAccount: Object,
        dueDate: String,
        patientName: String,
        showViewBill: {
            type: Boolean,
            default: false,
        },
        subAccountPaymentsEnabled: Boolean,
    },
    computed: {
        truncatedSubAccountNumber() {
            if (!this.subAccount.formattedSubAccountNumber || !this.subAccount.subAccountNumber) {
                return null;
            }
            // -1 to account for spaces
            const index = this.subAccount.formattedSubAccountNumber.indexOf(this.subAccount.subAccountNumber) - 1;
            if (index >= 0) {
                return this.subAccount.formattedSubAccountNumber.substring(0, Math.min(index, 20)) + '...' + this.subAccount.subAccountNumber.substring(this.subAccount.subAccountNumber.length - 4);
            }
            return this.subAccount.formattedSubAccountNumber;
        },
        currentBill() {
            return this.$store.getters.currentBill;
        },
        visitSummary() {
            let summary = {};
            let visit = {};
            if (this.subAccount) {
                visit = this.subAccount;
                summary.totalCharges = 0;
                summary.adjustments = 0;
                summary.insuranceCoverage = 0;
                summary.patientPayments = 0;
                if (visit.detailLines) {
                    visit.detailLines.forEach((detailLine) => {
                        if (detailLine.carcCodes) {
                            this.patientResponsibilityExpandable = true
                        }
                        if (Boolean(detailLine.cptCode || detailLine.totalCharge 
                            || detailLine.insuranceAdjustment|| detailLine.providerAdjustment 
                            || detailLine.insurancePaid || detailLine.carcCodes)) {
                            this.servicesExpandable = true;
                        }
                        summary.totalCharges = detailLine.totalCharge ? (Number(detailLine.totalCharge) + summary.totalCharges) : summary.totalCharges;
                        summary.adjustments = detailLine.providerAdjustment ? (Number(detailLine.providerAdjustment) + summary.adjustments) : summary.adjustments;
                        summary.adjustments = detailLine.insuranceAdjustment ? (Number(detailLine.insuranceAdjustment) + summary.adjustments) : summary.adjustments;
                        summary.insuranceCoverage = detailLine.insurancePaid ? (Number(detailLine.insurancePaid) + summary.insuranceCoverage) : summary.insuranceCoverage;
                        summary.patientPayments = detailLine.patientPaid ? (Number(detailLine.patientPaid) + summary.patientPayments) : summary.patientPayments;
                    });
                }
            }
            if (!summary.totalCharges && this.currentBill && this.currentBill.details && this.currentBill.details.visits && this.currentBill.details.visits.length) {
                summary = {};
                visit = _.find(this.currentBill.details.visits, { voucherNumber: this.subAccount.subAccountNumber });
                if (visit && visit.summary) {
                    summary.totalCharges = visit.summary.serviceCharges ? Number(visit.summary.serviceCharges) : 0;
                    summary.adjustments = visit.summary.otherAdjustments ? Number(visit.summary.otherAdjustments) : 0;
                    summary.insuranceCoverage = visit.summary.insuranceContribution ? Number(visit.summary.insuranceContribution) : 0;
                }
            }
            return summary;
        },
        patientResponsibility() {
            const summary = this.visitSummary;
            return summary.totalCharges + (summary.adjustments + summary.insuranceCoverage);
        },
        insuranceBreakdown() {
            if (this.currentBill && this.currentBill.subAccounts && this.currentBill.subAccounts.length) {
                const subAccount = this.subAccount;
                let insuranceBreakdown = {};
                if (subAccount.detailLines) {
                    subAccount.detailLines.forEach((dl) => {
                        if (dl.carcCodes) {
                            dl.carcCodes.forEach((carc) => {
                                let code = carc.carcCode;
                                switch (code) {
                                    case '1':
                                        code = 'labels.deductible';
                                        break;
                                    case '2':
                                        code = 'labels.coinsurance';
                                        break;
                                    case '3':
                                        code = 'labels.copay';
                                        break;
                                    default:
                                        code = 'labels.notCovered';
                                        break;
                                }
                                if (!insuranceBreakdown[code]) {
                                    insuranceBreakdown[code] = 0;
                                }
                                insuranceBreakdown[code] += carc.amount ? (Number(carc.amount)) : 0;
                            });
                        }
                    });
                }
                return {
                    "labels.copay": insuranceBreakdown["labels.copay"],
                    "labels.deductible": insuranceBreakdown["labels.deductible"],
                    "labels.coinsurance": insuranceBreakdown["labels.coinsurance"],
                    "labels.notCovered": insuranceBreakdown["labels.notCovered"],
                };
            }
            return {};
        },
        hasInsuranceUncoveredAmount() {
            return this.insuranceBreakdown['labels.notCovered'] != 0;
        },
    },
    data: () => ({
        patientResponsibilityExpanded: false,
        servicesExpandable: false,
        patientResponsibilityExpandable: false
    }),
    methods: {
        expandPatientResponsibility() {
            if (this.patientResponsibilityExpandable) {
                this.patientResponsibilityExpanded = !this.patientResponsibilityExpanded;
            }
        },
    }
};
</script>

<style lang="scss">
@import './../styles/variables.scss';
    .sub-account-cost-summary {
        display: flex;
        flex-direction: column;
        color: $global-color-font-new !important;

        .sub-account-header {
            display: flex;
            justify-content: space-between;

            // mobile specific
            flex-direction: column;
            margin-bottom: 0.8rem;
            margin-top: 0.9rem;

            .header-provider-location {
                font-size: 2rem;
                font-weight: 500;
                max-width: 80%;
                align-self: center;
                letter-spacing: 0.015rem;
                line-height: 2.4rem;

                // mobile specific
                text-align: center;
                margin-bottom: 2.5rem;
                margin-top: 0.8rem;
            }

            .header-mobile {
                // ex: SERVICES FOR: 
                //     George Burdell               Account...1234
                // mobile includes account number so it needs flex to align correctly
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;

                .header-label-and-name {
                    // ex: SERVICES FOR: 
                    //     George Burdell

                    // left align for mobile
                    text-align: left;
                    line-height: 1.8rem;

                    .header-services-for {
                        // ex: SERVICES FOR:
                        font-size: 1rem;
                        font-weight: 500;
                        letter-spacing: 0.15rem;
                    }

                    .header-patient-name {
                        // ex: George Burdell
                        font-size: 1.6rem;
                        letter-spacing: 0.05rem;
                    }
                }

                .header-account-number {
                    // ex: Account...1234
                    // always right aligned cause only shown on mobile
                    text-align: right;
                    letter-spacing: 0.025rem;
                    font-size: 1.4rem;
                }

                .placeholder {
                    width: 11.5rem;
                }
            }
        }

        .sub-account-balance {
            background-color: #DCDCDC;
            border-radius: $global-radius;
            padding: 1.6rem;
            display: flex;
            flex-direction: column;
            margin-bottom: 2.5rem;

            .balance-header {
                display: flex; // default direction is row
                justify-content: space-between;
                margin-bottom: 2rem;
                line-height: 2rem;

                .balance-label-total-amount-due {
                    font-size: 1.4rem;
                    font-weight: 500;
                    letter-spacing: 0.01rem;
                }

                .base-tooltip {
                    height: fit-content;
                }

                .balance-label-due-by {
                    font-size: 1.2rem;
                    text-align: left;
                    font-weight: 400;
                    letter-spacing: 0.04rem;
                }

                .balance-bill-icon {
                    size: 2.4rem;
                    cursor: pointer;
                }
            }

            .balance-amount-due {
                text-align: center;
                font-size: 4.8rem;
                margin-bottom: 2.4rem;
                font-weight: 400;
            }
        }

        .sub-account-cost-summary-breakdown {
            text-align: left;
            font-size: 1.4rem;
            padding-bottom: 0.9rem;
            letter-spacing: 0.025rem;
            padding-right: 2.4rem;

            .col-left-padding {
                padding-left: 2.4rem;
                padding-right: .6rem;
            }

            .row {
                height: 4rem;
                margin-bottom: 0;

                &.patient-responsibility {
                    font-weight: 500;
                }

                .col-left {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    :nth-last-child(0) {
                        padding: 0 .6rem;
                    }
    
                    .alert {
                        font-size: 1.75rem !important;
                        color: red !important;
                    }

                    .expand-icon-container {
                        height: 4rem;
                        width: 4rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 100%;
                        transition-property: background-color;
                        transition-duration: .2s;
    
                        &:hover {
                            cursor: pointer;
                            background-color: #9e9e9e30;
                        }
    
                        &:active {
                            background-color: #9e9e9e;
                        }
    
                        i.mdi-chevron-up, i.mdi-chevron-down {
                            margin: auto;
                            color: $global-color-font-new !important;
                            font-size: 2.4rem;
                        }
                    }

                    .label, .breakdown-label {
                        text-decoration: underline dotted;
                        padding: .6rem;
    
                        &:hover {
                            background: $global-color-body-bg;
                            border-radius: 5px;
                        }
                    }
                }

                &.expand-icon-padding {
                    padding-left: 1.6rem;
                }

                .col-right {
                    flex: 0;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
            }
        }
        .sub-account-cost-summary-breakdown.expanded {
            font-size: 1.4rem;
            background: $global-color-body-bg;
            padding: 1.5rem 2.5rem 0.5rem 5.7rem;
            margin-bottom: 1.6rem;

            + .payment-options-button {
                margin-bottom: 1.6rem;
            }

            .notCovered {
                padding: 0.8rem;
                border-radius: 5px;
                background-color: $global-color-warning-light;
                flex: 100%;
                display: flex;
                .alert {
                    font-size: 1.75rem !important;
                    color: red !important;
                    padding-left: 0.5rem;
                }
                div {
                    padding-left: 1em;
                    flex: 1;
                }
            }

            .divider {
                margin: .8rem 0;
            }

            &.data-grid .row {
                flex-wrap: wrap;
            }
        }

        .patient-responsibility-text {
            padding: 0 0.6rem;
            width: fit-content;

            &:hover {
                cursor: pointer;
            }
        }

        .payment-options-button {
            margin-top: 0;
            margin-bottom: 2.5rem;
            align-self: center;
            padding: 1rem 2.6rem !important;
            width: 100%;
            height: 3.9rem !important;
            font-size: 1.4rem !important;
        }
    }
</style>