<template>
<span>
	<nav id="nav">
		<div @click="toggleNav()" class="nav-icon" v-if="isLoggedIn" data-testid="nav-toggle"><span></span>&nbsp;</div>
		<div class="nav-content">
			<div class="nav-items-locations" :class="{ spanish: isSpanish }" v-if="isLoggedIn">
				<a @click="goToState('ProvidersSummary')" :class="{ active: (activeTab === 'Home') }" data-testid="nav-dashboard">{{ $translate('dashboard.home') }}</a>
				<a v-if="showMessages" @click="goToState('Messages')" :class="{ active: (activeTab === 'Messages') }" data-testid="nav-messages">{{ $translate('message.plural') }}</a>
				<a @click="goToState('SettingsContact')" class="nav-settings" :class="{ active: (activeTab === 'Settings') }" data-testid="nav-settings-desktop">{{ $translate('setting.plural') }}</a>
				<a @click="goToState('DocumentsPaymentHistory')" :class="{ active: (activeTab === 'Documents') }" data-testid="nav-documents"> {{ $translate('documents.documents') }} </a>
				<div class="nav-settings-mobile">
					<span>{{ $translate('setting.plural') }}</span>
					<a @click="goToState('SettingsContact')" :class="{ active: 'SettingsContact' === route }" data-testid="nav-settings-mobile">{{ $translate('setting.menu.contact') }}</a>
					<a @click="goToState('SettingsSavedPaymentMethods')" :class="{ active: 'SettingsSavedPaymentMethods' === route }" data-testid="nav-settings-saved-payment-methods">{{ $translate('setting.menu.savedPaymentMethods') }}</a>
					<a @click="goToState('SettingsAccountManagement')" :class="{ active: 'SettingsAccountManagement' === route }" data-testid="nav-settings-account-management">{{ $translate('setting.menu.accountManagement') }}</a>
				</div>
			</div>

			<div class="nav-items-actions" :class="{ spanish: isSpanish }" v-if="isLoggedIn">
				<div class="no-padding">
					<a @click="viewNotifications()" class="icon" data-testid="standard-header-notifications">
						<span class="filled-icon-header"><base-icon icon="mdi-bell"></base-icon></span>
						<svg v-if="numberOfNewNotifications" fill="#CB2A23" class='new' width="32" height="32" viewBox="0 0 32 32" data-testid="standard-header-numnotifications">
							<path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16z"></path>
							<text text-anchor="middle" fill="white" font-weight="bold" font-size="19px" x="50%" y="70%">{{ numberOfNewNotifications }}</text>
						</svg>
					</a>
				</div>
				<div>
					<a class="button button-primary" @click="closeNav();payNewBill();" data-testid="pay-new-bill">
						{{ $translate('actions.payNewBill') }}
					</a>
				</div>
				<div>
					<a class="button button-white-out" @click="closeNav();logOut();" data-testid="logout">
						{{ $translate('actions.logout') }}
					</a>
				</div>
			</div>

			<div class="nav-items-actions" v-if="!isLoggedIn">
				<a class="button button-primary" @click="closeNav();logIn();" data-testid="login">
					{{ $translate('actions.login') }}
				</a>
			</div>
		</div>
	</nav>
	<nav v-if="isLoggedIn">
		<div>
			<a class="icon" @click="viewNotifications()">
				<span class="nav-notifications" size="sm"><span class="filled-icon-header"><base-icon icon="mdi-bell"></base-icon></span></span>
				<div class="nav-notifications new-notif">
					<svg v-if="numberOfNewNotifications" fill="#CB2A23" width="32" height="32" viewBox="0 0 32 32">
						<path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16z"></path>
						<text text-anchor="middle" fill="white" font-weight="bold" font-size="19px" x="50%" y="70%">{{ numberOfNewNotifications }}</text>
					</svg>
				</div>
			</a>
		</div>
	</nav>
</span>
</template>

<script>
import _ from 'lodash';

import BaseIcon from './BaseIcon.vue';

export default {
	name: 'StandardHeader',
	components: {
		BaseIcon
	},
	data: () => ({
		showMessages: false,
	}),
    computed: {
        isLoggedIn() {
            return Boolean(this.$store.getters.currentUser);
        },
        numberOfNewNotifications() {
            return this.$store.getters.numNew;
        },
		route() {
			return this.$route.name;
		},
		activeTab() {
			if (this.route) {
				if ((this.route === 'Messages') || (this.route === 'MessageDetails')) {
					return 'Messages';
				} else if ((this.route === 'SettingsContact') || (this.route === 'SettingsAccountManagement') || (this.route === 'SettingsSavedPaymentMethods')) {
					return 'Settings';
				} else if ((this.route === 'DocumentsPaymentHistory') || (this.route === 'DocumentsBillHistory')) {
					return 'Documents';
				} else {
					return 'Home';
				}
			}
		},
		isSpanish() {
			return localStorage.getItem('currentLanguage') === 'es';
		}
	},
	watch: {
		isLoggedIn() {
			if (this.isLoggedIn) {
				this.setUpLogicToRunOnLogin();
			}
		}
	},
    methods: {
		closeNav() {
			this.$store.dispatch('closeNav');
		},
		toggleNav() {
			this.$store.dispatch('toggleNav');
		},
        viewNotifications() {
			this.emitter.emit('notifications:show');
        },
        logIn() {
			const authParams = {
				openSection: 'login',
				state: 'ProvidersSummary'
			};
            const onAuthSuccess = () => {
                this.$router.push({name: 'ProvidersSummary'});
            };
			this.emitter.emit('require-authed-user', {params:authParams, successCallback:onAuthSuccess});
		},
		logOut() {
			if (this.$store.getters.isChatSessionActive) {
				this.emitter.emit('simpleModal:showPrompt', {
					header: this.$translate('dialogs.activeChatWarningHeader'),
					subcontent: this.$translate('dialogs.activeChatWarningSubcontent'),
					intent: 'information',
					meta: 'warning',
					actions: [
						{
							label: this.$translate('actions.cancel')
						},
						{
							label: this.$translate('actions.logout'),
							clickHandler: () => {
								this.emitter.emit('unauthenticate-user', /*showModal*/ true);
							}
						}
					]
				});
			} else {
				this.emitter.emit('unauthenticate-user', /*showModal*/ true);
			}
		},
		setUpLogicToRunOnLogin() {
			this.$store.dispatch('getProviders').then((providers) => {
				this.showMessages = _.some(providers, provider => provider.canMessage);
			}).catch(() => {
				this.showMessages = false;
			});
			this.$store.dispatch('getNotifications');
		},
		payNewBill() {
			this.emitter.emit('newBill:showPrompt');
		},
		goToState(state) {
			this.closeNav();
			this.$router.push({name: state});
		},
	},
	mounted() {
		if (this.$store.getters.currentUser) {
			this.setUpLogicToRunOnLogin();
		}
	},
};
</script>
<style lang="scss">
.filled-icon-header {
	background: rgba(255,255,255,0.3);
	border-radius: 10%;
	padding: 0.5rem 0.9rem 0.8rem 0.9rem;
	margin-top: 1.6rem;
	width: 3.4rem;
	height: 3.4rem;
	i {
		color: white !important;
		font-size: 1.4rem;
	}
}
</style>