<template>
<div id="layout" class="view-layout-transition">
  <div v-if="shouldShowStandardHeader" id="nav-header">
    <standard-header></standard-header>
  </div>
  <div id="full-screen-loading" class="hide-on-print" v-show="$store.getters.showLoading">
    <div class="full-screen-loading-indicator"></div>
  </div>
  <router-view v-show="!$store.getters.showLoading"></router-view>
  <div v-if="$store.getters.config.vueEnv === 'demo'" class="banner">Demo Environment</div>
  <standard-footer v-show="!$store.getters.showLoading && matchedRoute"></standard-footer>
  <div id="bg-mask" @click="$store.dispatch('clickOverlay')"></div>
  <account-verification-modal></account-verification-modal>
  <browser-info-modal></browser-info-modal>
  <change-password-modal></change-password-modal>
  <compose-message-modal v-if="$store.getters.currentUser"></compose-message-modal>
  <confirm-email-modal></confirm-email-modal>
  <confirm-password-modal></confirm-password-modal>
  <confirm-phone-number-modal></confirm-phone-number-modal>
  <contact-modal></contact-modal>
  <feedback-modal></feedback-modal>
  <first-comm-verification-modal></first-comm-verification-modal>
  <login-modal></login-modal>
  <loyal-modal></loyal-modal>
  <my-chart-sso-modal></my-chart-sso-modal>
  <new-bill-modal></new-bill-modal>
  <notifications-modal></notifications-modal>
  <redirect-modal></redirect-modal>
  <simple-modal></simple-modal>
  <terms-of-service-modal></terms-of-service-modal>
  <verification-modal></verification-modal>
</div>
</template>

<script>
import { RouterView } from 'vue-router';
import AccountVerificationModal from './components/AccountVerificationModal.vue';
import BrowserInfoModal from './components/BrowserInfoModal.vue';
import ChangePasswordModal from './components/ChangePasswordModal.vue';
import ComposeMessageModal from './components/ComposeMessageModal.vue';
import ConfirmEmailModal from './components/ConfirmEmailModal.vue';
import ConfirmPasswordModal from './components/ConfirmPasswordModal.vue';
import ConfirmPhoneNumberModal from './components/ConfirmPhoneNumberModal.vue';
import ContactModal from './components/ContactModal.vue';
import FeedbackModal from './components/FeedbackModal.vue';
import FirstCommVerificationModal from './components/FirstCommVerificationModal.vue';
import LoginModal from './components/LoginModal.vue';
import LoyalModal from './components/LoyalModal.vue';
import MyChartSsoModal from './components/MyChartSsoModal.vue';
import NewBillModal from './components/NewBillModal.vue';
import NotificationsModal from './components/NotificationsModal.vue';
import RedirectModal from './components/RedirectModal.vue';
import SimpleModal from './components/SimpleModal.vue';
import StandardFooter from './components/StandardFooter.vue';
import StandardHeader from './components/StandardHeader.vue';
import TermsOfServiceModal from './components/TermsOfServiceModal.vue';
import VerificationModal from './components/VerificationModal.vue';

export default {
  name: 'App',
  components: {
    AccountVerificationModal,
    BrowserInfoModal,
    ChangePasswordModal,
    ComposeMessageModal,
    ConfirmEmailModal,
    ConfirmPasswordModal,
    ConfirmPhoneNumberModal,
    ContactModal,
    FeedbackModal,
    FirstCommVerificationModal,
    LoginModal,
    LoyalModal,
    MyChartSsoModal,
    NewBillModal,
    NotificationsModal,
    RedirectModal,
    SimpleModal,
    StandardHeader,
    StandardFooter,
    TermsOfServiceModal,
    VerificationModal
  },
  watch: {
    $route() {
      let loyalChatbot = document.getElementById('guide');

      if (this.$route && loyalChatbot) {
        /** Hide the Loyal Chatbot help text on most screens
         * since it blocks the user from being able to click
         * on necessary buttons
         */
        let allowedRoutes = ['AccountsSummary', 'ProvidersSummary', 'Landing', 'Home'];
        if (!loyalChatbot.shadowRoot || !loyalChatbot.shadowRoot.getElementById('loyal-launcher-cta')) {
          if (window.FS) {
            window.FS.log('error', 'Loyal Chatbot text overlay id was not found for route '+this.$route.name);
          }
        } else if (allowedRoutes.includes(this.$route.name)) {
          loyalChatbot.shadowRoot.getElementById('loyal-launcher-cta').style.display = 'inline';
        } else {
          loyalChatbot.shadowRoot.getElementById('loyal-launcher-cta').style.display = 'none';
        }
      }
    }
  },
  computed: {
    matchedRoute() {
      return this.$route.matched[this.$route.matched.length - 1];
    },
    shouldShowStandardHeader() {
      // search back to front from matched routes for a definition of `headerType` prop
      for (let i = this.$route.matched.length - 1; i >= 0; i--) {
        const props = this.$route.matched[i].meta;
        const headerType = props.default ? props.default.headerType : props.headerType;
        if (headerType !== undefined) {
          if ('standard' === headerType) {
            return true;
          }
          if ('standardWhenLoggedIn' === headerType) {
            return Boolean(this.$store.getters.currentUser);
          }
          // Something was explicitly passed in that does not map to standard header, so return false and stop searching the matches
          return false;
        }
      }
      return false;
    }
  },
  mounted() {
    this.$store.commit('setInitialHistoryLength', window.history.length);
    this.emitter.on('require-authed-user', ({params, successCallback, errCallback}) => {
      this.$store.dispatch('requireAuthedUser', params).then((user) => {
        if (successCallback) {
          successCallback(user);
        }
      }).catch(() => {
        if (errCallback) {
          errCallback();
        }
      });
    });
    this.emitter.on('unauthenticate-user', (showModal) => {
      this.$store.dispatch('unauthenticateUser', showModal);
    });
    this.emitter.on('switch-language', () => {
      if (localStorage.getItem('currentLanguage') !== 'en') {
            localStorage.setItem('currentLanguage', 'en');
            this.$store.commit('setIsSpanish', false);
            window.location.reload();
        } else {
            localStorage.setItem('currentLanguage', 'es');
            this.$store.commit('setIsSpanish', true);
            window.location.reload();
        }
    });
    this.emitter.on('linkedAccountService:fetchUnverified', (forceRefresh) => {
      this.$store.dispatch('fetchUnverifiedAccounts', forceRefresh);
    });
    /**
     * Update the click value for each link in the Loyal Chatbot
     * because the current ones no longer work. We need to update
     * existings links on refresh and also update new ones
     */
    var observer = new MutationObserver(() => {
      let loyalChatbot = document.getElementById('guide');
        // Make it so the whole page doesn't refresh when the user clicks a link in the chat
      if (loyalChatbot && loyalChatbot.shadowRoot && loyalChatbot.shadowRoot.getElementById('loyal-activity-list')) {
        let allLinks = Array.from(loyalChatbot.shadowRoot.getElementById('loyal-activity-list').getElementsByTagName('a'));
        allLinks.forEach((link) => {
          if (link.hasAttribute('onclick') && link.getAttribute('onclick').includes('deepLinks')) {
            let linkOnClick = link.getAttribute('onclick');
            let getFunction = linkOnClick.split('deepLinks.')[1];
            getFunction = getFunction.split('(')[0];
            link.addEventListener("click", (e) => {
              e.preventDefault();
              e.stopImmediatePropagation();
              this.emitter.emit('loyalActions:' + getFunction);
              return false;
            }, true);
          }
        });
      }
    });
    var checkExist = setInterval(() => {
      let loyalChatbot = document.getElementById('guide');
      if (loyalChatbot) {
        try {
          observer.observe(document, {
            characterDataOldValue: true, 
            subtree: true, 
            childList: true, 
            characterData: true
          });
          observer.observe(loyalChatbot.shadowRoot, {
            characterDataOldValue: true, 
            subtree: true, 
            childList: true, 
            characterData: true
          });
          clearInterval(checkExist);
        } catch {
          // This has an error if loyalChatbot.shadowRoot isn't available yet, but just ignore it and let it load
        }
      }
    }, 500);
    // Loyal Chat lags behind, so set an interval with a timeout to wait for it
    let fixLoyalChatLinks = () => {
      setTimeout(() => {
        clearInterval(checkExist);
      }, 1500);
    };
    // Wait for dom to load then check for Loyal Chat
    document.addEventListener('DOMContentLoaded', fixLoyalChatLinks);
  },
  created() {
    const now = new Date().getTime();
    let favicon16Url = '/favicon-16x16.png';
    let favicon32Url = '/favicon-32x32.png';
    let subdomain = this.$store.getters.subdomain;
    if (subdomain) {
      document.body.classList.add('wait-for-theme');
      favicon16Url = `/api/services/v1/providers/favicon/${subdomain}?&size=16&nocache=${now}`;
      favicon32Url = `/api/services/v1/providers/favicon/${subdomain}?&size=32&nocache=${now}`;
      // Init branding config
      this.$store.dispatch('getBrandingConfig').then((brandingConfig) => {
        // Add stylesheet and favicons to head of page
        const head = document.head;
        const styleLink = document.createElement('link');
        styleLink.rel = 'stylesheet';
        styleLink.href = `/api/services/v1/providers/theme/${subdomain}?nocache=${now}`;

        head.appendChild(styleLink);

        // Keep the branding config in the store
        this.$store.commit('setBrandingConfig', brandingConfig);

        // Extract branded colors
        const primaryColor = brandingConfig.primaryColor;
        const secondaryColor = brandingConfig.secondaryColor;

        // Set the vuetify theme to use the branded colors
        this.$vuetify.theme.themes.light.colors.primary = primaryColor;
        this.$vuetify.theme.themes.dark.colors.primary = primaryColor;
        this.$vuetify.theme.themes.light.colors.secondary = secondaryColor;
        this.$vuetify.theme.themes.dark.colors.secondary = secondaryColor;
      }).catch((message) => {
        if ('INVALID_PARAMETERS' === message.errorCode) {
          // remove wait for theme because there is an invalid subdomain
          document.body.classList.remove('wait-for-theme');
        } else {
          console.error(message);
        }
      });
    }
    // Initialize favicons
    [].slice.call(document.getElementsByTagName('link')).filter(m => m.rel == 'icon').forEach(e => e.remove());

    const head = document.head;
    const link16 = document.createElement('link');
    link16.rel = 'icon';
    link16.type = 'image/png';
    link16.sizes = '16x16';
    link16.href = favicon16Url;
    head.appendChild(link16);
  
    const link32 = document.createElement('link');
    link32.rel = 'icon';
    link32.type = 'image/png';
    link32.sizes = '32x32';
    link32.href = favicon32Url;
    head.appendChild(link32);
  }
};
</script>
<style>
</style>