import http from '../../utils/http';
import _ from 'lodash';
import vueEventTransmitter  from '../../utils/vueEventTransmitter';

import Provider from '../../models/Provider';

const state = {
    providers: [],
    fetched: false,
    syncPromise: null,
    currentProvider: {},
};

const getters = {
    getStoredProviderById: (state) => (id) => {
        return _.first(_.filter(state.providers, function(p){
            return p.id.toString() === id.toString();
        }));
    },
    getAccountByNumber: (state) => (acctNumber) => {
        var _account = null;
        var providers = state.providers;
        _.forEach(providers, function(provider){
            _.forEach(provider.accounts, function(account){
                if (account.accountNumber == acctNumber) {
                    _account = account;
                }
            });
        });
        return _account;
    },
    currentProvider(state) {
        return state.currentProvider;
    },
    providers(state) {
        return state.providers;
    },
};

const mutations = {
    setProviders(state, val) {
        state.providers = val;
    },
    setProvidersFetched(state, val) {
        state.fetched = val;
    },
    setSyncPromise(state, p) {
        state.syncPromise = p;
    },
    providersMerge(state, updatedProviders) {
        var currentProviders = state.providers;
        var _pros = [];

        _.forEach(updatedProviders, function(newProvider){

            var currentProvider = _.find(currentProviders, function(p){
                return p.id === newProvider.id;
            });

            if (newProvider.websites) {
                // see if a protocol was in the url.  if not, add a safe fallback for the provider link to work properly
                newProvider.websites = _.map(newProvider.websites, function(website) {
                    website.url = _.startsWith(website.url, 'http') ? website.url : ('http://' + website.url);
                    return website;
                });
            }

            // if we arent tracking this provider already, add and dont worry about 
            // syncing with an existing one
            if(!currentProvider){
                _pros.push(newProvider);
                return;
            }

            // this provider has already been loaded before
            // sync everything over to the existing provider reference


            // sync accounts
            newProvider.accounts = _.map(newProvider.accounts, function(updatedAccount){

                var matchingAccount = _.find(currentProvider.accounts, function(a){
                    return a.accountId === updatedAccount.accountId;
                });

                return _.omit(_.merge({}, matchingAccount, updatedAccount), '$$hashKey');
            });

            // finally merge together all other fields 
            // exclude the accounts from this, as we have already merged them 
            // inpto newProvider
            _pros.push(new Provider(_.merge({}, _.omit(currentProvider, 'accounts'), newProvider)));
        });
        state.providers = _pros;
    },
    setCurrentProvider(state, val) {
        state.currentProvider = val;
    },
};

const actions = {

    // clear the data known about the providers
    // use sparing and only when there are not
    // other available options to update a specific
    // set of data
    flushProviders({ commit }) {
        commit('setSyncPromise', null);
        commit('setProvidersFetched', false);
        commit('setProviders', []);
    },
    
    // sync fetches the providers from the
    // server but syncs the differences of what we have
    // already loaded and any new data that the server
    // returns
    syncProviders({ commit, getters, state }) {
         let promise = new Promise((resolve, reject) => {
            const patientUserId = getters.currentPatientUserId;
            if (!patientUserId) {
                vueEventTransmitter.emit('unauthedAccessResponse');
                commit('setProvidersFetched', false);
                reject('unable to fetch providers: User is not logged in');
                return;
            }
            http.get(`providers?pid=${patientUserId}`)
                .then(function(resp){
                    if (!(resp && resp.hasData())) {
                        if (resp && resp.data && 'UNAUTHORIZED_ACCESS' === resp.data.errorCode) {
                            vueEventTransmitter.emit('unauthedAccessResponse');
                        }
                        return Promise.reject('invalid response');
                    }
                    
                    // for now, merge handles the sync for us
                    // we may need to optimize this after further testing
                    // debugger;
                    commit('providersMerge', resp.getData().map(raw => new Provider(raw)));
                    resolve(state.providers);
                }).catch(function( err ){
                    commit('setProvidersFetched', false);
                    reject('unable to fetch providers: ' + err);
                });
        });
        commit('setSyncPromise', promise);
        commit('setProvidersFetched', true);
        return promise;
    },

    // use getProviders when you need the providers array 
    // without worrying about the sync status. It will resolve
    // handling that syncing state itself
    getProviders({ state, dispatch }) {
        if(!state.fetched) {
            return dispatch('syncProviders'); 
        }
        return state.syncPromise;
    },

    // get a single providers details
    getProviderById({ getters, dispatch }, id){
        return dispatch('getProviders').then(function(){
            var provider = getters.getStoredProviderById(id);

            // only continue then chain if
            // we have a provider
            if(!provider){
                return Promise.reject('No matching provider found');
            }
            // Set port for local development, only works for one branded provider
            let config = getters.config;
            if (config && config.env === 'development' && !config.isCypress) {
                provider.brandedWalletUrl = provider.brandedWalletUrl;
            }
            return provider;
        }).catch(function( err ) {
            return Promise.reject('Error finding providerById: ' + err);
        });
    },

    // get an account by id
    getAccountById({ state }, accountId) {
        var provider = state.currentProvider;
        let account = null;
        if (provider && provider.accounts) {
            provider.accounts.forEach(function (a) {
                if (a.accountId == accountId) {
                    account = a;
                }
            });
        }
        return account;
    },

    flushAcctActivity({ commit, dispatch, getters}, {acctNumber}) {
        commit('setProvidersFetched', false);
        return dispatch('getProviders').then(function(){
            var account = getters.getAccountByNumber(acctNumber);
            if (account) {
                account.activity = null;
                delete account.activityLimit;
                delete account.activityFetched;
            }
        }).catch(function(err) {
            console.error('Failed to refresh provider data to flush account activity: ' + err);
        });    
    }
};


export default {
    state,
    getters,
    mutations,
    actions,
};
