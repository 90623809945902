<template>
    <div>
        <Modal :canClose="false" class="redirect-modal" :show="showRedirectModal">
            <div class="button-loader-container">
                <base-icon icon="custom:SpinnerWhiteSVG" alt="Loading..."></base-icon>
            </div>
            <span class="redirecting-modal-text" v-html="$translate('branding.redirectingModalText')"></span>
        </Modal>
        <Modal :show="redirectError && true">
            <span class="redirect-error-message" v-html="$translate('branding.redirectErrorMessage')"></span>
        </Modal>
    </div>
</template>

<script>
import Modal from './Modal.vue';
import BaseIcon from './../components/BaseIcon.vue';

export default {
    name: 'RedirectModal',
    data() {
        return {
            showRedirectModal: false,
            redirectError: false,
            newTab: null,
        };
    },
    components: {
        Modal,
        BaseIcon
    },
    computed: {
        currentProvider() {
            return this.$store.getters.currentProvider;
        }
    },
    methods: {
        redirect(options) {
            const delaySeconds = options.delaySeconds || 1;
            this.emitter.emit('redirect:modal');
            Promise.all([
                this.$store.dispatch('prepBrandingSSO', {
                    providerId: options.provider.id,
                    context: options.context || {},
                    ssoType: 'BRANDING'
                }),
                setTimeout(() => {}, delaySeconds * 1000),
            ]).then((results) => {
                var token = results[0];
                const ssoUrl = options.provider.brandedWalletUrl + '/#/sso/token/' + token;
                this.newTab = open(ssoUrl, '_blank');
            }).catch((error) => {
                this.emitter.emit('redirect:error', error);
            }).finally(() => {
                setTimeout(() => {
                    this.emitter.emit('redirect:complete', options);
                }, delaySeconds * 3000);
            });
        },
        stateGo(options) {
            if (options.stateGo) {
                if (options.stateGo.toParams && !options.stateGo.toParams.providerId  && this.currentProvider.id) {
                    options.stateGo.toParams.providerId = this.currentProvider.id;
                }
                let name = options.stateGo.to;
                if (!name) {
                    name = options.stateGo.to;
                }
                this.$router.replace({
                    name: name,
                    params: options.stateGo.toParams,
                });
            }
            return Promise.resolve();
        },
        emit(options) {
            if (Array.isArray(options.emit)) {
                var emitDestination = options.emit[0];
                var emitData = options.emit[1];
                // wait a second to emit so that any listeners in the new tab can be set up (was having issues with the PlanReceiptCtrl)
                return setTimeout(() => { this.emitter.emit(emitDestination, emitData) }, 1000);
            } else {
                return Promise.resolve();
            }
        }
    },
    created() {
        this.emitter.on('redirect:modal', () => {
            this.showRedirectModal = true;
        });

        this.emitter.on('redirect:tab', (options) => {
            if (options.context && options.context.goToCurrentStateWithCurrentParams) {
                this.emitter.emit('redirect:withCurrentState', options);
            } else {
                this.redirect(options);
            }
        });


        this.emitter.on('redirect:complete', (options) => {
            return this.stateGo(options).then(this.emit(options)).then(() => {
                this.showRedirectModal = false;
            }).catch((error) => {
                this.emitter.emit('redirect:error', error);
            });
        });
        
        this.emitter.on('redirect:error', (error) => {
            this.redirectError = error || 'unknown error';
            this.showRedirectModal = false;
            if (this.newTab) {
                this.newTab.close();
            }
            console.error('redirect error: ' + JSON.stringify(error));
        });
    },
};

</script>

<style lang="scss">
    .redirect-modal {
        .modal-content-container {
            background: transparent;
            color: white;
            font-size: 2em;
            font-weight: bolder;
            .button-loader-container {
                display: block;
                margin: 0 auto;
            }
        }
    }
</style>