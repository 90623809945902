<template>
	<modal :show="isVisible" @close="hidePrompt()" class="account-verification-modal-container">
		<div class="account-verification-modal">

            <div class='choose-account-section' v-show="!showVerifyForm">
                <h2 class="modal-header h-style-2">{{ $translate('encounterLinking.whichPatient') }}</h2>

                <div class="account-listing">
                    <button class="u-truncate linkable-account" v-for="(account, index) in accounts" :key="index" @click="selectAccount(account)">
                        {{ $translate(account.patientName || account.guarantorName) }}
                    </button>
                </div>
            </div>

            <div class='acct-verify-form' v-show="showVerifyForm">
                <form name="forms.verifyAcctForm" @submit.prevent="verifyAcct()" novalidate >
                    <h2 class="modal-header h-style-2" v-html="header"></h2>
                    <h3 class="modal-subheader h-style-4" v-html="subheader"></h3>

                    <div class="interaction-feedback" v-show="submitErrorMessage" v-html="submitErrorMessage"></div>

                    <div class="input-area-wrapper"
                        :class="{errored: forms.verifyAcctForm.birthdate.$invalid && (forms.verifyAcctForm.$submitted || forms.verifyAcctForm.birthdate.$error.invalidDate || forms.verifyAcctForm.birthdate.$error.futureDate)}">

                        <div class="input-area-field-wrapper">
                            <input v-model="fields.birthdate" type="text" pastdate required maxlength="10" autocomplete="off" class="fs-block"
                                @blur="validateBirthdate()" @keypress="birthdateKeypress($event)" @input="birthdateInput($event)"
                                name="birthdate" :placeholder="$translate('encounterLinking.birthdatePlaceholder')"/>
                        </div>

                        <div class="input-area-feedback">
                            <div v-show="forms.verifyAcctForm.$submitted || forms.verifyAcctForm.birthdate.$error.invalidDate || forms.verifyAcctForm.birthdate.$error.futureDate">
                                <div v-if="forms.verifyAcctForm.birthdate.$error.required" class="vue-message">
                                    {{ $translate('errors.genericRequired') }}
                                </div>
                                <div v-else-if="forms.verifyAcctForm.birthdate.$error.incomplete" class="vue-message">
                                    {{ $translate('errors.incompleteBirthdate') }}
                                </div>
                                <div v-else-if="forms.verifyAcctForm.birthdate.$error.invalidDate" class="vue-message">
                                    {{ $translate('errors.invalidDate') }}
                                </div>
                                <div v-else-if="forms.verifyAcctForm.birthdate.$error.futureDate" class="vue-message">
                                    {{ $translate('errors.futureBirthdate') }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="input-area-wrapper"
                        :class="{errored: (forms.verifyAcctForm.SSN.$invalid) && forms.verifyAcctForm.$submitted}">

                        <div class="input-area-field-wrapper">
                            <input v-model="fields.SSN" type="text" name="SSN" required class="fs-block" minlength="4" maxlength="4" inputmode="decimal"
                                @blur="validateSSN()" :placeholder="$translate('encounterLinking.SSNPlaceholder')" autocomplete="off"/>
                        </div>

                        <div class="input-area-feedback">
                            <div class="vue-messages">
                                <div v-if="forms.verifyAcctForm.SSN.$error.required" class="vue-message">
                                    {{ $translate('errors.genericRequired') }}
                                </div>
                                <div v-else-if="forms.verifyAcctForm.SSN.$error.minlength" class="vue-message">
                                    {{ $translate('encounterLinking.incompleteSSN') }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="currAcctInfo.authType=='patient'" class="SSN-help-text explanation-text" v-html="$translate('encounterLinking.ssnHelpInfo')"></div>

                    <button class="button-primary button-callout submit-button" :disabled="linkBlocked">{{ submitButtonText }}</button>
                </form>
            </div>
		</div>
	</modal>
</template>
<script>

import _ from 'lodash';
import moment from 'moment';

import Modal from './Modal.vue';

export default {
    name: 'AccountVerificationModal',
    components: {
        Modal,
    },
    data: () => ({
        accounts: [],
        currAcctInfo: {
            authType: null,
            id: null,
            patientName: null,
            guarantorName: null,
            providerGroup: null,
        },
        fields: {
            birthdate: null,
            SSN: null,
        },
        forms: {
            verifyAcctForm: {
                $submitted: false,
                birthdate: {
                    $error: {
                        futureDate: false,
                        invalidDate: false,
                    },
                    $invalid: false,
                },
                SSN: {
                    $error: {
                        required: false,
                        minlength: false,
                    },
                    $invalid: false,
                },
            },
        },
        intendedScodeToLinkAndPay: null,
        isVisible: false,
        linkBlocked: false,
        showVerifyForm: true,
        submitting: false,

        header: null,
        subheader: null,
        submitErrorMessage: null,
        submitButtonText: null,
    }),
    computed: {
        getBillToLinkAfterVerifyFromMasterLinkIdAndSecureCode() {
            return this.$store.getters.getBillToLinkAfterVerifyFromMasterLinkIdAndSecureCode;
        },
        getHasUnverifiedAccountWithEncounterGroupId() {
            return this.$store.getters.getHasUnverifiedAccountWithEncounterGroupId;
        },
        getNewAccountsSecureCodesFromMasterLinkId() {
            return this.$store.getters.getNewAccountsSecureCodesFromMasterLinkId;
        },
        FS() {
            return this.$store.getters.getFullstory;
        }
    },
    methods: {
        birthdateKeypress(event) {
            if (isNaN(Number(event.key)) && event.key !== '/') {
                event.preventDefault();
                return;
            }
        },
        birthdateInput(e) {
            if (!this.fields.birthdate) {
                return;
            }
            if (e.inputType ===  'deleteContentBackward') {
                return;
            }
            let unformattedBirthdate = this.fields.birthdate;
            unformattedBirthdate = unformattedBirthdate.replace(/\D/g,'');
            if (unformattedBirthdate.length > 8) {
                unformattedBirthdate = unformattedBirthdate.slice(0, 8);
            }
            if (unformattedBirthdate.length >= 1 && parseInt(unformattedBirthdate.charAt(0)) > 1) {
                unformattedBirthdate = '0' + unformattedBirthdate;
            }
            // if we have more characters after where the first slash would go, or if the original input had a slash at the end, put the slash back
            if (unformattedBirthdate.length > 2 || (this.fields.birthdate.length === 3 && unformattedBirthdate.length === 2)) {
                unformattedBirthdate = unformattedBirthdate.slice(0, 2) + '/' + unformattedBirthdate.slice(2);
            }
            // If there are more than 3 numbers eg. 02/4 -> and the 3rd number is larger than 3, or if its 3, and there's a 4th number that's larger than 1, insert a 0 before the 3rd digit since anything above 32 can't be a day value 
            if (unformattedBirthdate.length >= 4 && (parseInt(unformattedBirthdate.charAt(3)) > 3 || (parseInt(unformattedBirthdate.charAt(3)) === 3 && unformattedBirthdate.length > 4 && parseInt(unformattedBirthdate.charAt(4)) > 1))) {
                unformattedBirthdate = unformattedBirthdate.slice(0, 3) + '0' + unformattedBirthdate.slice(3);
            }
            // if we have more characters after where the last slash would go, or if the original input had a slash at the end, put the slash back
            if (unformattedBirthdate.length > 5 || (this.fields.birthdate.length === 6 && unformattedBirthdate.length === 5)) {
                unformattedBirthdate = unformattedBirthdate.slice(0, 5) + '/' + unformattedBirthdate.slice(5);
            }
            this.fields.birthdate = unformattedBirthdate;
            this.$forceUpdate(); // temp hack to get it to refresh
        },
        hidePrompt() {
            this.FS.event('account-linking verify-modal-interaction closed');
            this.isVisible = false;
        },
        makePossessive(noun) {
            return (noun.charAt(noun.length-1).toLowerCase() === 's') ? noun + '\'' : noun + '\'s';
        },
        reset() {
            this.fields = {};
            this.fields.birthdate = '';
            this.fields.SSN = '';

            if (this.forms && this.forms.verifyAcctForm) {
                this.setPristine('verifyAcctForm');
            }

            this.submitButtonText = this.$translate('encounterLinking.verifyInformation');
            this.submitting = false;
            this.submitErrorMessage = '';
            this.linkBlocked = false;
        },
        selectAccount(acct) {
            let linkName; 
            if (acct.authType=='guarantor'){
                linkName=acct.guarantorName;
                this.subheader = this.$translate('encounterLinking.verifyAcctSubtitleGuarantor', { patientName: this.makePossessive(acct.patientName), providerGroup: acct.providerGroup });
            } else if (acct.authType=='patient'){
                linkName=acct.patientName;
                this.subheader = this.$translate('encounterLinking.verifyAcctSubtitlePatient', { patientName: this.makePossessive(acct.patientName), providerGroup: acct.providerGroup });
            }
            this.currAcctInfo = acct;
            this.header = this.$translate('encounterLinking.verifyAcctTitle', { linkingName: linkName }); 
            this.showVerifyForm = true;
        },
        setPristine(formName) {
            Object.keys(this.forms[formName]).forEach((fieldKey) => {
                if (typeof this.forms[formName][fieldKey] !== 'object') {
                    return;
                }
                const field = this.forms[formName][fieldKey];
                field.$dirty = false;
                field.$focused = false;
                field.$invalid = false;
                field.$active = false;
            });
            this.forms[formName].$invalid = false;
            this.forms[formName].$submitted = false;
        },
        validateBirthdate() {
            this.forms.verifyAcctForm.birthdate.$error = {};
            if (!this.fields.birthdate) {
                this.forms.verifyAcctForm.birthdate.$error.required = true;
            // generic formatting regex to show generic error
            } else if (!this.fields.birthdate.match(/\d\d\/\d\d\/\d\d\d\d/)) {
                this.forms.verifyAcctForm.birthdate.$error.incomplete = true;
            // more specific regex for valid birthdates (restricts year to 1900 minimum)
            } else if (!this.fields.birthdate.match(/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/) || !moment(this.fields.birthdate).isValid()) {
                this.forms.verifyAcctForm.birthdate.$error.invalidDate = true;
            } else if (moment(this.fields.birthdate).isAfter(moment())) {
                this.forms.verifyAcctForm.birthdate.$error.futureDate = true;
            }
            this.forms.verifyAcctForm.birthdate.$invalid = this.forms.verifyAcctForm.birthdate.$error.incomplete
                || this.forms.verifyAcctForm.birthdate.$error.required
                || this.forms.verifyAcctForm.birthdate.$error.invalidDate
                || this.forms.verifyAcctForm.birthdate.$error.futureDate;
        },
        validateForm() {
            this.validateBirthdate();
            this.validateSSN();
            this.forms.verifyAcctForm.$invalid = this.forms.verifyAcctForm.birthdate.$invalid || this.forms.verifyAcctForm.SSN.$invalid;
        },
        validateSSN() {
            this.forms.verifyAcctForm.SSN.$error = {};
            this.forms.verifyAcctForm.SSN.$error.required = !Boolean(this.fields.SSN);
            this.forms.verifyAcctForm.SSN.$error.minlength = !Boolean(this.fields.SSN && this.fields.SSN.length >= 4);
            this.forms.verifyAcctForm.SSN.$invalid = this.forms.verifyAcctForm.SSN.$error.required || this.forms.verifyAcctForm.SSN.$error.minlength;
        },
        verifyAcct() {
            this.validateForm();
            this.forms.verifyAcctForm.$submitted = true;
            if (this.forms.verifyAcctForm.$invalid || this.submitting) {
                if (this.forms.verifyAcctForm.$invalid){
                    this.FS.log('error', 'account-linking verify-submission INVALID_FORM');
                }
                return;
            }

            this.submitting = true;
            this.submitButtonText = this.$translate('encounterLinking.verifyingInformation');

            var formattedBirthdate = this.$formatDate(this.fields.birthdate, 'yy-MM-dd'),
                numberOfNewAccts = this.getNewAccountsSecureCodesFromMasterLinkId(this.currAcctInfo.id).length,
                newAccountsScodes = this.getNewAccountsSecureCodesFromMasterLinkId(this.currAcctInfo.id),
                billToLinkAfterVerify = this.getBillToLinkAfterVerifyFromMasterLinkIdAndSecureCode(this.currAcctInfo.id, this.intendedScodeToLinkAndPay),
                haveAnyMasterLinksBeenVerifiedYet = this.$store.getters.haveAnyMasterLinksBeenVerifiedYet,
                successHeader = '',
                subcontent = '',
                buttonText = '',
                patientName = this.currAcctInfo.patientName || this.currAcctInfo.guarantorName;


            this.FS.event('account-linking post-verify', { 
                newAccountsCount: numberOfNewAccts,
                newAccountsProviderGroup: this.currAcctInfo.providerGroup,
                intentToPayScode: this.intendedScodeToLinkAndPay
            });

            if (this.intendedScodeToLinkAndPay && newAccountsScodes.includes(this.intendedScodeToLinkAndPay.toUpperCase())){

                successHeader = this.$translate('encounterLinking.verificationHeader');
                subcontent = this.$translate('encounterLinking.verificationSubheader', { patientName: this.makePossessive(patientName), providerGroup: this.currAcctInfo.providerGroup });
                buttonText = this.$translate('encounterLinking.continueToPay');

            } else if (numberOfNewAccts === 0) {
                if (!haveAnyMasterLinksBeenVerifiedYet) {
                    subcontent = this.$translate('encounterLinking.verificationSubheader', { patientName: this.makePossessive(patientName), providerGroup: this.currAcctInfo.providerGroup });
                } else {
                    subcontent = this.$translate('encounterLinking.proactiveExperiencedVerificationSubheader', { patientName: patientName, providerGroup: this.currAcctInfo.providerGroup });
                }
                successHeader = this.$translate('encounterLinking.proactiveVerificationHeader', {patientName : patientName});
                buttonText = this.$translate('encounterLinking.close');

            } else if (numberOfNewAccts === 1) {
                successHeader = this.$translate('encounterLinking.verificationHeader');
                subcontent = this.$translate('encounterLinking.verificationSubheader', { patientName: this.makePossessive(patientName), providerGroup: this.currAcctInfo.providerGroup });
                buttonText = this.$translate('actions.viewAccount');
            } else {
                successHeader = this.$translate('encounterLinking.verificationHeaderPlural');
                subcontent = this.$translate('encounterLinking.verificationSubheader', { patientName: this.makePossessive(patientName), providerGroup: this.currAcctInfo.providerGroup });
                buttonText = this.$translate('actions.viewAccounts');
            }

            this.$store.dispatch('authenticateAccount', { masterLinkId: this.currAcctInfo.id, fieldOneValue: formattedBirthdate, fieldTwoValue: this.fields.SSN })
                .then(() => {
                    this.hidePrompt();

                    this.FS.log('log', 'success account-linking verify-submission processed');

                    this.emitter.emit('simpleModal:showPrompt', {
                        header: successHeader,
                        subcontent: subcontent,
                        intent: 'success',
                        actions: [{
                            label: buttonText,
                            clickHandler: () => {

                                if (billToLinkAfterVerify) {

                                    this.$store.dispatch('getAccountBill', billToLinkAfterVerify).then(() => {
                                        this.$router.push({name: 'BillSummary'});
                                    }).catch(() => {
                                        console.error('Unable to get the ebill');
                                        this.$router.go({name: 'ProvidersSummary'});
                                    });

                                    // after we go to this bill don't go directly
                                    // to paying this bill again on other tries
                                    if (this.intendedScodeToLinkAndPay === billToLinkAfterVerify){
                                        this.intendedScodeToLinkAndPay = null;
                                    }
                                } else {
                                    //force reload because we may already be on this state and the data will need to be re-fetched
                                    this.$router.go({name: 'ProvidersSummary'});
                                }
                            }
                        }]
                    });
                })
                .catch((resp) => {
                    if ('LINK_BLOCKED' === resp.errorCode) {
                        this.submitErrorMessage = this.$translate('encounterLinking.verificationBlocked', { patientName: this.makePossessive(patientName) });
                        this.linkBlocked = true;
                    } else {
                        this.submitErrorMessage = this.$translate('encounterLinking.verificationFailed', { patientName });
                    }
                    this.FS.log('error', 'account-linking verify-submission ' + resp.errorCode);

                })
                .finally(() => {
                    this.submitting = false;
                    this.submitButtonText = this.$translate('encounterLinking.verifyInformation');
                });
        },
    },
    created() {
        this.emitter.on('accountVerification:prompt', () => {
            var linkedAccts = this.$store.getters.unverifiedAccounts;

            this.reset();

            this.FS.event('account-linking verify-modal-interaction opened');
            this.FS.log('log', 'account-linking verify-modal-interaction patients-presented-count ' + linkedAccts.length);

            if (linkedAccts.length === 1) {
                this.selectAccount(linkedAccts[0]);
            } else {
                this.accounts = _.chain(linkedAccts)
                    .sortBy('patientName')
                    .value();
                this.showVerifyForm = false;
            }

            this.isVisible = true;
        });

        this.emitter.on('accountVerification:prompt:proceedToPay', (scodeToPay) => {

            this.intendedScodeToLinkAndPay = scodeToPay;

            // let's make sure we have unverified before continuing
            // note: if we find the user has unverified accounts we are going
            // to show them regardless if the scode is attached to one
            // of them. B/c if they don't have an account associated with the
            // scode from the ebill, it's likely they will never get to this
            // point b/c the ebill flow will likely find the bill available to
            // the user.
            this.$store.dispatch('fetchUnverifiedAccounts').then(() => {
                if (this.getHasUnverifiedAccountWithEncounterGroupId()){
                    this.emitter.emit('accountVerification:prompt');
                }
            });
        });
    },
};
</script>
<style lang="scss">
</style>