<template>
<div class="providers-summary-content vuetify">
    <div v-show="!noProviders" class="page-title">{{ $translate('dashboard.dashboard') }}</div>
    <div v-if="noProviders">
        <no-providers  @findBillLink="findBillBottomSheet = true"></no-providers>
    </div>
    <div v-else class="providers-summary-cards">
        <div v-for="provider in providers" :key="provider.id" @click="goToAccountSummary(provider.id)">
            <provider-summary-card
                :providerName="provider.name"
                :providerId="provider.id"
                :amountDue="provider.balance"
                :numAccounts="provider.accounts.length"
                :numPastDue="provider.accounts.filter(a => a.isPastDue).length"
                clickable
            >
            </provider-summary-card>
        </div>
    </div>
</div>
</template>
<script>

import ProviderSummaryCard from './ProviderSummaryCard.vue';
import NoProviders from './NoProviders.vue';

export default {
    name: 'ProvidersSummaryContent',
    components: {
        ProviderSummaryCard,
        NoProviders,
    },
    computed: {
        providers() {
            return this.$store.getters.providers;
        },
        noProviders() {
            return this.providers.length === 0;
        }
    },
    methods: {
        async loadProviders() {
            this.$store.commit('setShowLoading', true);
            try {
                await Promise.all([this.$store.dispatch('syncProviders'), this.$store.dispatch('fetchUnverifiedAccounts')]);
            } catch {
                this.$store.commit('setShowLoading', false);
            }

            if (this.providers.length === 1) {
                this.$router.push({
                    name: 'AccountsSummary',
                    params: { providerId: this.providers[0].id },
                });
            } else {
                this.$store.dispatch('refreshLinkableAccountsNotificationBanner');
                this.$store.commit('setShowLoading', false);
            }
        },
        goToAccountSummary(providerId) {
            this.$router.push({
                name: 'AccountsSummary',
                params: { providerId: providerId },
            });
        },
        refreshEmailAndPhoneNotificationBanners() {
            this.$store.dispatch('refreshEmailAndPhoneNotificationBanners');
        },
        refreshLinkableAccountsNotificationBanner() {
            this.$store.dispatch('refreshLinkableAccountsNotificationBanner');
        },
    },
    mounted() {
        this.emitter.on('accountVerification:updated', this.refreshLinkableAccountsNotificationBanner);
        this.emitter.on('userSettings:updated', this.refreshEmailAndPhoneNotificationBanners);
        this.$store.dispatch('refreshEmailAndPhoneNotificationBanners');
        this.loadProviders();
    },
    destroyed() {
        this.emitter.off('accountVerification:updated', this.refreshLinkableAccountsNotificationBanner);
        this.emitter.off('userSettings:updated', this.refreshEmailAndPhoneNotificationBanners);
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.providers-summary-content {
    .providers-summary-cards {
        @include wider-than-tablet {
            display: flex;
            flex-wrap: wrap;
        }
    }
    a {
        text-decoration: none;
    }
}

</style>