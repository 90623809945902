<template>
    <v-radio-group :modelValue="modelValue" v-bind="$attrs" mandatory class="base-radio-group">
        <v-radio
            v-for="option in options"
            :color="$vuetify.theme.themes.light.colors.primary"
            :key="option.key"
            :value="option.key"
            false-icon="mdi-radiobox-blank"
            true-icon="mdi-radiobox-marked"
            class="base-radio"
            @click="toggle(option.key)"
        >
            <template v-slot:label>
                <div class="label">
                    <div class="title">{{ option.title }}</div>
                    <div class="subtitle">{{ option.subtitle }}</div>
                </div>
            </template>
        </v-radio>
    </v-radio-group>
</template>

<!--
    options = [{key, title, subtitle}]
    <base-radio-group v-model="model" :options="options"></base-radio-group>
-->

<script>
export default {
    name: 'BaseRadioGroup',
    props: {
        options: {
            type: Array,
            default: null
        },
        modelValue: {
            default: null
        }
    },
    methods: {
        toggle(value) {
            this.$emit('update:modelValue', value);
        }
    }
}
</script>
<style lang="scss">
@import '../styles/variables.scss';
.base-radio {
    padding-bottom: 0.8rem;
    &:last-of-type {
        padding-bottom: 0;
    }
    .v-icon {
        font-size: 2.4rem;
        margin-left: 1rem;
        margin-top: -1rem;
    }
    .label {
        margin-left: 1.6rem;
        .title {
            color: $global-color-font-new;
            padding-left: 0;
            font-size: 1.4rem;
            font-weight: 500;
            letter-spacing: 0.046rem;
            line-height: 1.6rem;
            padding-right: 0;
            @include wider-than-tablet {
                font-size: 1.6rem;
                letter-spacing: 0.014rem;
                line-height: 2.4rem;
            }
        }
        .subtitle {
            color: $global-color-font-new;
            padding-left: 0;
            font-size: 1.2rem;
            letter-spacing: 0.011rem;
            line-height: 1.6rem;
            @include wider-than-tablet {
                font-size: 1.4rem;
                letter-spacing: 0.01rem;
                line-height: 2.4rem;
            }
        }
    }
}
</style>