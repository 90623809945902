<template>
<div>
  <div id="app-wrap">
    <div id="main-content">
      <div class="content">
        <div class="landing-content">

          <!-- Logo, scode, and amount section -->
          <div class="landing-entry-container">
            <div class="landing-entry-content">
              <div class="landing-logo">
                <patientco-logo color="white" id="landing-logo"></patientco-logo>
                <div style="clear:both"></div>
                <h1 class="header-hidden">Patientco</h1>
                <patientco-text color="'white'" id="landing-text"></patientco-text>
              </div>

              <div class="scode-amount-entry">

                <div class="scode-amount-instructions">
                  {{ $translate('landing.enterBills') }}
                </div>

                <div>
                  <find-bill-container></find-bill-container>
                </div>

                <div class="scode-amount-help u-center" @click="needHelp()">
                  {{ $translate('actions.needHelp') }}
                </div>

                <div class="scode-amount-help u-center" 
                  v-if="showSwitchLanguage"
                  @click="switchLanguage()">
                  {{ $translate('actions.switchLanguage') }}
                </div>
              </div>
            </div>
          </div>


          <div class="landing-secondary-content">

            <!-- sub actions - floating contact and login links -->
            <div class="landing-sub-actions">
              <button class="button-inline landing-login" @click="logIn()" data-testid="landing-login">
                {{ $translate('actions.login') }}
              </button>
              <button class="button-inline landing-contactus" @click="onClickContactUs()" data-testid="landing-contactus">
                {{ $translate('actions.contactUs') }}
              </button>
            </div>

            <!-- Marketing content section -->
            <div class="landing-info-content">
              <div v-html="landingPageText" data-testid="landing-page-text"></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script>
import FindBillContainer from './../components/FindBillContainer.vue';
import PatientcoLogo from './../components/PatientcoLogo.vue';
import PatientcoText from './../components/PatientcoText.vue';
import StandardFooter from './../components/StandardFooter.vue';

export default {
  name: 'Landing',
  components: {
    FindBillContainer,
    PatientcoLogo,
    PatientcoText,
    StandardFooter,
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    brandingConfig() {
      return this.$store.getters.brandingConfig;
    },
    showLoading() {
      return this.$store.getters.showLoading;
    },
    isSpanish() {
      return this.$store.getters.isSpanish;
    },
    subdomain() {
      return this.$store.getters.subdomain;
    },
    showSwitchLanguage() {
      return true;
    },
    landingPageText() {
      if (this.brandingConfig) {
        if (this.isSpanish) {
          if (this.brandingConfig.spanishLandingPageText) {
            return this.brandingConfig.spanishLandingPageText;
          }
        }
        if (this.brandingConfig.landingPageText) {
          return this.brandingConfig.landingPageText;
        }
      }
      return this.$translate('landing.marketingBlurb');
    }
  },
  methods: {
    logIn() {
      const authParams = {
        openSection: 'login',
        state: 'ProvidersSummary'
      };
      const onAuthSuccess = () => {
        this.$router.push({'name': 'ProvidersSummary'});
      };
      this.emitter.emit('require-authed-user', {params:authParams, successCallback:onAuthSuccess});
    },
    needHelp() {
        let needHelpSrc = 'assets/images/needhelp.png';
        if (this.brandingConfig && this.brandingConfig.needHelpImage) {
            needHelpSrc = this.brandingConfig.needHelpImage;
        } else if (this.brandingConfig) {
            needHelpSrc = 'assets/images/needhelpbranded.png';
        }
        this.emitter.emit('simpleModal:showPrompt',{
            header: this.$translate('landing.needHelpHeader'),
            subcontent: this.$translate('landing.needHelpContent') +
                '<br /><br /><img class="needhelp" src="' + needHelpSrc + '"/>',
            actions: [{
                label: this.$translate('actions.close'),
                includeClasses: 'button-primary'
            }]
        });
    },
    onClickContactUs() {
      this.emitter.emit('contact:show');
    },
    switchLanguage() {
      this.emitter.emit('switch-language');
    },
  },
  mounted() {
    this.$store.dispatch('showLoadingIfNecessary');
    if ('ForgotPassword' === this.currentState) {
      const authParams = {
        openSection: 'resetPassword',
      };
      const onAuthSuccess = () => {
        this.$router.push({'name': 'ProvidersSummary'});
      };
      this.emitter.emit('require-authed-user', {params:authParams, successCallback:onAuthSuccess});
    }
    if (this.currentUser) {
      this.$router.push({'name': 'ProvidersSummary'});
    }
  },
};
</script>
<style lang="scss" scoped>
</style>