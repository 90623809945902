import http from '../../utils/http';

const state = {
    currentPaymentData: {
        provider: null,
        account: null,
        estimate: null,
        subAccount: null
    },
    isGuestPay: false,
    guestPayFormData: {
        email: null,
        acceptanceOfTerms: null
    }
};

const getters = {
    currentPaymentData(state) {
        return state.currentPaymentData;
    },
    isGuestPay(state) {
        return state.isGuestPay;
    },
    guestPayFormData(state) {
        return state.guestPayFormData;
    }
};

const mutations = {
    setCurrentPaymentData(state, val) {
        state.currentPaymentData = val;
    },
    setIsGuestPay(state, val) {
        state.isGuestPay = val;
    },
    setGuestPayFormData(state, val) {
        state.guestPayFormData = val;
    }
};

const actions = {
    postApplePayToken({}, { amount, date, applePayToken, secureCode, paymentType, estimateId, voucherNumber }) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { amount, date, applePayToken, secureCode, paymentType, estimateId, voucherNumber },
            url: 'payments/applePayToken',
        });
    },
    fetchPaymentPlanDetails({}, planId) {
        return http.getDataOrThrow({
            method: 'GET',
            url: `paymentPlans/${planId}`,
        });
    },
    updatePaymentForm({}, { planId, paymentFormId }) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { planId:parseInt(planId), paymentFormId },
            url: 'paymentPlans/updateMethod',
        });
    },
    fetchPaymentDetails({}, paymentId) {
        return http.getDataOrThrow({
            method: 'GET',
            url: `payments/${paymentId}`,
        });
    },
    fetchPaymentAgreementDetails({}, paymentAgreementId) {
        return http.getDataOrThrow({
            method: 'GET',
            url: `payments/paymentAgreement/${paymentAgreementId}`,
        });
    },
    cancelPayment({}, paymentId) {
        return http.getDataOrThrow({
            method: 'DELETE',
            data: { paymentId },
            url: `payments/${paymentId}`,
        });
    },
    logUsage({}, { type, event, secureCode }) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { type, event, secureCode },
            url: 'usageData/log',
        });
    },
    recordUsage({}, { providerId, statType, statGroup, statValue }) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { providerId, statType, statGroup, statValue },
            url: 'usageData/record',
        });
    },
};

export default {
    state, getters, mutations, actions,
};
