<template><v-btn v-bind="$attrs" class="base-btn vuetify"><slot></slot></v-btn></template>

<script>
export default {
    name: 'BaseBtn'
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
    .button-bigger-text {
        font-size: 1.4rem !important;
        @include wider-than-phablet {
            font-size: 1.6rem !important;
        }
    }

    .v-button-primary.vuetify.v-btn {
        color: #fff;
        background-color: $global-color-secondary;
        border-color: $global-color-secondary;
    }

    .keep-right {
        display: flex;
        justify-content: flex-end;
        flex-flow: row wrap;
        align-items: center;
    }

    .keep-right > div, .keep-right > .v-btn {
        margin-left: 1rem;
    }

    .vuetify {
        button.v-btn--text {
            color: $global-color-secondary;
            font-size: 1.2rem;
        }
    }

    .no-capitalization {
        text-transform: none;
    }
</style>