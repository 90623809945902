<template>
<modal can-close :show="isVisible" @close="close" class="contact-modal-container">
    <!-- MAIN -->
    <div v-show="currentState == 'main'" class="contact-modal">
        <div>
            <h2 class="modal-header h-style-2">{{ $translate('contact.help.header') }}</h2>
            <div class="contact-modal-content">
                <div class="modal-content-container">
                    <button class="button-inline button-callout main-button"
                        @click="goNext('reason')">
                        {{ $translate('contact.help.myBill') }}
                    </button>
                    <button class="button-inline button-callout main-button"
                        @click="setReason(itemizedBillReasonId); goNext('bill');">
                        {{ $translate('contact.help.itemizedBill') }}
                    </button>
                    <button class="button-inline button-callout main-button"
                        id="contact-help-lost-bill"
                        @click="goNextLostBill()">
                        {{ $translate((Boolean(subdomain) && Boolean(brandingConfig) ? 'whitelabel.' : '') + 'contact.help.lostBill') }}
                    </button>
                    <button class="button-inline button-callout main-button"
                        v-show="!isLoggedIn"
                        @click="setSubject('Logging in'); goNext('zendesk');">
                        {{ $translate('contact.help.loggingIn') }}
                    </button>
                    <button class="button-inline button-callout main-button"
                        v-show="!isLoggedIn"
                        @click="forgot()">
                        {{ $translate('contact.help.resetPassword') }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- REASON -->
    <div v-show="currentState == 'reason'" class="contact-modal">
        <h2 class="modal-subheader">{{ $translate('contact.questionAbout.header') }}</h2>
        <div class="contact-modal-content">
            <div class="modal-content-container">
                <button 
                    v-for="reason in reasonsObj"
                    :key="reason.id"
                    :id="reason.id"
                    class="reason-button"
                    @click="setReason(reason.id); goNext()">
                    {{ reason.contactName }}
                </button>
                <button tabindex="4" class="button button-primary" @click="goPrevious()">{{ back }}</button>
            </div>
        </div>
    </div>

    <!-- BILL -->
    <div class="contact-modal" v-show="currentState == 'bill'">
        <h2 class="modal-subheader">{{ $translate('contact.rightPlace.header') }}</h2>
        <form name="forms.scEmailForm" @submit.prevent="submitVerify()" novalidate>
            <!-- FORM SUBMISSION RESPONSE FEEDBACK -->
            <div v-if="searchErrors.notFound || searchErrors.throttled" class="vue-messages interaction-feedback">
                <div v-if="searchErrors.notFound" class="vue-message">
                    {{ $translate('errors.unableToFindBill') }}
                </div>
                <div v-if="searchErrors.throttled" class="vue-message">
                    {{ $translate('errors.billThrottled')}}
                </div>
            </div>
            <!-- EMAIL -->
            <div class="modal-text" v-show="!(isLoggedIn && contact.email)">{{ $translate('contact.rightPlace.enterEmail') }}</div>
            <div class="input-area-wrapper" :class="{errored: forms.scEmailForm.email.$invalid && forms.scEmailForm.$submitted, 'input-focused': forms.scEmailForm.email.$focused}" v-show="!(isLoggedIn && contact.email)">
                <div class="input-area-field-wrapper">
                    <span :class="{ 'icon-active': forms.scEmailForm.email.$active }">
                        <base-icon icon="mdi-email"></base-icon>
                    </span>
                    <input required name="email" type="email" autocapitalize="off" autocorrect="off" autosuggest="off" id="contact-bill-email" :placeholder="$translate('labels.email')" v-model="contact.email" tabindex="1" maxlength="255" class="fs-block input-with-icon"
                        @input="forms.scEmailForm.email.$active = true; validateVerifyEmail()"
                        @change="forms.scEmailForm.email.$dirty = true; $forceUpdate()"
                        @focus="forms.scEmailForm.email.$focused = true; $forceUpdate()"
                        @blur="forms.scEmailForm.email.$focused = false; $forceUpdate()"
                    />
                </div>
                <div class="input-area-feedback">
                    <div v-if="forms.scEmailForm.email.$invalid && forms.scEmailForm.$submitted" class="vue-messages">
                        <div v-if="forms.scEmailForm.email.errors.required" class="vue-message">
                            {{ $translate('errors.genericRequired') }}
                        </div>
                        <div v-else-if="forms.scEmailForm.email.errors.email" class="vue-message">
                            {{ $translate('errors.email') }}
                        </div>
                        <div v-else-if="forms.scEmailForm.email.errors.unknown" class="vue-message">
                            {{ $translate('errors.unknown') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-text">{{ $translate('contact.rightPlace.enterBillDetails') }}</div>
            <!-- SECURECODE -->
            <div class="input-area-wrapper" :class="{errored: forms.scEmailForm.scode.$invalid && (forms.scEmailForm.scode.$dirty || forms.scEmailForm.$submitted), 'input-focused': forms.scEmailForm.scode.$focused}">
                <div class="input-area-field-wrapper">
                    <base-icon icon="custom:BillScodeSVG"></base-icon>
                    <input v-model="contact.secureCode" type="text" required id="contact-bill-scode" tabindex="2" name="scode" autocomplete="off" :placeholder="$translate('labels.secureCodeOnBill')" class="fs-block input-with-icon" maxlength="11"
                        @input="onInputSecureCode"
                        @change="forms.scEmailForm.scode.$dirty = true; $forceUpdate()"
                        @focus="forms.scEmailForm.scode.$focused = true; $forceUpdate()"
                        @blur="forms.scEmailForm.scode.$focused = false; $forceUpdate()"
                    />
                </div>
                <div class="input-area-feedback">
                    <div v-if="forms.scEmailForm.scode.$error && (forms.scEmailForm.$dirty || forms.scEmailForm.$submitted)" class="vue-messages">
                        <div v-if="forms.scEmailForm.scode.errors.required" class="vue-message">
                            <div v-html="$translate(((subdomain ? 'whitelabel.' : '') + 'errors.scodeRequired'))"></div>
                        </div>
                        <div v-else-if="forms.scEmailForm.scode.errors.allNumericSCode" class="vue-message">
                            {{ $translate('errors.allNumericSCode') }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- AMOUNT -->
            <div class="input-area-wrapper" autocomplete="off" :class="{errored: (forms.scEmailForm.billAmount.errors.required || forms.scEmailForm.billAmount.errors.min) && (forms.scEmailForm.billAmount.$dirty || forms.scEmailForm.$submitted), 'input-focused': forms.scEmailForm.billAmount.$focused}">
                <div class="input-area-field-wrapper">
                    <base-icon icon="custom:BillAmountSVG"></base-icon>
                    <input v-model="contact.billAmount" tabindex="3" type="text" id="contact-bill-amount" name="billAmount" required maxlength="20" :placeholder="$translate('labels.balanceOnBill')" class="fs-block input-with-icon"
                        @input="onInputBillAmount"
                        @change="forms.scEmailForm.billAmount.$dirty = true; $forceUpdate()"
                        @focus="forms.scEmailForm.billAmount.$focused = true; $forceUpdate()"
                        @blur="onBlurBillAmount()"
                    />
                </div>
                <div class="input-area-feedback">
                    <div v-if="forms.scEmailForm.billAmount.$error && (forms.scEmailForm.billAmount.$dirty || forms.scEmailForm.$submitted)" class="vue-messages">
                        <div v-if="forms.scEmailForm.billAmount.errors.required" class="vue-message">
                            {{ $translate('errors.amountRequired') }}
                        </div>
                        <div v-else-if="forms.scEmailForm.billAmount.errors.min" class="vue-message">
                            {{ $translate('errors.belowMinAmount') }}
                        </div>
                    </div>
                </div>
            </div>
            <button class="button-primary submit-verify" tabindex="4" @click.prevent="submitVerify()">{{ verifyDialog }}</button>
            <button type="button" tabindex="5" class="button" @click.prevent="goPrevious()">{{ back }}</button>
        </form>
    </div>

    <!-- PASSWORD -->
    <div v-show="currentState == 'register'" class="contact-modal">
        <form name="forms.createForm" @submit.prevent novalidate>
            <h2 class="modal-header">{{ $translate('contact.almostThere.header') }}</h2>
            <div class="modal-text">{{ $translate('contact.almostThere.password') }}</div>
            <!-- PASSWORD -->
            <div class="input-area-wrapper login-password-input-area" :class="{
                            errored: forms.createForm.password.$invalid && forms.createForm.$submitted,
                            'login-password-incorect-blurred': forms.createForm.password.$dirty && forms.createForm.password.$invalid,
                            'input-focused': forms.createForm.password.$focused,
                            }">
                <div class="input-area-field-wrapper">
                    <span :class="{ 'icon-active': forms.createForm.password.$active }">
                        <base-icon icon="mdi-lock"></base-icon>
                    </span>
                    <input required name="password" type="password" valid-password id="contact-register-pass" maxlength="255" :placeholder="$translate('labels.password')" v-model="contact.password" tabindex="4" class="fs-block input-with-icon"
                        @input="forms.createForm.password.$active = true; validateRegisterPassword()"
                        @change="forms.createForm.password.$dirty = true; $forceUpdate()"
                        @focus="forms.createForm.password.$focused = true; $forceUpdate()"
                        @blur="forms.createForm.password.$focused = false; $forceUpdate()"
                    />
                </div>
                <div class="input-area-rules">
                    <ul>
                        <li :class="{'rule-met': !forms.createForm.password.errors.minCharactersRule}">
                            <span v-if="!forms.createForm.password.errors.minCharactersRule" class="rule-met-icon">
                                <base-icon icon="mdi-checkbox-blank-circle"></base-icon>
                            </span>
                            <span v-else class="rule-met-icon">
                                <base-icon icon="mdi-circle"></base-icon>
                            </span>
                            {{ $translate('login.minCharactersRule') }}
                        </li>
                        <li :class="{'rule-met': !forms.createForm.password.errors.upperCaseRule}">
                            <span v-if="!forms.createForm.password.errors.upperCaseRule" class="rule-met-icon">
                                <base-icon icon="mdi-checkbox-blank-circle"></base-icon>
                            </span>
                            <span v-else class="rule-met-icon">
                                <base-icon icon="mdi-circle"></base-icon>
                            </span>
                            {{ $translate('login.upperCaseRule') }}
                        </li>
                        <li :class="{'rule-met': !forms.createForm.password.errors.digitRule}">
                            <span v-if="!forms.createForm.password.errors.digitRule" class="rule-met-icon">
                                <base-icon icon="mdi-checkbox-blank-circle"></base-icon>
                            </span>
                            <span v-else class="rule-met-icon">
                                <base-icon icon="mdi-circle"></base-icon>
                            </span>
                            {{ $translate('login.digitRule') }}
                        </li>
                    </ul>
                </div>
            </div>
            <!-- CONFIRM PASSWORD -->
            <div class="input-area-wrapper" :class="{errored: forms.createForm.password.$valid && forms.createForm.confirmPassword.$invalid && forms.createForm.$submitted, 'input-focused': forms.createForm.confirmPassword.$focused}">
                <div class="input-area-field-wrapper">
                    <span :class="{ 'icon-active': forms.createForm.confirmPassword.$dirty }">
                        <base-icon icon="mdi-lock"></base-icon>
                    </span>
                    <input required name="confirmPassword" type="password" id="contact-register-con-pass" :placeholder="$translate('labels.passwordConfirm')" v-model="contact.confirmPassword" tabindex="5" class="fs-block input-with-icon"
                        @input="forms.createForm.confirmPassword.$active = true; validateRegisterConfirmPassword()"
                        @change="forms.createForm.confirmPassword.$dirty = true; $forceUpdate()"
                        @focus="forms.createForm.confirmPassword.$focused = true; $forceUpdate()"
                        @blur="forms.createForm.confirmPassword.$focused = false; $forceUpdate()"
                    />
                </div>
                <div class="input-area-feedback">
                    <div v-if="forms.createForm.confirmPassword.$error" class="vue-messages">
                        <div v-if="forms.createForm.confirmPassword.errors.required" class="vue-message">
                            {{ $translate('errors.genericRequired') }}
                        </div>
                        <div v-else-if="forms.createForm.confirmPassword.errors.mismatch" class="vue-message">
                            {{ $translate('errors.confirmPassword') }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- TERMS OF USE -->
            <div class="input-area-wrapper" :class="{errored: forms.createForm.tou.$invalid && forms.createForm.$submitted}">
                <div class="input-area-field-wrapper no-initial-border">
                    <input required name="tou" id="contact-tou" type="checkbox" v-model="contact.acceptTerms" tabindex="6" @change="validateRegisterTou()"/>
                    <label for="contact-tou" class="create-account-terms" v-html="$translate('labels.acceptanceOfTerms')"></label>
                </div>
                <div class="input-area-feedback">
                    <div v-if="forms.createForm.tou.$error" class="vue-messages">
                        <div v-if="forms.createForm.tou.errors.required" class="vue-message">
                            {{ $translate('errors.termsAcceptanceRequired') }}
                        </div>
                    </div>
                </div>
            </div>
            <button tabindex="7" class="button button-primary" @click.prevent="submitRegister()">{{ submitDialog }}</button>
            <button tabindex="8" type="button" class="button" @click.prevent="goPrevious()">{{ back }}</button>
        </form>
    </div>

    <!-- PHONE -->
    <div v-if="currentState == 'phone'" class="contact-modal">
        <h2 class="modal-header">{{ $translate('whitelabel.contact.lostBill.header') }}</h2>
        <div class="contact-modal-content">
            <div class="modal-content-container">
                <h3 id="phone-prompt" class="modal-subheader phone-prompt">{{ $translate('whitelabel.contact.lostBill.reachOut') }}<br />
                    <b>{{ brandingConfig.phoneNumber }}</b></h3>
                <button class="button button-primary" @click="goPrevious();">{{ back }}</button>
                <br />
            </div>
        </div>
    </div>

    <!-- LOGIN -->
    <div v-show="currentState == 'login'" class="contact-modal login">
        <form name="forms.loginForm" @submit.prevent novalidate>
            <h2 class="modal-header">{{ $translate('contact.almostThere.header') }}</h2>
            <!-- FORM SUBMISSION RESPONSE FEEDBACK -->
            <div v-if="submissionNotifications.loginFailed || submissionNotifications.userBlocked || submissionNotifications.resetPasswordComplete" class="interaction-feedback vue-messages">
                <div v-if="submissionNotifications.loginFailed" class="vue-message">
                    {{ $translate('errors.unableToLogin') }}
                </div>
                <div v-else-if="submissionNotifications.userBlocked" class="vue-message">
                    {{ $translate('errors.loginThrottled') }}
                </div>
                <div v-else-if="submissionNotifications.resetPasswordComplete" class="vue-message positive-feedback">
                    {{ $translate('login.resetPasswordCompleteDialog') }}
                </div>
            </div>
            <!-- EMAIL -->
            <div class="modal-text"> {{ $translate('contact.almostThere.login') }} </div>
            <div class="input-area-wrapper" :class="{errored: forms.loginForm.email.$invalid && forms.loginForm.$submitted, 'input-focused': forms.loginForm.email.$focused}">
                <div class="input-area-field-wrapper">
                    <span :class="{ 'icon-active': forms.loginForm.email.$active }">
                        <base-icon icon="mdi-email"></base-icon>
                    </span>
                    <input tabindex="1" name="email" type="email" required v-model="contact.email" autocapitalize="off" maxlength="255" :placeholder="$translate('labels.email')" id="contact-login-email" class="fs-block input-with-icon"
                        @input="forms.loginForm.email.$active = true; validateLoginEmail()"
                        @change="forms.loginForm.email.$dirty = true; $forceUpdate()"
                        @focus="forms.loginForm.email.$focused = true; $forceUpdate()"
                        @blur="forms.loginForm.email.$focused = false; $forceUpdate()"
                    />
                </div>
                <div class="input-area-feedback">
                    <div v-if="forms.loginForm.email.$error" class="vue-messages">
                        <div v-if="forms.loginForm.email.errors.required" class="vue-message">
                            {{ $translate('errors.loginEmailRequired') }}
                        </div>
                        <div v-else-if="forms.loginForm.email.errors.email" class="vue-message">
                            {{ $translate('errors.email') }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- PASSWORD -->
            <div class="input-area-wrapper" :class="{errored: forms.loginForm.password.$invalid && forms.loginForm.$submitted, 'input-focused': forms.loginForm.password.$focused}">
                <div class="input-area-field-wrapper">
                    <span :class="{ 'icon-active': forms.loginForm.password.$active }">
                        <base-icon icon="mdi-lock"></base-icon>
                    </span>
                    <input id="contact-login-pass" tabindex="2" name="password" type="password" required
                        :placeholder="$translate('labels.password')" v-model="contact.password" class="fs-block input-with-icon"
                        @input="forms.loginForm.password.$active = true; validateLoginPassword()"
                        @change="forms.loginForm.password.$dirty = true; $forceUpdate()"
                        @focus="forms.loginForm.password.$focused = true; $forceUpdate()"
                        @blur="forms.loginForm.password.$focused = false; $forceUpdate()"/>
                </div>
                <div class="input-area-feedback">
                    <div v-if="forms.loginForm.password.$error" class="vue-messages">
                        <div v-if="forms.loginForm.password.errors.required" class="vue-message">
                            <div v-html="$translate((subdomain ? 'whitelabel.' : '') + 'errors.loginPasswordRequired')"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="login-forgot-password-link">
                <span @click="forgot()" tabindex="3" class="link-text">
                    {{ $translate('actions.forgotPassword') }}
                </span>
            </div>
            <button tabindex="4" class="button button-primary"  @click.prevent="submitLogin()">{{ submitDialog }}</button>
            <button tabindex="5" type="button" class="button" @click.prevent="goPrevious()">{{ back }}</button>
        </form>
    </div>
    <!-- ZENDESK -->
    <div v-show="currentState == 'zendesk'" class="contact-modal">
        <h2 class="modal-header h-style-2">{{ $translate('dialogs.contactTitle') }}</h2>
        <h3 class="modal-subheader">{{ $translate('dialogs.contactSubtitle') }}</h3>
        <form name="forms.contactForm" @submit.prevent="sendZenMessage()" novalidate>
            <div class="split-input-areas-container" v-show="!(isLoggedIn && contact.firstName && contact.lastName)">
                <!-- FIRST NAME -->
                <div class="input-area-wrapper" :class="{errored: forms.contactForm.firstName.$invalid && forms.contactForm.$submitted, 'input-focused': forms.contactForm.firstName.$focused}">
                    <div class="input-area-field-wrapper">
                        <span :class="{ 'icon-active': forms.contactForm.firstName.$active }">
                            <base-icon icon="mdi-account"></base-icon>
                        </span>
                        <input required name="firstName" type="text" id="contact-zendesk-first" :placeholder="$translate('labels.firstName')" v-model="contact.firstName" tabindex="1" class="fs-block input-with-icon"
                            @input="onInputZenFirstName"
                            @change="forms.contactForm.firstName.$dirty = true; $forceUpdate()"
                            @focus="forms.contactForm.firstName.$focused = true; $forceUpdate()"
                            @blur="forms.contactForm.firstName.$focused = false; $forceUpdate()"
                        />
                    </div>
                    <div class="input-area-feedback">
                        <div v-if="forms.contactForm.firstName.$error" class="vue-messages">
                            <div v-if="forms.contactForm.firstName.errors.required" class="vue-message">
                                {{ $translate('errors.genericRequired') }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- LAST NAME -->
                <div class="input-area-wrapper" :class="{errored: forms.contactForm.lastName.$invalid && forms.contactForm.$submitted, 'input-focused': forms.contactForm.lastName.$focused}">
                    <div class="input-area-field-wrapper">
                        <span :class="{ 'icon-active': forms.contactForm.lastName.$active }">
                            <base-icon icon="mdi-account"></base-icon>
                        </span>
                        <input required name="lastName" type="text" id="contact-zendesk-last" :placeholder="$translate('labels.lastName')" v-model="contact.lastName" tabindex="2" class="fs-block input-with-icon"
                            @input="onInputZenLastName"
                            @change="forms.contactForm.lastName.$dirty = true; $forceUpdate()"
                            @focus="forms.contactForm.lastName.$focused = true; $forceUpdate()"
                            @blur="forms.contactForm.lastName.$focused = false; $forceUpdate()"
                        />
                    </div>
                    <div class="input-area-feedback">
                        <div v-if="forms.contactForm.lastName.$error" class="vue-messages">
                            <div v-if="forms.contactForm.lastName.errors.required" class="vue-message">
                                {{ $translate('errors.genericRequired') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- EMAIL -->
            <div class="input-area-wrapper" :class="{errored: forms.contactForm.email.$invalid && forms.contactForm.$submitted, 'input-focused': forms.contactForm.email.$focused}" v-show="!(isLoggedIn && contact.email)">
                <div class="input-area-field-wrapper">
                    <span :class="{ 'icon-active': forms.contactForm.email.$active }">
                        <base-icon icon="mdi-email"></base-icon>
                    </span>
                    <input required name="email" type="email" autocapitalize="off" autocorrect="off" autosuggest="off" id="contact-zendesk-email" :placeholder="$translate('labels.email')" v-model="contact.email" tabindex="3" maxlength="255" class="fs-block input-with-icon"
                            @input="forms.contactForm.email.$active = true; validateZenEmail()"
                            @change="forms.contactForm.email.$dirty = true; $forceUpdate()"
                            @focus="forms.contactForm.email.$focused = true; $forceUpdate()"
                            @blur="forms.contactForm.email.$focused = false; $forceUpdate()"
                    />
                </div>
                <div class="input-area-feedback">
                    <div v-if="forms.contactForm.email.$invalid && forms.contactForm.$submitted" class="vue-messages">
                        <div v-if="forms.contactForm.email.errors.required" class="vue-message">
                            {{ $translate('errors.genericRequired') }}
                        </div>
                        <div v-else-if="forms.contactForm.email.errors.email" class="vue-message">
                            {{ $translate('errors.email') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="input-area-wrapper" :class="{errored: forms.contactForm.message.$invalid && forms.contactForm.$submitted, 'input-focused': forms.contactForm.message.$focused}">
                <div class="input-area-field-wrapper no-initial-border">
                    <textarea required maxlength="5000" name="message" :placeholder="$translate('message.startMessagePlaceholder')" v-model="contact.message" tabindex="4" class="fs-block"
                            @input="forms.contactForm.message.$active = true; validateZenMessage()"
                            @change="forms.contactForm.message.$dirty = true; $forceUpdate()"
                            @focus="forms.contactForm.message.$focused = true; $forceUpdate()"
                            @blur="forms.contactForm.message.$focused = false; $forceUpdate()"></textarea>
                </div>
                <div class="input-area-feedback">
                    <div v-if="forms.contactForm.message.$error" class="vue-messages">
                        <div v-if="forms.contactForm.message.errors.required">
                            {{ $translate('errors.genericRequired') }}
                        </div>
                    </div>
                </div>
            </div>
            <p class="info">{{ $translate('dialogs.phiWarning') }}</p>
            <div class="form-error">
                <div v-if="notifications.fiveHundred" class="vue-messages">
                    <div v-if="notifications.fiveHundred" class="vue-message">
                        {{ $translate('errors.fiveHundred') }}
                    </div>
                </div>
            </div>
            <button class="button-primary" tabindex="5">{{ sendButton }}</button>
            <button tabindex="6" type="button" class="button" @click.prevent="goPrevious()">{{ back }}</button>
        </form>
    </div>
</modal>
</template>
<script>

import { generateDeferredPromise, getValidName, MoneyUtils, validEmail } from '../utils/utils';
import Modal from './Modal.vue';
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'ContactModal',
    components: {
        Modal,
        BaseIcon
    },
    data: () => ({
        reasonsObj: {},
        isVisible: false,
        currentState: 'main',
        state: {
            main: {
                next: 'reason'
            },
            reason: {
                next: 'bill'
            },
            bill: {},
            login: {
                next: 'register'
            },
            register: {
                next: 'forgot'
            },
            zendesk: {},
            phone: {},
        },
        isLoggedIn: false,
        isMyChartSSOOnlyUser: false,
        canMessage: false,
        reasonId: null,
        itemizedBillReasonId: null,
        contact: {
            email: null,
            secureCode: null,
            billAmount: null,
            password: null,
            confirmPassword: null,
            acceptTerms: false,
            firstName: null,
            lastName: null,
            message: null,
            subject: null,
            reasonId: null,
        },
        notifications: {
            fiveHundred: false,
        },
        // forms
        forms: {
            contactForm: {
                email: {
                    errors: {
                        email: false,
                        required: false,
                    },
                },
                firstName: {
                    errors: {
                        required: false,
                    },
                },
                lastName: {
                    errors: {
                        required: false,
                    },
                },
                message: {
                    errors: {
                        required: false,
                    },
                },
            },
            createForm: {
                password: {
                    errors: {
                        digitRule: false,
                        minCharactersRule: false,
                        required: false,
                        upperCaseRule: false,
                    },
                },
                confirmPassword: {
                    errors: {
                        required: false,
                        mismatch: false,
                    },
                },
                tou: {
                    errors: {
                        required: false,
                    },
                },
            },
            scEmailForm: {
                billAmount: {
                    errors: {
                        min: false,
                        required: false,
                    },
                },
                email: {
                    errors: {
                        email: false,
                        required: false,
                        unknown: false,
                    },
                },
                scode: {
                    errors: {
                        allNumericSCode: false,
                        required: false,
                    },
                },
            },
            loginForm: {
                email: {
                    errors: {
                        email: false,
                        required: false,
                    },
                },
                password: {
                    errors: {
                        required: false,
                    },
                },
            },
        },
        verifyDialog: null,
        verifying: false,
        submitDialog: null,
        submitting: false,
        retrieveDialog: null,
        retrieving: false,
        sendButton: null,
        sending: false,
        back: null,
        searchErrors: {},
        emailFailed: false,
        submissionNotifications: {},
    }),
    computed: {
        brandingConfig() {
            return this.$store.getters.brandingConfig;
        },
        currentBill() {
            return this.$store.getters.currentBill;
        },
        currentUser() {
            return this.$store.getters.currentUser;
        },
        contactReasons() {
            return this.$store.getters.contactReasons;
        },
        contactReasonById() {
            return this.$store.getters.contactReasonById;
        },
        reasonByCode() {
            return this.$store.getters.reasonByCode;
        },
        subdomain() {
            return this.$store.getters.subdomain;
        }
    },
    methods: {
        close() {
            this.reset();
            this.isVisible = false;
        },
        reset() {
            this.currentState = 'main';
            if (this.forms && this.forms.contactForm) {
                this.setPristine('contactForm');
            }

            if (this.forms && this.forms.createForm) {
                this.setPristine('createForm');
            }

            if (this.forms && this.forms.scEmailForm) {
                this.setPristine('scEmailForm');
            }

            if (this.forms && this.forms.loginForm) {
                this.setPristine('loginForm');
            }

            var user = this.currentUser;
            this.isLoggedIn = Boolean(user);
            if (user) {
                this.isMyChartSSOOnlyUser = user.isMyChartSSOOnlyUser;
            }

            this.reasonId = '';
            this.reasonsObj = this.contactReasons;
            this.canMessage = false;

            this.contact = {
                email: null,
                secureCode: null,
                billAmount: null,
                password: null,
                confirmPassword: null,
                acceptTerms: false,
                firstName: null,
                lastName: null,
                message: null,
                subject: null,
                reasonId: null,
            };
            if (this.isLoggedIn) {
                this.contact.email = user.email;
                this.contact.firstName = user.firstName;
                this.contact.lastName = user.lastName;
            }

            // Dialogs
            this.verifyDialog = this.$translate('actions.verify');
            this.verifying = false;

            this.submitDialog = this.$translate('actions.submit');
            this.submitting = false;

            this.retrieveDialog = this.$translate('actions.retrievePassword');
            this.retrieving = false;

            this.sendButton = this.$translate('actions.sendMessage');
            this.sending = false;

            this.back = this.$translate('actions.back');

            this.notifications = {};

            this.validateRegister();
        },
        setPristine(formName) {
            Object.keys(this.forms[formName]).forEach((fieldKey) => {
                if (typeof this.forms[formName][fieldKey] !== 'object') {
                    return;
                }
                const field = this.forms[formName][fieldKey];
                field.$dirty = false;
                field.$focused = false;
                field.$error = false;
                field.$invalid = false;
                field.$active = false;
            });
            this.forms[formName].$error = false;
            this.forms[formName].$invalid = false;
            this.forms[formName].$submitted = false;
        },
        goNext(option) {
            var nextState;
            if (option) {
                nextState = this.state[this.currentState].next = option;
            } else {
                nextState = this.state[this.currentState].next;
            }

            this.state[nextState].previous = this.currentState;
            this.currentState = nextState;
        },
        goNextLostBill() {
            if (!this.subdomain || !this.brandingConfig || !this.brandingConfig.phoneNumber) {
                this.setSubject('Recovering a lost bill');
                this.goNext('zendesk');
            } else {
                this.goNext('phone');
            }
        },
        goPrevious() {
            this.currentState = this.state[this.currentState].previous;
        },
        setSubject(subject) {
            this.contact.subject = subject;
        },
        setReason(reason) {
            this.reasonId = reason;
        },
        forgot() {
            this.isVisible = false;
            var deferred = generateDeferredPromise();
            this.emitter.emit('login:showPrompt', {defer:deferred, options:{ openSection: 'forgotpassword' }});
        },
        submitVerify() {
            this.forms.scEmailForm.$submitted = true;
            this.validateVerify();
            this.searchErrors = {};
            this.emailFailed = false;

            if (this.forms.scEmailForm.$invalid || this.verifying) {
                return;
            }

            // let the user know we are looking for the bill
            this.verifying = true;
            this.verifyDialog = this.$translate('actions.verifying');

            if (this.isBillCached()) {
                this.findBillHelper();
            } else {
                this.$store.dispatch('findBill', { secureCode: this.contact.secureCode, billAmount: this.contact.rawBillAmount || "0.00" })
                    .then(() => {
                        this.findBillHelper();
                    })
                    .catch((resp) => {
                        // could not find bill. Handle error
                        this.verifying = false;
                        this.verifyDialog = this.$translate('actions.verify');

                        if ('SECURECODE_NOT_FOUND' === resp.errorCode) {
                            this.searchErrors.notFound = true;
                        } else if ('SECURECODE_BLOCKED' === resp.errorCode) {
                            this.searchErrors.throttled = true;
                        }
                    });
            }
        },
        validateVerify() {
            this.validateVerifyEmail();
            this.validateVerifySCode();
            this.validateVerifyBillAmount();
            this.forms.scEmailForm.$error = this.forms.scEmailForm.email.$error 
                || this.forms.scEmailForm.scode.$error
                || this.forms.scEmailForm.billAmount.$error;
            this.forms.scEmailForm.$invalid = this.forms.scEmailForm.$error;
        },
        validateVerifyEmail() {
            this.forms.scEmailForm.email.errors.required = !Boolean(this.contact.email);
            this.forms.scEmailForm.email.errors.email = !validEmail(this.contact.email);
            this.forms.scEmailForm.email.$error = this.forms.scEmailForm.email.errors.required
                || this.forms.scEmailForm.email.errors.email;
            this.forms.scEmailForm.email.$invalid = this.forms.scEmailForm.email.$error;
        },
        validateVerifySCode() {
            this.forms.scEmailForm.scode.errors.required = !Boolean(this.contact.secureCode);
            this.forms.scEmailForm.scode.errors.allNumericSCode = /[0-9]{3}-[0-9]{3}-[0-9]{3}/.test(this.contact.secureCode);
            this.forms.scEmailForm.scode.$error = this.forms.scEmailForm.scode.errors.required
                || this.forms.scEmailForm.scode.errors.allNumericSCode;
            this.forms.scEmailForm.scode.$invalid = this.forms.scEmailForm.scode.$error;
        },
        validateVerifyBillAmount() {
            this.forms.scEmailForm.billAmount.errors.required = !Boolean(this.contact.billAmount);
            this.forms.scEmailForm.billAmount.errors.min = !MoneyUtils.greaterThanOrEqual(parseFloat((this.contact.billAmount || '').replace(/[^\d\.]+/g, '')), 0);
            this.forms.scEmailForm.billAmount.$error = this.forms.scEmailForm.billAmount.errors.required
                || this.forms.scEmailForm.billAmount.errors.min;
            this.forms.scEmailForm.billAmount.$invalid = this.forms.scEmailForm.billAmount.$error;
        },
        onInputSecureCode(e) {
            if (this.contact.secureCode) {
                // strip out anything that's not a number or letter
                const cleaned = this.contact.secureCode.replace(/[^a-zA-Z0-9]$/g, '').toUpperCase();
                let formatted = '';

                // Rebuild the string with proper formatting
                for (let i = 0; i < cleaned.length; i++) {
                    const curChar = cleaned[i];
                    if(/[A-Za-z0-9]/.test(curChar)){
                        formatted += curChar;
                    }
                    if (formatted.length === 3 || formatted.length === 7){
                        formatted += '-';
                    }
                }

                // remove trailing hyphen if we are backspacing into it
                if (e.inputType ===  'deleteContentBackward' && (formatted.length === 4 || formatted.length === 8)) {
                    formatted = formatted.slice(0, -1);
                }
                this.contact.secureCode = formatted;
            }
            this.validateVerifySCode();
        },
        onInputBillAmount(e) {
            if (this.contact.billAmount) {
                // strip out anything that's not a number or a decimal
                const cleaned = this.contact.billAmount.replace(/[^\d\.]+/g, '');
                const sections = cleaned.split('.');
                const dollars = sections[0];
                const cents = (sections[1] || '').slice(0, 2);
                const decimalIndex = cleaned.indexOf('.');
                let formatted = dollars;
                if (decimalIndex >= 0) {
                    formatted = formatted + '.' + cents;
                }
                if ('deleteContentBackward' !== e.inputType && (1 === cleaned.length && ('.' === cleaned || '0' === cleaned))) {
                    formatted = '0.';
                }
                this.contact.rawBillAmount = formatted;
                // return our formatted string with commas to mark thousands
                this.contact.billAmount = '$' + formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            this.validateVerifyBillAmount();
        },
        onBlurBillAmount() {
            if (!this.contact.billAmount) {
                // if the user clicked the input then immediately left for some reason
                this.contact.billAmount = '$0.00';
            } else {
                // let's make sure our decimal is formatted properly
                const index = this.contact.billAmount.indexOf('.');
                if (index < 0) {
                    // e.g. $420
                    this.contact.billAmount += '.00';
                } else if (index === this.contact.billAmount.length - 1) {
                    // e.g. $. or $420.
                    this.contact.billAmount += '00';
                } else if (index === this.contact.billAmount.length - 2) {
                    this.contact.billAmount += '0';
                }
            }
            this.forms.scEmailForm.billAmount.$focused = false;
            this.$forceUpdate();
        },
        isBillCached() {
            var _bill = this.currentBill;
            return _bill && MoneyUtils.equals(this.contact.billAmount, _bill.billAmount) && this.contact.secureCode === _bill.secureCode;
        },
        findBillHelper() {
            var _bill = this.currentBill,
                name = _bill.guarantorDetails.name.split(' ');
            if (!this.isLoggedIn) {
                this.contact.firstName = name[0];
                this.contact.lastName = name[1];
            }
            if (!_bill.canMessage()) {
                this.goNext('zendesk');
                this.verifying = false;
                this.verifyDialog = this.$translate('actions.verify');
            } else {
                if (!this.isLoggedIn) {
                    this.$store.dispatch('emailAvailable', { email: this.contact.email, isUpdate: false })
                    .then(() => {
                        // Register
                        this.goNext('register');
                    })
                    .catch((resp) => {
                        if (resp.errorCode) {
                            this.forms.scEmailForm.email.errors.unknown = true;
                        } else {
                            this.goNext('login');
                        }
                    })
                    .finally(() => {
                        this.verifying = false;
                        this.verifyDialog = this.$translate('actions.verify');
                    });
                } else {
                    this.isVisible = false;
                    this.verifying = false;
                    this.verifyDialog = this.$translate('actions.verify');
                    this.getUserAndLink().then(this.createMessage);
                }
            }
        },
        createMessage() {
            this.$router.push({
                name: 'Messages',
                query: {
                    autofill: true,
                    data: JSON.stringify({
                        reason: this.reasonId,
                    }),
                },
            });
        }, 
        getUserAndLink(secureCode, billAmount) {
            var reasonId = this.reasonId;
            var deferred = generateDeferredPromise();
            if (undefined === secureCode) {
                secureCode = this.contact.secureCode;
            }
            if (undefined === billAmount) {
                billAmount = this.contact.billAmount;
            }
            this.$store.dispatch('requireAuthedUser').then((user) => {

                // link this bill with the current user - async
                this.$store.dispatch('linkBill', { sCode: secureCode, amount: billAmount.replace(/[^\d\.]+/g, '') }).then(() => {
                    this.reasonId = reasonId;
                    deferred.resolve(user);
                });
                
            }).catch((message) => {
                console.error(message);
                deferred.reject(message);
            });

            return deferred.promise;
        },
        submitRegister(continuingSubmission) {
            this.forms.createForm.$submitted = true;
            this.validateRegister();
            // reset submitting status if we are invalid but resuming
            if (this.forms.createForm.$invalid && this.submitting) {
                this.submitting = false;
                this.submitDialog = this.$translate('actions.submit');
            }

            // supress duplicate submission attempts
            if (this.forms.createForm.$invalid || (this.submitting && !continuingSubmission)) {
                return;
            }

            this.submitting = true;
            this.submitDialog = this.$translate('actions.submitting');

            var registerInfo = {
                email: this.contact.email,
                password: this.contact.password,
                acceptTerms: this.contact.acceptTerms,
                confirmPassword: this.contact.confirmPassword,
                firstName: this.contact.firstName,
                lastName: this.contact.lastName
            };

            var finishAndLink = (secureCode, billAmount) => {
                this.isVisible = false;
                this.state.createPassword = false;
                this.getUserAndLink(secureCode, billAmount).then(this.createMessage);
            };
            this.$store.dispatch('register', registerInfo)
                .then((user) => {
                    if (user) {
                        finishAndLink();
                    }
                })
                .catch((message) => {
                    if (message.errorCode === 'LOGIN_NOT_VERIFIED') {
                        this.isVisible = false;
                        this.state.createPassword = false;
                        var deferred = generateDeferredPromise();
                        var secureCode = this.contact.secureCode;
                        var billAmount = this.contact.billAmount;
                        deferred.promise.then(() => {
                            finishAndLink(secureCode, billAmount);
                        });
                        this.emitter.emit('verification:prompt', {
                            email: this.contact.email,
                            defer: deferred,
                        });
                    } else {
                        console.error(message);
                    }
                })
                .finally(() => {
                    this.submitting = false;
                    this.submitDialog = this.$translate('actions.submit');
                });
        },
        validateRegister() {
            this.validateRegisterPassword();
            this.validateRegisterConfirmPassword();
            this.validateRegisterTou();
            this.forms.createForm.$error = this.forms.createForm.password.$error
                || this.forms.createForm.confirmPassword.$error
                || this.forms.createForm.tou.$error;
            this.forms.createForm.$invalid = this.forms.createForm.$error;
            this.$forceUpdate();
        },
        validateRegisterPassword() {
            this.forms.createForm.password.errors.required = !Boolean(this.contact.password);
            this.forms.createForm.password.errors.minCharactersRule = (this.contact.password || '').trim().length < 8;
            this.forms.createForm.password.errors.upperCaseRule = !/[A-Z]+/.test((this.contact.password || '').trim());
            this.forms.createForm.password.errors.digitRule = !/\d+/.test((this.contact.password || '').trim());
            this.forms.createForm.password.$error = this.forms.createForm.password.errors.required
                || this.forms.createForm.password.errors.minCharactersRule
                || this.forms.createForm.password.errors.upperCaseRule
                || this.forms.createForm.password.errors.digitRule;
            this.forms.createForm.password.$invalid = this.forms.createForm.password.$error;
            this.forms.createForm.password.$valid = !this.forms.createForm.password.$invalid;
        },
        validateRegisterConfirmPassword() {
            this.forms.createForm.confirmPassword.errors.required = !Boolean(this.contact.confirmPassword);
            this.forms.createForm.confirmPassword.errors.mismatch = this.contact.password !== this.contact.confirmPassword;
            this.forms.createForm.confirmPassword.$error = this.forms.createForm.confirmPassword.errors.required
                || this.forms.createForm.confirmPassword.errors.mismatch;
            this.forms.createForm.confirmPassword.$invalid = this.forms.createForm.confirmPassword.$error;
        },
        validateRegisterTou() {
            this.forms.createForm.tou.errors.required = !Boolean(this.contact.acceptTerms);
            this.forms.createForm.tou.$error = this.forms.createForm.tou.errors.required;
            this.forms.createForm.tou.$invalid = this.forms.createForm.tou.errors.required;
        },

        submitLogin() {
            this.forms.loginForm.$submitted = true;
            this.submissionNotifications = {};

            this.validateLogin();

            if (this.forms.loginForm.$invalid || this.submitting) {
                return;
            }

            this.submitting = true;
            this.submitDialog = this.$translate('actions.submitting');
            var login = { email: this.contact.email, password: this.contact.password };
            this.$store.dispatch('logIn', login)
                .then(() => {
                    this.isVisible = false;
                    this.state.login = false;
                    this.getUserAndLink().then(this.createMessage);
                })
                .catch((resp) => {
                    if ('INVALID_PARAMETERS' === resp.errorCode) {
                        this.submissionNotifications.loginFailed = true;
                    } else if ('LOGIN_BLOCKED' === resp.errorCode) {
                        this.submissionNotifications.userBlocked = true;
                    }
                    this.contact.password = '';
                    this.forms.loginForm.$submitted = false;
                })
                .finally(() => {
                    this.submitting = false;
                    this.submitDialog = this.$translate('actions.submit');
                });
        },
        validateLogin() {
            this.validateLoginEmail();
            this.validateLoginPassword();
            this.forms.loginForm.$error = this.forms.loginForm.email.$error 
                || this.forms.loginForm.password.$error;
            this.forms.loginForm.$invalid = this.forms.loginForm.$error;
        },
        validateLoginEmail() {
            this.forms.loginForm.email.errors.required = !Boolean(this.contact.email);
            this.forms.loginForm.email.errors.email = !validEmail(this.contact.email);
            this.forms.loginForm.email.$error = this.forms.loginForm.email.errors.required
                || this.forms.loginForm.email.errors.email;
            this.forms.loginForm.email.$invalid = this.forms.loginForm.email.$error;
        },
        validateLoginPassword() {
            this.forms.loginForm.password.errors.required = !Boolean(this.contact.password);
            this.forms.loginForm.password.$error = this.forms.loginForm.password.errors.required;
            this.forms.loginForm.password.$invalid = this.forms.loginForm.password.$error;
        },

        sendZenMessage() {
            this.forms.contactForm.$submitted = true;
            this.validateZen();
            // Make sure form is valid and not submitting
            if (this.forms.contactForm.$invalid || this.sending) {
                return;
            }

            this.sending = true;
            this.sendButton = this.$translate('actions.sending');

            // get subject if necessary
            if (this.state[this.currentState].previous === 'bill') {
                this.contact.subject = this.contactReasonById(this.reasonId);
            }

            this.$store.dispatch('sendContactInfo', this.contact)
                .then((status) => {
                    //We're done sending
                    this.sending = false;
                    this.sendButton = this.$translate('actions.sendMessage');
                    // Show a success modal
                    if (status === 'OK') {
                        this.isVisible = false;

                        this.emitter.emit('simpleModal:showPrompt', {
                            header: this.$translate('dialogs.contactSent'),
                            subcontent: this.$translate('dialogs.contactThanks'),
                            intent: 'success',
                            autoExpire: true,
                        });
                    } else {
                        // Display a five hundred error message
                        this.notifications.fiveHundred = true;
                    }
                });
        },
        validateZen() {
            this.validateZenFirstName();
            this.validateZenLastName();
            this.validateZenEmail();
            this.validateZenMessage();
            this.forms.contactForm.$error = this.forms.contactForm.email.$error 
                || this.forms.contactForm.firstName.$error
                || this.forms.contactForm.lastName.$error
                || this.forms.contactForm.message.$error;
            this.forms.contactForm.$invalid = this.forms.contactForm.$error;
            this.$forceUpdate();
        },
        validateZenFirstName() {
            this.forms.contactForm.firstName.errors.required = !Boolean(this.contact.firstName);
            this.forms.contactForm.firstName.$error = this.forms.contactForm.firstName.errors.required;
            this.forms.contactForm.firstName.$invalid = this.forms.contactForm.firstName.$error;
        },
        validateZenLastName() {
            this.forms.contactForm.lastName.errors.required = !Boolean(this.contact.lastName);
            this.forms.contactForm.lastName.$error = this.forms.contactForm.lastName.errors.required;
            this.forms.contactForm.lastName.$invalid = this.forms.contactForm.lastName.$error;
        },
        validateZenEmail() {
            this.forms.contactForm.email.errors.required = !Boolean(this.contact.email);
            this.forms.contactForm.email.errors.email = !validEmail(this.contact.email);
            this.forms.contactForm.email.$error = this.forms.contactForm.email.errors.required
                || this.forms.contactForm.email.errors.email;
            this.forms.contactForm.email.$invalid = this.forms.contactForm.email.$error;
        },
        validateZenMessage() {
            this.forms.contactForm.message.errors.required = !Boolean(this.contact.message);
            this.forms.contactForm.message.$error = this.forms.contactForm.message.errors.required;
            this.forms.contactForm.message.$invalid = this.forms.contactForm.message.$error;
        },
        onInputZenFirstName() {
            this.contact.firstName = getValidName(this.contact.firstName);
            this.forms.contactForm.firstName.$active = true;
            this.validateZenFirstName();
        },
        onInputZenLastName() {
            this.contact.lastName = getValidName(this.contact.lastName);
            this.forms.contactForm.lastName.$active = true;
            this.validateZenFirstName();
        },
    },
    mounted() {
        this.reset();
        this.itemizedBillReasonId = this.reasonByCode('itemizedBill').id;
        this.emitter.on('contact:show', (options) => {
            this.reset();
            this.isLoggedIn = Boolean(this.currentUser);
            //defaults
            this.isVisible = true;

            if (options && options.subject) {
                this.contact.subject = options.subject;
            }

            if (options && options.reasonId) {
                this.contact.reasonId = options.reasonId;
            }

            if (options && options.state) {
                this.goNext(options.state);
            }
        });
    },
};
</script>
<style lang="scss">
@import '../styles/vuetify-overrides.scss';
.login-forgot-password-link {
    color: $global-color-link-new;
    text-decoration: underline;
    cursor: pointer;
}
</style>