<template>
    <v-icon v-bind="$attrs" :icon="icon" :color="iconColor" class="base-icon vuetify"></v-icon>
</template>

<script>
export default {
    name: 'BaseIcon',
    props: {
        icon: {
            type: String
        },
        color: {
            type: String
        }
    },
    computed: {
        iconColor() {
            if (this.color) {
                return this.color;
            } else {
                return this.$vuetify.theme.themes.light.colors.primary;
            }
        }
    }
};
</script>