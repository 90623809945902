import _ from 'lodash';
import { generateDeferredPromise, listCombine, translate } from '../../utils/utils';
import vueEventTransmitter  from '../../utils/vueEventTransmitter';
import http from '../../utils/http';

const state = {
    unverifiedAccounts: [],
    fetchAttempted: false,
    fetchDefer: null,
    haveAnyMasterLinksBeenVerifiedYet: false,
};

const getters = {
    getBillToLinkAfterVerifyFromMasterLinkIdAndSecureCode: (state) => (masterLinkId, scodeWithPayIntent) => {
        //only go to bill-link page if there is a single bill about to be added to the PatientWallet
        const masterLink = _.find(state.unverifiedAccounts, (a) => {
            return _.parseInt(a.id) === _.parseInt(masterLinkId);
        });

        if (masterLink && masterLink.additionalBills){
            if (scodeWithPayIntent && _.includes(masterLink.additionalBills, scodeWithPayIntent.toUpperCase())) {
                return scodeWithPayIntent;
            } else if (masterLink.additionalBills.length === 1) {
                return masterLink.additionalBills[0];
            }
        }

        return null;
    },

    getNewAccountsSecureCodesFromMasterLinkId: (state) => (masterLinkId) => {
        const masterLink = _.find(state.unverifiedAccounts, (a) => {
            return _.parseInt(a.id) === _.parseInt(masterLinkId);
        });
        if (masterLink && masterLink.additionalBills) {
            return masterLink.additionalBills;
        }
        return [];
    },

    getHasUnverifiedAccountWithEncounterGroupId: (state) => (encounterGroupId, onlyWithNewAccts) => {
        return Boolean(_.find(state.unverifiedAccounts, (a) => {
            return (!encounterGroupId || _.parseInt(a.providerGroupId) === _.parseInt(encounterGroupId)) && (!onlyWithNewAccts ||  a.additionalBills.length > 0);
        }));
    },

    getNotificationTextForEncounterGroupId: (state) => (encounterGroupId) => {
        var matchingMasterLinks = _.filter(state.unverifiedAccounts, function(acct){
            return !encounterGroupId || _.parseInt(acct.providerGroupId) === _.parseInt(encounterGroupId);
        }),
        proactiveMasterLinks = _.filter(matchingMasterLinks, function(acct){
            return acct.additionalBills && acct.additionalBills.length === 0;
        }),
        proactiveProviderGroups = _.chain(proactiveMasterLinks)
            .map(function(acct) {
                return acct.providerGroup;
            })
            .uniq()
            .sort()
            .value();

        //if all of the MLs are proactive, then the user gets a special message.  i.e. if we have one proactive ML and one ML with
        //existing accts, just show the standard notification message. if we're showing the notification again after the user has
        //already verified account, modify the language slightly to indicate that there are more accts to verify.
        if (!_.isEmpty(proactiveMasterLinks) && proactiveMasterLinks.length === matchingMasterLinks.length) {
            return translate('encounterLinking.proactiveLinkableAccounts', { providerGroupList : listCombine(proactiveProviderGroups) });
        } else if (state.haveAnyMasterLinksBeenVerifiedYet) {
            return translate('encounterLinking.experiencedLinkableAccounts');
        }
        return translate('encounterLinking.linkableAccounts');
    },
    haveAnyMasterLinksBeenVerifiedYet(state) {
        return state.haveAnyMasterLinksBeenVerifiedYet;
    },
    unverifiedAccounts(state) {
        return state.unverifiedAccounts;
    }
};

const mutations = {
    setUnverifiedAccounts(state, val) {
        state.unverifiedAccounts = val;
    },
    setFetchAttempted(state, val) {
        state.fetchAttempted = val;
    },
    setFetchDefer(state, val) {
        state.fetchDefer = val;
    },
    setHaveAnyMasterLinksBeenVerifiedYet(state, val) {
        state.haveAnyMasterLinksBeenVerifiedYet = val;
    },
};

const actions = {
    fetchUnverifiedAccounts({ commit, state }, forceRefresh) {
        // reuse this promise if we need more than
        // one fetch of the data at a time
        if (!state.fetchDefer || forceRefresh) {
            commit('setFetchDefer', generateDeferredPromise());
        }

        if (!state.fetchAttempted || forceRefresh) {
            http.get('masterLinks/unverified').then((resp) => {
                commit('setFetchAttempted', true);
                if (resp && resp.hasData()) {
                    let theAccounts, theAuthType, extendedAccounts;
                    theAccounts = resp.getData();
                    extendedAccounts = _.map(theAccounts, (element) => {
                        theAuthType = 'patient'; // default
                        if (_.includes(element.fields, 'guarantordob') && _.includes(element.fields, 'guarantorssn')) {
                            theAuthType = 'guarantor';
                        } else if (_.includes(element.fields, 'patientdob') && _.includes(element.fields, 'patientssn')) {
                            theAuthType = 'patient';
                        } else if (_.includes(element.fields, 'patientdob') && _.includes(element.fields, 'guarantorssn')) {
                            theAuthType = 'patient';
                        } else {
                            // This is a weird case and should never happen unless the backend got messed up, but we are going to filter these out
                            theAuthType='badfields';
                        }
                        return _.extend({}, element, { authType: theAuthType });
                    });
                    extendedAccounts = _.filter(extendedAccounts, (acct) => {
                        return acct.authType !== 'badfields'; // Don't show stuff that can't possibly auth anyway
                    });
                    commit('setUnverifiedAccounts', extendedAccounts);
                    state.fetchDefer.resolve(state.unverifiedAccounts);
                } else {
                    state.fetchDefer.reject(resp);
                }
            });
        } else {
            state.fetchDefer.resolve(state.unverifiedAccounts);
        }
        return state.fetchDefer.promise;
    },

    authenticateAccount({ commit, dispatch, state }, { masterLinkId, fieldOneValue, fieldTwoValue }) {
        const masterLink = _.find(state.unverifiedAccounts, (a) => {
            return _.parseInt(a.id) === _.parseInt(masterLinkId);
        });
        const fields = {};
        fields[masterLink.fields[0]] = fieldOneValue;
        fields[masterLink.fields[1]] = fieldTwoValue;

        return http.post(`masterLinks/${masterLinkId}/verify`, {
            fields,
        }).then(function(resp){
            if (resp && resp.hasData() && resp.getData().success){
                commit('setUnverifiedAccounts', _.without(state.unverifiedAccounts, masterLink)); // verified now. no longer in list
                commit('setHaveAnyMasterLinksBeenVerifiedYet', true);
                dispatch('refreshLinkableAccountsNotificationBanner');
                return resp;
            }
            return Promise.reject(resp);
        });
    },

    async refreshLinkableAccountsNotificationBanner({ commit, dispatch, getters }, provider) {
        if (provider) {
            await dispatch('fetchUnverifiedAccounts');
        }
        const searchedProviderHasAccounts = provider && provider.encounterGroupId && getters.getHasUnverifiedAccountWithEncounterGroupId(provider.encounterGroupId);
        const noProviderSearchedButAccountsFound = !provider && getters.getHasUnverifiedAccountWithEncounterGroupId();
        if (searchedProviderHasAccounts || noProviderSearchedButAccountsFound) {
            commit('setVisibleNotificationBanner', {
                action: () => vueEventTransmitter.emit('accountVerification:prompt'),
                text: translate('actions.verifyFoundAccounts.message'),
            });
        }
    },
};

export default {
    state, getters, mutations, actions,
};
