<template>
    <div v-if="showComponent" class='sub-account-details-left-column'>
        <AppointmentInfoCard :appointmentInfo="appointmentInfo"></AppointmentInfoCard>
        <need-help-card @composemessage="composeMessage" :provider="provider"></need-help-card>
    </div>
</template>

<script>

import AppointmentInfoCard from './AppointmentInfoCard.vue';
import NeedHelpCard from './NeedHelpCard.vue';

export default {
	name: 'SubAccountDetailsLeftColumn',
	components: {
		AppointmentInfoCard,
        NeedHelpCard,
	},
    data: () => ({
        provider: {},
        messageProviderData: {},
        appointmentInfo: {}
    }),
    watch: {
        estimate() {
            this.setProviderDataIfEstimate(this.estimate);
        },
    },
	computed: {
        estimate() {
            return this.$store.getters.currentEstimate;
        },
        showComponent() {
        	return this.estimate && this.$vuetify.display.lgAndUp;
        },
    },
    created() {
        this.setProviderDataIfEstimate(this.estimate);
    },
    methods: {
        setProviderDataIfEstimate(estimate) {
            if (estimate) {
                this.messageProviderData = { estimateId: estimate.id, reason: 11 };
                this.appointmentInfo = estimate.appointmentInfo;
                if (estimate.providerDetails) {
                    this.provider = estimate.providerDetails;
                }
            }
        },
        composeMessage() {
            const authParams = {};
            const onAuthSuccess = () => {
                this.$store.dispatch('linkEstimate', this.estimate).then(() => {
                    const forceAccountRefresh = true;
                    this.emitter.emit('linkedAccountService:fetchUnverified', forceAccountRefresh);
                    if (this.estimate.providerDetails.requiresRedirectToBranded) {
                        this.emitter.emit('redirect:tab', {
                            provider: this.provider,
                            context: {
                                stateGo: {
                                    to: 'SubAccountDetails',
                                    toParams: {
                                        id: this.provider.id,
                                        estimateId: this.estimate.subAccount.id,
                                    }
                                },
                                emit: ['message:compose', this.messageProviderData],
                            },
                        });
                    } else {
                        this.emitter.emit('message:compose', this.messageProviderData);
                    }
                }).catch((message) => {
                    console.error(message);
                });
            };
            this.emitter.emit('require-authed-user', {params:authParams, successCallback:onAuthSuccess});
        },
    },
};
</script>