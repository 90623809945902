<template>
    <div v-if="showComponent" class="vuetify appointment-info" data-testid="appointment-info">
        <div class="flex-container">
            <icon-data-list-item class="flex-item"
                icon="mdi-hospital-box-outline"
                :label="$translate('labels.providerLocation')"
                :data="appointmentInfo.providerLocation"
                v-if="appointmentInfo.providerLocation"
            ></icon-data-list-item>
            <icon-data-list-item class="flex-item fs-block"
                icon="mdi-calendar-month-outline"
                :label="$translate('labels.appointmentDate')"
                :data="$formatDate(appointmentInfo.appointmentDate, 'MMMM DD, YYYY')"
                v-if="appointmentInfo.appointmentDate"
            ></icon-data-list-item>
            <icon-data-list-item class="flex-item fs-block"
                icon="mdi-clock-time-four-outline"
                :label="$translate('labels.appointmentTime')"
                :data="`${$formatDate(appointmentInfo.appointmentTime, 'h:mm A')} ${appointmentInfo.serviceTimeZone || ''}`"
                v-if="appointmentInfo.appointmentTime"
            ></icon-data-list-item>
            <icon-data-list-item class="flex-item fs-block"
                icon="mdi-stethoscope"
                :label="$translate('linkedAccounts.providerName')"
                :data="appointmentInfo.inNetworkProvider"
                v-if="appointmentInfo.inNetworkProvider"
            ></icon-data-list-item>
        </div>
    </div>
</template>

<script>
import IconDataListItem from  './IconDataListItem.vue';
export default {
    name: 'AppointmentInfo',
    components: {
        IconDataListItem
    },
    props: {
        appointmentInfo: Object,
    },
    computed: {
        showComponent() {
        	return this.appointmentInfo && (this.appointmentInfo.providerLocation || this.appointmentInfo.appointmentDate || this.appointmentInfo.appointmentTime || this.appointmentInfo.inNetworkProvider);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.appointment-info {
    .icon-info-list-item {
        margin-bottom: 1.6rem;
    }
}
.v-bottom-sheet {
    .appointment-info {
        .flex-container {
            display: flex;
            flex-direction: column;
        }
        @include wider-than-phablet {
            .flex-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
            .flex-item {
                width: 50%;
            }
        }
    }
}

#left-column-v2 {
    .appointment-info {
        .flex-container {
            display: flex;
            flex-direction: column;
        }
    }
}

#main-column-v2 {
    .appointment-info {
        .flex-container {
            display: flex;
            flex-direction: column;
        }
        @include wider-than-phablet {
            .flex-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
            .flex-item {
                width: 50%;
            }
        }
    }
}
</style>