import 'promise-polyfill/src/polyfill';
import 'regenerator-runtime/runtime.js';
import { createStore } from 'vuex';
import axios from 'axios';

import AccountsStore from './modules/AccountsStore';
import AppStore from './modules/AppStore';
import BillsStore from './modules/BillsStore';
import EstimatesStore from './modules/EstimatesStore';
import FinancingStore from './modules/FinancingStore';
import FullstoryStore from './modules/FullstoryStore';
import LinkedAccountsStore from './modules/LinkedAccountsStore';
import MessagesStore from './modules/MessagesStore';
import NMIGatewayStore from './modules/NMIGatewayStore';
import NotificationsStore from './modules/NotificationsStore';
import PaymentFormsGatewayStore from './modules/PaymentFormsGatewayStore';
import PaymentGatewayStore from './modules/PaymentGatewayStore';
import PaymentsStore from './modules/PaymentsStore';
import ProvidersStore from './modules/ProvidersStore';
import ReceiptStore from './modules/ReceiptStore';
import ServerStatusStore from './modules/ServerStatusStore';
import UsersStore from './modules/UsersStore';

import { onRequest, onResponse } from './../utils/axiosInterceptors';
import listeners from './listeners';

const store = createStore({
  modules: {
    AccountsStore,
    AppStore,
    BillsStore,
    EstimatesStore,
    FinancingStore,
    FullstoryStore,
    LinkedAccountsStore,
    MessagesStore,
    NMIGatewayStore,
    NotificationsStore,
    PaymentFormsGatewayStore,
    PaymentGatewayStore,
    PaymentsStore,
    ProvidersStore,
    ReceiptStore,
    ServerStatusStore,
    UsersStore,
  },
});

listeners.initialize(store);

export default store;

axios.interceptors.request.use((config) => onRequest(config, store));
axios.interceptors.response.use((response) => onResponse(response, store));
