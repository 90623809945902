<template>
<div class="accounts-summary-content vuetify">
    <div class="page-title">{{ $translate('providersDashboard.accounts') }}</div>
    <div v-if="currentProvider">
        <div v-for="account in sortedAccounts" :key="account.accountId" class="accounts-summary-content-card" @click="goToAccountDetails(account.accountId)">
            <accounts-summary-account-card
                :account="account"
            ></accounts-summary-account-card>
        </div>
    </div>
</div>
</template>

<script>
import _ from 'lodash';
import AccountsSummaryAccountCard from './AccountsSummaryAccountCard.vue';

export default {
    name: 'AccountsSummaryContent',
    components: {
        AccountsSummaryAccountCard,
    },
    computed: {
        currentProvider() {
            return this.$store.getters.currentProvider;
        },
        providers() {
            return this.$store.getters.providers;
        },
        sortedAccounts() {
            // Sort by due date ascending, but push financed accounts, negative balance accounts, and zero balance accounts to the bottom
            if (this.currentProvider && this.currentProvider.accounts) {
                const accounts = this.currentProvider.accounts;
                let partitions,
                    financed,
                    greaterThanZero,
                    zero,
                    lessThanZero;
                partitions = _.partition(accounts, (acc) => {
                    return acc.financedBy && acc.amountDue > 0;
                });
                financed = partitions[0];
                partitions = _.partition(partitions[1], (acc) => {
                    return acc.amountDue > 0;
                });
                greaterThanZero = _.sortBy(partitions[0], (acc) => {
                    return new Date(acc.dueDate);
                });
                partitions = _.partition(partitions[1], (acc) => {
                    return acc.amountDue === 0;
                });
                zero = partitions[0];
                lessThanZero = partitions[1];
                return greaterThanZero.concat(financed, lessThanZero, zero);
            }
            return [];
        },
    },
    methods: {
        async loadCurrentProvider() {
            await this.$nextTick();
            this.$store.commit('setShowLoading', true);
            await this.$store.dispatch('syncProviders');
            try {
                const provider = await this.$store.dispatch('getProviderById', this.getProviderIdFromRoute());
                this.$store.commit('setCurrentProvider', provider);
                this.onAccountVerificationUpdate();
                if (provider.accounts && provider.accounts.length === 1) {
                    this.$router.replace({
                        name: 'AccountDetails',
                        params: {
                            accountId: provider.accounts[0].accountId,
                            providerId: provider.id,
                        },
                    });
                } else {
                    this.$store.commit('setShowLoading', false);
                }
            } catch {
                // Unable to load provider, go back to Providers Summary where the user can see their list of providers
                this.$router.push({name: 'ProvidersSummary'});
            }
        },
        getProviderIdFromRoute() {
            // return this.$route.params.providerId;  // Use this once we're on vue-router
            return window.location.hash.split("provider/")[1];
        },
        onAccountVerificationUpdate() {
            this.$store.dispatch('refreshLinkableAccountsNotificationBanner', this.currentProvider);
        },
        goToAccountDetails(accountId) {
            this.$router.push({
                name: 'AccountDetails',
                params: {
                    accountId: accountId,
                    providerId: this.currentProvider.id,
                },
            });
        },
        refreshEmailAndPhoneNotificationBanners() {
            this.$store.dispatch('refreshEmailAndPhoneNotificationBanners');
        },
    },
    mounted() {
        this.loadCurrentProvider();
        this.emitter.on('accountVerification:updated', this.onAccountVerificationUpdate);
        this.emitter.on('userSettings:updated', this.refreshEmailAndPhoneNotificationBanners);
        this.$store.dispatch('refreshEmailAndPhoneNotificationBanners');
    },
    destroyed() {
        this.emitter.off('accountVerification:updated', this.onAccountVerificationUpdate);
        this.emitter.off('userSettings:updated', this.refreshEmailAndPhoneNotificationBanners);
    },
};
</script>
<style lang="scss">
@import './../styles/variables.scss';

.page-title {
    color: $global-color-font-new;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    font-size: 2.4rem;
    line-height: 2.4rem;
    margin-top: 3rem;
    @include wider-than-tablet {
        font-size: 2.4rem;
        line-height: 3.6rem;
        margin-top: 0px;
    }
}
.vuetify.accounts-summary-content {
    @include wider-than-tablet {
        min-height: 35rem;
    }
    .accounts-summary-content-card {
        @include wider-than-tablet {
            padding: 0 0.5rem;
        }
        margin-bottom: 1rem;
        cursor: pointer;
        a {
            text-decoration: none;
        }
        .v-card {
            margin: 0;
        }
    }
}
</style>