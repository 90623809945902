<template>
<div>
    <div ref="sskydiv" id="sskydiv">
    </div>
    <div ref="close" class="sunday-sky-close-icon">
        <base-icon color="white" @click="$emit('exit')" class="vuetify" icon="mdi-close"></base-icon>
    </div>
</div>
</template>

<script>
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'SundaySkyPlayer',
    props: {
        posterUrl: String,
        programId: String,
    },
    components: {
        BaseIcon
    },
    methods: {
        async loadPlayer() {
            await this.$nextTick();
            if (!document.getElementById('ssky-src')) {
                const script = document.createElement("script");
                script.src = 'https://play.sundaysky.com/main/sundaysky-player.grey.en-us.min.js';
                script.id = 'ssky-src';
                document.head.appendChild(script);
            }
            const sskyProgramId = this.programId;
            const rawSSkyVideoResponse = await this.$store.dispatch('retrieveSundaySkyVideo', sskyProgramId);
            if (rawSSkyVideoResponse && rawSSkyVideoResponse.response) {
                const sskyplayer = document.createElement('sundaysky-video');
                sskyplayer.setAttribute('id', 'sskyplayer');
                sskyplayer.setAttribute('analytics-token', sskyProgramId);
                sskyplayer.setAttribute('session', rawSSkyVideoResponse.response);
                sskyplayer.setAttribute('poster', this.posterUrl);
                sskyplayer.setAttribute('transcript-button', 'show');
                sskyplayer.setAttribute('no-autoplay', 'true');
                this.$refs.sskydiv.appendChild(sskyplayer);
                sskyplayer.addEventListener('userAction', (e) => {
                    if (e && e.detail && 'toggleFullscreen' === e.detail.action && !e.detail.value) {
                        this.$emit('exit');
                    }
                    e.preventDefault();
                });

                this.$refs.close.style.opacity = 1;
                if (document.querySelector('.video-js')) {
                    document.querySelector('.video-js').appendChild(this.$refs.close);
                }
            }
        }
    },
    mounted() {
        this.loadPlayer();
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.sunday-sky-close-icon {
    background: $global-color-darkish-grey;
    border-radius: 50%;
    opacity: 0;
    position: absolute;
    right: 2.4rem;
    top: 2.4rem;
    z-index: 999;
    i {
        color: white !important;
        font-size: 2.2rem;
        margin: 2px;
    }
}

sundaysky-video, sundaysky-video.horizontal {
    width: 100rem !important;
}

.vjs-big-play-centered .vjs-big-play-button {
    top: 45% !important;
    left: 45% !important;
    margin-left: 0 !important;
}
</style>