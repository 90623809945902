<template>
<div class="settings-content">
	<div class="block-segment">

		<h2 class="call-out settings-details-header settings-saved-methods-header">{{ $translate('labels.savedMethods') }}</h2>
		<div class="separator"></div>

		<div class="method-item" v-show="!methodsFetched">{{ $translate('labels.updating') }}</div>

		<div class="method-item"
			 v-show="methodsFetched && paymentMethods().length === 0">{{ $translate('labels.noSavedMethods') }}</div>

		<div class="method-item saved-method" v-for="(method, i) in paymentMethods()" :key="i">
			<div>
				<span class='method-desc fs-block'>{{ getMethodDescriptor(method) }}</span>
				<button class="delete-method button-warn button-sml"
                    :disabled="method.hasScheduledPayment"
                    @click="deleteMethod(method.id)"
                >
                    {{ $translate((method.formType.toLowerCase() === 'echeck' ? 'actions.deleteECheck' : 'actions.deleteCard')) }}
                </button>
				<div class="help-text" v-show="method.hasScheduledPayment" v-html="$translate('setting.unremovableMethodHelp')"></div>
			</div>
			<div class="separator"></div>
		</div>

		<button class="add-method settings-primary-action button-secondary button-sml" @click="addNewMethod()">
            {{ $translate('actions.addNewMethod') }}
        </button>
	</div>

	<div class="block-segment">

		<h2 class="call-out settings-financing-methods-header">{{ $translate('labels.financingMethods') }}</h2>
		<div class="separator"></div>

		<div class="method-item" v-show="!methodsFetched">{{ $translate('labels.updating') }}</div>

		<div class="method-item"
            v-show="methodsFetched && financingMethods().length === 0">
            {{ $translate('labels.noFinancingMethods') }}
        </div>

		<div class="method-item financing-account" v-for="(method, i) in financingMethods()" :key="i">
			<div>
				<span class='method-desc fs-block'>{{ getMethodDescriptor(method) }}</span>
				<button class="button-primary button-sml" @click="moreInfo(method.id)">{{ $translate('labels.moreInfo') }}</button>
			</div>
			<div class="separator"></div>
		</div>
	</div>
    <add-payment-method-dialog ref="addPaymentMethodDialog" @submit="submitNewMethod" :submitting="submittingNewMethod" :error="addPaymentMethodError"></add-payment-method-dialog>
</div>
</template>
<script>

import { getMethodDescriptor } from '../utils/utils';
import _ from 'lodash';
import AddPaymentMethodDialog from './AddPaymentMethodDialog.vue';

export default {
    name: 'SettingsSavedPaymentMethodsContent',
    components: {
        AddPaymentMethodDialog,
    },
    data: () => ({
        addPaymentMethodError: null,
        methodsFetched: false,
        submittingNewMethod: false,
    }),
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        }
    },
    methods: {
        getMethodDescriptor,
        deleteMethod(formId) {
            var method = _.find(this.currentUser.savedMethods, {id: formId});
        
            if (!method) {
                console.error('A delete was requested for a form that we do not know about, id:', formId);
                return;
            }

            if (method.hasScheduledPayment) {
                console.error('A delete was requested for a form that has a scheduled payment, id:', formId);
                return;
            }

            // present confirmation
            this.emitter.emit('simpleModal:showPrompt', {
                header: this.$translate('setting.confirmDeleteDialog'),
                subcontent: getMethodDescriptor(method),
                includeClasses: 'settings-delete-method',
                actions: [{
                    label: this.$translate('setting.deleteMethodArg', {detail: method.formType}),
                    includeClasses: 'button-primary',
                    clickHandler: () => {
                        // submit the delete request to server
                        this.$store.dispatch('deleteSavedMethod', method.id).then(() => {
                            // show thank you
                            this.emitter.emit('simpleModal:showPrompt', {
                                header: this.$translate('setting.argMethodDeleted', {method: getMethodDescriptor(method, 'long', '&nbsp;')}),
                                intent: 'success',
                                autoExpire: true,
                            });
                        }).finally(() => {
                            this.$forceUpdate();
                        });
                    },
                }]
            });
        },
        addNewMethod() {
            if (this.$refs && this.$refs.addPaymentMethodDialog) {
                this.$refs.addPaymentMethodDialog.open();
            }
        },
        async loadMethods() {
            this.methodsFetched = false;
            try {
                await this.$store.dispatch('fetchSavedMethods', {
                    filterExpired: false,
                    filterAcceptedMethods: false,
                });
            } finally {
                this.methodsFetched = true;
            }
        },
        moreInfo(formId) {
            var method = _.find(this.currentUser.savedMethods, {id: formId});
            method.formattedAvailableCredit = this.$formatCurrency(method.availableCredit);

            this.emitter.emit('simpleModal:showPrompt', {
                header: this.$translate('payment.financing.paymentForm.infoHeader', method),
                subcontent: this.$translate('payment.financing.paymentForm.infoContent', method),
                includeClasses: 'settings-more-info',
                actions: [{
                    label: this.$translate('actions.close'),
                    includeClasses: 'button-primary'
                }]
            });
        },
        paymentMethods() {
            const methods = this.currentUser.savedMethods;
            const types = ['card','echeck'];
            if(_.isUndefined(methods) || !_.isArray(methods)) {
                return [];
            }
            return _.filter(methods, function (method) {
                return types.indexOf(method.formType) > -1;
            });
        },
        financingMethods() {
            const methods = this.currentUser.savedMethods;
            const types = ['financing'];
            if(_.isUndefined(methods) || !_.isArray(methods)) {
                return [];
            }
            return _.filter(methods, function (method) {
                return types.indexOf(method.formType) > -1;
            });
        },
        async submitNewMethod({ newCard, newECheck }) {
            this.submittingNewMethod = true;
            this.addPaymentMethodError = null;
            try {
                if (newCard) {
                    await this.$store.dispatch('submitPaymentForm', { paymentForm: { method: { card: newCard }, billing: newCard.billing } });
                } else if (newECheck) {
                    await this.$store.dispatch('submitPaymentForm', { paymentForm: { method: { eCheck: newECheck }, billing: newECheck.billing } });
                }
                if (this.$refs && this.$refs.addPaymentMethodDialog) {
                    // close the dialog after a success and force it to clear out its data
                    this.$refs.addPaymentMethodDialog.close(true);
                }
                this.loadMethods();
            } catch {
                this.addPaymentMethodError = this.$translate('newPaymentFormFlow.genericError');
            } finally {
                this.submittingNewMethod = false;
            }
        },
    },
    mounted() {
        this.loadMethods();
    },
};
</script>