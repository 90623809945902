<template>
<div v-if="provider" class="vuetify need-help-card" data-testid="need-help-card">
    <content-card :headerTitle="$translate('labels.needHelp')" headerIcon="mdi-hospital-box-outline" padded>
        <need-help @composemessage="$emit('composemessage')" :provider="provider"></need-help>
    </content-card>
</div>
</template>

<script>
import NeedHelp from './NeedHelp.vue';
import ContentCard from './ContentCard.vue';

export default {
    name: 'NeedHelpCard',
    components: {
        NeedHelp,
        ContentCard,
    },
    props: {
        provider: Object,
    },
};
</script>
<style lang="scss">
.need-help-card {
    .card-header {
        .v-icon {
            padding: .9rem .9rem .7rem .9rem;
        }
    }
}
</style>