import _ from 'lodash';
import vueEventTransmitter  from '../../utils/vueEventTransmitter';
import http from '../../utils/http';

import { CardUtils, translate, generateDeferredPromise } from './../../utils/utils.js';

const DEFAULT_SESSIONTIMEOUT = 3600000,
        DEFAULT_CLIENT_MODAL_GRACE_PERIOD = 120000,
        DEFAULT_HB_FREQ = 3480000;

const getDefaultState = () => {
    return {
        currentUser: null,
        userStatusKnown: false,
    
        autoLogout: {
            timeUntilWarning: null,
            lastInteractionTime: null,
            windowIsLoggedInVal: null,
            sessionTimeout: null,
            hbFreq: null,
            dlgVisible: false,
            hbPromise: null,
            enabled: false,
        }
    };
}

const state = getDefaultState();

const getters = {
    currentPatientUserId(state) {
        if (state.currentUser && state.currentUser.patientUserId) {
            return state.currentUser.patientUserId;
        }
        return null;
    },
    getUserStatusKnown(state) {
        return state.userStatusKnown;
    },
    currentUser(state) {
        return state.currentUser;
    }

};

const mutations = {
    setCurrentUser(state, val) {
        state.currentUser = val;
    },
    setUserStatusKnown(state, val) {
        state.userStatusKnown = val;
    },
    setTimeUntilWarning(state, val) {
        state.autoLogout.timeUntilWarning = val;
    },
    setLastInteractionTime(state, val) {
        state.autoLogout.lastInteractionTime = val;
    },
    setWindowIsLoggedInVal(state, val) {
        state.autoLogout.windowIsLoggedInVal = val;
    },
    setSessionTimeout(state, val) {
        state.autoLogout.sessionTimeout = val;
    },
    setHbFreq(state, val) {
        state.autoLogout.hbFreq = val;
    },
    setHbPromise(state, val) {
        state.autoLogout.hbPromise = val;
    },
    setAutoLogoutEnabled(state, val) {
        state.autoLogout.enabled = val;
    },
    setDlgVisible(state, val) {
        state.autoLogout.dlgVisible = val;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    changeCurrentUser({ commit }, user) {
        commit('setCurrentUser', user);
        const wasLoggedIn = localStorage.getItem('loggedIn');
        if (user && wasLoggedIn !== '1') {
            localStorage.setItem('loggedIn', '1');
        } else if (!user && wasLoggedIn !== '0') {
            localStorage.setItem('loggedIn', '0');
        }
        localStorage.setItem('showLoadingIndicatorOnLoad', user ? '1' : '0');

        //Generic user changed event
        vueEventTransmitter.emit('user:changed', user);
        commit('setUserStatusKnown', true);
    },
    fetchCurrentUser({ dispatch }) {
        return http.getDataOrThrow({
            method: 'GET',
            url: 'patientUser',
        }).then((data) => {
            dispatch('changeCurrentUser', data);
            return data;
        }).catch((e) => {
            if (e && 'UNAUTHORIZED_ACCESS' === e.errorCode) {
                dispatch('changeCurrentUser', null);
            }
            throw e;
        });;
    },
    logIn({ dispatch }, formData) {
        return http.getDataOrThrow({
            method: 'POST',
            data: formData,
            url: 'patientUser/login',
        }).then((currentUser) => {
            dispatch('changeCurrentUser', currentUser);
            window.FS.log('log', 'session user assigned');
            if (window['_fs_namespace']) {
                window.FS.identify(currentUser.patientUserId, {
                    displayName: 'Patient User ' + currentUser.patientUserId,
                    newUser: currentUser.newUser,
                });
            }
            return currentUser;
        });
    },
    resendVerification({}, email) {
        return http.post('patientUser/resendVerification', email);
    },
    checkPatientUserStatus({}, { token, context }) {
        return http.post('patientUser/checkPatientUserStatus', { token, context });
    },
    retrieveMetaInformation({}, token) {
        return http.post('patientUser/retrieveMetaInformation', { token });
    },
    myChartSSOSendVerification({}, { email, firstName, source, token }) {
        return http.post('patientUser/myChartSSOSendVerification', { email, firstName, source, token });
    },
    confirmSSOVerifyCode({}, { email, verificationCode, source, token }) {
        return http.post('patientUser/confirmSSOVerifyCode', { email, verificationCode, source, token });
    },
    registerSSOUser({}, { email, verificationCode, acceptTerms, source, token }) {
        return http.post('patientUser/registerSSOUser', { email, verificationCode, acceptTerms, source, token });
    },
    forgotPassword({}, email) {
        return http.post('patientUser/forgot', { email });
    },
    resetPassword({}, { tokenId, password }) {
        return http.post('patientUser/resetPassword', { tokenId, password });
    },
    sendHeartbeat() {
        return http.get('patientUser');
    },

    // These two are used by NPS from receipts, etc.
    addFeedback({}, { token, score, useNPS }) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { token, score, useNPS },
            url: 'patientUser/feedback',
        });
    },
    editFeedback({}, { id, token, score, comment, useNPS, reasons }) {
        return http.post('patientUser/feedback', { id, token, score, comment, useNPS, reasons});
    },

    // These three are used by NPS in app
    getFeedbackOptions({}, { isGuestPay }) {
        return http.getDataOrThrow({
            method: 'GET',
            url: 'feedback/options',
            params: { isGuestPay },
        });
    },
    updateFeedbackScore({}, { paymentId, score, id, isGuestPay }) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { paymentId, score, id, isGuestPay },
            url: 'feedback',
        });
    },
    updateFeedback({}, { id, paymentId, score, comment, reasons, isGuestPay }) {
        return http.post('feedback', { id, paymentId, score, comment, reasons, isGuestPay });
    },

    // feedback for video bill
    addVideoBillFeedback({}, { providerId, patientUserId, score, comment, structuredFeedback }) {
        return http.post('patientUser/videoFeedback', { providerId, patientUserId, score, comment, structuredFeedback });
    },

    deactivateAccount({}, currentPassword) {
        return http.post('patientUser/deactivateAccount', { currentPassword });
    },
    disassociateAccount({}, disassociateAccountId) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { disassociateAccountId },
            url: 'patientUser/disassociateAccount',
        });
    },
    acceptTerms() {
        return http.post('patientUser/acceptTerms', {});
    },
    emailAvailable({}, { email, isUpdate }) {
        return http.getDataOrThrow({
            method: 'POST',
            url: 'patientUser/emailAvailable',
            data: {
                email,
                isUpdate: isUpdate || false,
            },
            successFlag: 'valid',
        }).then(() => true);
    },
    getSmsEligibility({}, number) {
        return http.getDataOrThrow({
            method: 'POST',
            url: 'patientUser/getSmsEligibility',
            data: { number },
            successFlag: 'valid',
        }).then(() => true);
    },
    logOut({ dispatch }) {
        // send a request to the server to destroy the cookie
        // we will internally clear memory of the user
        return http.post('patientUser/logout').then(() => {
            dispatch('changeCurrentUser', null);
        });
    },
    forgotPasswordGetCode({}, email) {
        window.FS.event('session user PatientUsersService::forgotPasswordGetCode', {
            method: 'sms'
        });
        return http.getDataOrThrow({
            method: 'POST',
            data: { email },
            url: 'patientUser/forgotPasswordGetCode',
            successFlag: 'success',
        });
    },
    forgotPasswordSendEmail({}, email) {
        window.FS.event('session user PatientUsersService::forgotPasswordSendEmail', {
            method: 'email'
        });
        return http.getDataOrThrow({
            method: 'POST',
            data: { email },
            url: 'patientUser/forgotPasswordSendEmail',
            successFlag: 'success',
        });
    },
    forgotPasswordVerifyCode({}, { email, method, code }) {
        window.FS.event('session user PatientUsersService::forgotPasswordVerifyCode', {
            method: method,
            code: code
        });
        return http.getDataOrThrow({
            method: 'POST',
            data: { email, method, code },
            url: 'patientUser/forgotPasswordVerifyCode',
            successFlag: 'success',
        });
    },
    verifyEmail({ state }, { token, patientUserId }) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { token, patientUserId },
            url: 'patientUser/verifyEmail',
        }).then((resp) => {
            if (state.currentUser) {
                state.currentUser.emailVerified = true;
            }
            return resp;
        });
    },
    verifyPhone({ state }, { code, patientUserId, phoneNumber }) {
        return http.post('patientUser/verifyPhone',  { code, patientUserId, phoneNumber }).then((resp) => {
            if (state.currentUser) {
                state.currentUser.phoneNumberConfirmed = true;
            }
            return null;
        }).catch(error => { throw error; });
    },
    backdoorLogin({ dispatch }, token) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { token },
            url: 'patientUser/token',
        }).then((currentUser) => {
            dispatch('changeCurrentUser', currentUser);
            window.FS.log('log', 'session user assigned');
            return currentUser;
        });
    },
    demoSSO({ dispatch }, token) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { token },
            url: 'patientUser/demoSSO',
        }).then((currentUser) => {
            dispatch('changeCurrentUser', currentUser);
            window.FS.log('log', 'session user assigned');
            return currentUser;
        });
    },
    myChartSSO({ dispatch }, body) {
        return http.getDataOrThrow({
            method: 'POST',
            data: body,
            url: 'patientUser/MyChartSSO',
        }).then((data) => {
            if (data.patientUserId) {
                const currentUser = data;
                dispatch('changeCurrentUser', currentUser);
                window.FS.log('log', 'session user assigned');
            }
            return data;
        });
    },
    confirmPhoneGetCode({ state }, { phone, currentPassword }) {
        const currentUser = state.currentUser;
        if (currentUser) {
            if (currentUser.phoneNumber !== phone) {
                window.FS.log('log', 'session user update-phone-confirm-get-code');
            } else {
                window.FS.log('log', 'session user send-phone-confirm-get-code');
            }
        }
        return http.getDataOrThrow({
            method: 'POST',
            data: { phone, currentPassword },
            url: 'patientUser/confirmPhoneGetCode',
            successFlag: 'success',
        }).then((data) => {
            _.assign(state.currentUser, { phoneNumber: phone });
            return data;
        });
    },
    confirmPhoneVerifyCode({ state }, code) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { code },
            url: 'patientUser/confirmPhoneVerifyCode',
            successFlag: 'success',
        }).then((data) => {
            _.assign(state.currentUser, { phoneNumberConfirmed: true });
            vueEventTransmitter.emit('userSettings:updated');
            return data;
        });
    },
    confirmEmailSendVerification({ state }, { email, currentPassword }) {
        const currentUser = state.currentUser;
        if (currentUser) {
            if (currentUser.email !== email) {
                window.FS.log('log', 'session user update-email-send-verification');
            } else {
                window.FS.log('log', 'session user send-email-verification');
            }
        }
        return http.getDataOrThrow({
            method: 'POST',
            data: { email, currentPassword },
            url: 'patientUser/confirmEmailSendVerification',
            allowNullData: true,
        }).then((data) => {
            _.assign(state.currentUser, { email });
            return data;
        });
    },
    confirmEmailVerifyCode({ dispatch }, code) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { code },
            url: 'patientUser/confirmEmailVerifyCode',
        }).then((currentUser) => {
            dispatch('changeCurrentUser', currentUser);
            return currentUser;
        });
    },
    deleteSavedMethod({ state }, id) {
        return http.delete(`paymentForms/${id}`).then((res) => {
            _.remove(state.currentUser.savedMethods, m => m.id === id);
            return res;
        });
    },
    // pass the form data to create a new user account
    // we will update the newUser flag on the user response
    // to be true to help know this was a new user.
    register({ dispatch }, formData) {
        return http.getDataOrThrow({
            method: 'POST',
            data: formData,
            url: 'patientUser/register',
        }).then((currentUser) => {
            if (_.isUndefined(currentUser.newUser)) {
                // flag to give us a quick check on
                // whether the user is not expected to have
                // existing data (saved cards for example)
                currentUser.newUser = true;
            }
            dispatch('changeCurrentUser', currentUser);
            window.FS.log('log', 'session user assigned');
            return currentUser;
        });
    },
    // Try to get user, remember state
    // if we have a user, continue but if
    // we don't, prompt for login the redirect back to state
    requireAuthedUser({ getters }, loginOptions) {
        let patientUserId = getters.currentPatientUserId;
        let promise = generateDeferredPromise();
        
        if (patientUserId) {
            promise.resolve(patientUserId);
        } else {
            vueEventTransmitter.emit('login:showPrompt', {defer:promise, options:loginOptions});
        }
        return promise.promise;
    },
    // Send logout server request.
    // If we are unable to log the user out,
    // we will clear our cookie for the server
    unauthenticateUser({ rootState, getters, dispatch }, showModal) {
        if (showModal) {
            let subdomain = getters.subdomain;
            vueEventTransmitter.emit('simpleModal:showPrompt', {
                header: translate('dialogs.successfulLogout'),
                subcontent: translate((subdomain ? 'whitelabel.' : '') + 'dialogs.thanksAndComeBack'),
                intent: 'success',
                autoExpire: true
            });
        }
        return dispatch('logOut').then(function() {
            vueEventTransmitter.emit('data:clear');
            if (!rootState.stateObject || !rootState.stateObject.current || !rootState.stateObject.current.data || !rootState.stateObject.current.data.authNotRequired) {
                rootState.AppStore.router.push({
                    name: 'Landing',
                });
            }
        }).catch(function (e) {
            console.error('Unable to log the user out on server', e);
        });
    },

    configureAutoLogout({ commit, dispatch }, { enabled, sessionTimeout, clientModalGracePeriod, hbFreq }) {
        commit('setAutoLogoutEnabled', enabled);
        commit('setSessionTimeout', sessionTimeout);
        commit('setTimeUntilWarning', sessionTimeout - clientModalGracePeriod);

        // leave the interval alone unless the value is actually changing
        if (state.autoLogout.hbFreq !== hbFreq) {
            if (state.autoLogout.hbPromise) {
                // must cancel existing interval before we create a new one
                window.clearInterval(state.autoLogout.hbPromise);
                commit('setHbPromise', undefined);
            }
            commit('setHbFreq', hbFreq);
            commit('setHbPromise', window.setInterval(() => {
                if (state.currentUser) {
                    dispatch('sendHeartbeat');
                }
            }, hbFreq));
        }
    },

    async startAutoLogout({ rootState, commit, dispatch, getters, state }, enabled) {
        function closeDialogIfVisible() {
            if (state.autoLogout.dlgVisible) {
                // if we're at the point where we're going to log the user out, then the dialog should definitely be visible
                vueEventTransmitter.emit('simpleModal:hidePrompt');
                commit('setDlgVisible', false);
            }
        }
        function keepSessionAlive(broadcastToOtherTabs) {
            if (state.currentUser) {
                var lastInteractionTime = Date.now();
                commit('setLastInteractionTime', lastInteractionTime);
                closeDialogIfVisible();
                if (broadcastToOtherTabs) { 
                    localStorage.setItem('lastInteractionTime', lastInteractionTime); //push to other tabs
                } 
            }
        }
        await dispatch('configureAutoLogout', {
            enabled,
            sessionTimeout: DEFAULT_SESSIONTIMEOUT,
            clientModalGracePeriod: DEFAULT_CLIENT_MODAL_GRACE_PERIOD,
            hbFreq: DEFAULT_HB_FREQ,
        });

        ['mousemove', 'keydown', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'scroll'].forEach((e) => {
            document.body.addEventListener(e, _.debounce(() => {
                if (!state.autoLogout.dlgVisible) {
                    keepSessionAlive(true);
                }
            }, 200));
        });
        window.addEventListener('storage', (e) => {
            if (e.key === 'lastInteractionTime') {
                commit('setLastInteractionTime', parseInt(e.newValue));  // keep lastInteractionTime in sync with other tabs
                keepSessionAlive(false);
            } else if (e.key === 'loggedIn') {
                if (getters.config.env !== 'test') {
                    if (!parseInt(e.newValue) && state.currentUser) {
                        dispatch('unauthenticateUser', /*showModal*/ true); // manually logged-out on another tab.  log-out here too
                    } else {
                        const oldUserId = getters.currentPatientUserId;

                        dispatch('fetchCurrentUser').then(function(user) {
                            // if we swapped users in another tab
                            if (oldUserId !== user.patientUserId) {
                                vueEventTransmitter.emit('login:closePrompt');
                                rootState.AppStore.router.push({
                                    name: 'Home',
                                });
                            }
                        });
                    }
                }
            }
        });
        keepSessionAlive(true);

        window.setInterval(() => {
            if (state.currentUser && state.autoLogout.enabled) {
                const inactiveTimeInMS = Date.now() - state.autoLogout.lastInteractionTime;
                if (inactiveTimeInMS >= state.autoLogout.sessionTimeout) {
                    closeDialogIfVisible();
                    dispatch('unauthenticateUser', /*showModal*/ true);
                } else if (inactiveTimeInMS >= state.autoLogout.timeUntilWarning) { 
                    if (!state.autoLogout.dlgVisible) {
                        vueEventTransmitter.emit('simpleModal:showPrompt', {
                            header: translate('dialogs.logoutWarningHeader'),
                            subcontent: translate('dialogs.logoutWarningSubcontent'),
                            intent: 'information',
                            meta: 'warning',
                            actions: [{
                                label: translate('actions.stayLoggedIn')
                            }],
                            closeHandler: () => {
                                keepSessionAlive(true);
                                commit('setDlgVisible', false);
                            }
                        });
                        commit('setDlgVisible', true);
                    }
                }
            }

            if (state.autoLogout.windowIsLoggedInVal !== Boolean(state.currentUser)) {
                localStorage.setItem('loggedIn', state.currentUser ? 1 : 0);  // auth status changed.  broadcast to other tabs
                commit('setWindowIsLoggedInVal', Boolean(state.currentUser));
            }
        }, 1000);
    },

    fetchSavedMethods({ state }, { filterExpired, filterAcceptedMethods }) {
        return http.getDataOrThrow({
            url: 'paymentForms',
            method: 'GET',
        }).then((methods) => {
            state.currentUser.savedMethods = methods;
            return _.filter(state.currentUser.savedMethods, (method) => {
                let pass = true;

                // filtering by expired logic
                if (pass && filterExpired){
                    pass = _.trim(method.formType).toLowerCase() === 'card' ? !CardUtils.isExpired(method.expDate) : true;
                }

                // filter by acceptable methods
                if (pass && _.isArray(filterAcceptedMethods)){
                    filterAcceptedMethods = _.map(filterAcceptedMethods, function(s){
                        return _.trim(s).toLowerCase();
                    });
                    pass = _.includes(filterAcceptedMethods, _.trim(method.formType).toLowerCase());
                }

                return pass;
            });
        });
    },

    updateUser({ state }, userUpdateDetails) {
        return http.post('patientUser', userUpdateDetails).then((resp) => {
            if (resp.hasData()) {
                const currentUser = state.currentUser;
                if (!_.isUndefined(resp.getData().phoneNumberConfirmed)) {
                    userUpdateDetails.phoneNumberConfirmed = resp.getData().phoneNumberConfirmed;
                }
    
                if (resp.getData().email !== currentUser.email) {
                    currentUser.newEmail = true;
                }
    
                // update the current user's info
                _.assign(currentUser, _.omit(userUpdateDetails, ['newPassword', 'oldPassword']));
            }
        });
    },

    refreshEmailAndPhoneNotificationBanners({ commit, state }) {
        if (state.currentUser) {
            if (!state.currentUser.emailVerified && !state.currentUser.newUser && !state.currentUser.newEmail) {
                commit('setVisibleNotificationBanner', {
                    action: () => vueEventTransmitter.emit('confirmEmail:prompt'),
                    text: translate('actions.confirmEmailnew.message'),
                });
            } else if (state.currentUser.phoneNumber && !state.currentUser.phoneNumberConfirmed && !state.currentUser.newUser) {
                commit('setVisibleNotificationBanner', {
                    action: () => vueEventTransmitter.emit('confirmPhone:prompt'),
                    text: translate('actions.confirmPhoneNumberNew.message'),
                });
            } else {
                commit('setVisibleNotificationBanner', null);
            }
        }
    },

    fetchPaymentHistory() {
        return http.getDataOrThrow({
            method: 'GET',
            url: 'patientUser/paymentHistory'
        }).then((result) => {
            return result;
        });
    }
};

export default {
    state, getters, mutations, actions,
};
