<template>
    <div class="find-bill-container">
        <!-- FORM SUBMISSION RESPONSE FEEDBACK -->
      <div v-show="searchErrors.notFound || searchErrors.throttled" class="interaction-feedback">
          <div v-show="searchErrors.notFound">
              {{ $translate('errors.unableToFindBill') }}
          </div>
          <div v-show="searchErrors.throttled">
              {{ $translate('errors.billThrottled') }}
          </div>
      </div>

      <!-- SECURECODE -->
      <div :class="{'input-area-wrapper': true, 'errored': forms.sCodeForm.error, 'input-focused': forms.sCodeForm.focused}" id="securecode">
          <div class="input-area-field-wrapper">
              <span class=autofillwidth>
                  <input
                    class="fs-block"
                    @keydown.enter="onClickFindBill"
                    @input="onInputSecureCode"
                    @focus="forms.sCodeForm.focused = true"
                    @blur="forms.sCodeForm.focused = false"
                    @change="forms.sCodeForm.dirty = true"
                    v-model="findData.sCode"
                    type="text"
                    required
                    name="scode"
                    maxlength="11"
                    autocomplete="off"
                    :placeholder="$translate('labels.secureCodeOnBill')"
                    :title="$translate('actions.enterSHCTooltip')"/>
              </span>
          </div>
          <div class="input-area-feedback">
            <div class="vue-messages" v-if="forms.sCodeForm.dirty || submitted">
                <div class="vue-message">
                    <div>
                        {{ forms.sCodeForm.error }}
                    </div>
                </div>
            </div>
          </div>
      </div>

      <!-- AMOUNT -->
      <div :class="{'input-area-wrapper': true, 'errored': forms.billAmountForm.error, 'input-focused': forms.billAmountForm.focused}" autocomplete="off" id="amount">

          <div class="input-area-field-wrapper">
              <span class=autofillwidth>
                  <input
                    class="fs-block"
                    @keydown.enter="onClickFindBill"
                    @input="onInputBillAmount"
                    @change="forms.billAmountForm.dirty = true"
                    @focus="forms.billAmountForm.focused = true"
                    @blur="onBlurBillAmount(); forms.billAmountForm.focused = false"
                    v-model="findData.billAmount"
                    type="text"  
                    name="billAmount" 
                    required
                    min="0"
                    maxlength="20"
                    :placeholder="$translate('labels.balanceOnBill')"
                    :title="$translate('actions.enterAmountTooltip')"
                    />
              </span>
          </div>
            <div class="input-area-feedback">
                <div class="vue-messages" v-if="forms.billAmountForm.dirty || submitted">
                    <div class="vue-message">
                        <div>
                            {{ forms.billAmountForm.error }}
                        </div>
                    </div>
                </div>
          </div>
      </div>
    
      <button @click="onClickFindBill" class="button-primary button-callout submitFindBill">{{ submitting ? $translate('actions.findingBill') : $translate('actions.findBill') }}</button>
    </div>
</template>

<script>
export default {
    name: 'FindBillContainer',
    props: {
        linkFunction: {
            default: 'findBill',
            type: String,
        },
    },
    data: () => ({
        forms: {
            error: false,
            billAmountForm: {
                dirty: false,
                focused: false,
                error: null,
            },
            sCodeForm: {
                dirty: false,
                focused: false,
                error: null,
            },
        },
        searchErrors: {},
        findData: {
            billAmount: null,
            rawBillAmount: null,
            sCode: null,
        },
        submitting: false,
        submitted: false,
    }),
    computed: {
        subdomain() {
            return this.$store.getters.subdomain;
        },
        currentUser() {
            return this.$store.getters.currentUser;
        }
    },
    methods: {
        onInputBillAmount(e) {
            if (this.findData.billAmount) {
                // strip out anything that's not a number or a decimal
                const cleaned = this.findData.billAmount.replace(/[^\d\.]+/g, '');
                const sections = cleaned.split('.');
                const dollars = sections[0];
                const cents = (sections[1] || '').slice(0, 2);
                const decimalIndex = cleaned.indexOf('.');
                let formatted = dollars;
                if (decimalIndex >= 0) {
                    formatted = formatted + '.' + cents;
                }
                if ('deleteContentBackward' !== e.inputType && (1 === cleaned.length && ('.' === cleaned || '0' === cleaned))) {
                    formatted = '0.';
                }
                this.findData.rawBillAmount = formatted;
                // return our formatted string with commas to mark thousands
                this.findData.billAmount = formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            this.validateBillAmount();
        },
        onBlurBillAmount() {
            if (!this.findData.billAmount) {
                // if the user clicked the input then immediately left for some reason
                this.findData.billAmount = '0.00';
            } else {
                // let's make sure our decimal is formatted properly
                const index = this.findData.billAmount.indexOf('.');
                if (index < 0) {
                    // e.g. $420
                    this.findData.billAmount += '.00';
                } else if (index === this.findData.billAmount.length - 1) {
                    // e.g. $. or $420.
                    this.findData.billAmount += '00';
                } else if (index === this.findData.billAmount.length - 2) {
                    this.findData.billAmount += '0';
                }
            }
        },
        onInputSecureCode(e) {
            if (this.findData.sCode) {
                // strip out anything that's not a number or letter
                const cleaned = this.findData.sCode.replace(/[^a-zA-Z0-9]$/g, '').toUpperCase();
                let formatted = '';

                // Rebuild the string with proper formatting
                for (let i = 0; i < cleaned.length; i++) {
                    const curChar = cleaned[i];
                    if(/[A-Za-z0-9]/.test(curChar)){
                        formatted += curChar;
                    }
                    if (formatted.length === 3 || formatted.length === 7){
                        formatted += '-';
                    }
                }

                // remove trailing hyphen if we are backspacing into it
                if (e.inputType ===  'deleteContentBackward' && (formatted.length === 4 || formatted.length === 8)) {
                    formatted = formatted.slice(0, -1);
                }
                this.findData.sCode = formatted;
            }
            this.validateSecureCode();
        },
        onClickFindBill() {
            this.submitted = true;
            this.resetSearchErrors();
            this.validateForm();
            if (!this.forms.error) {
                this.submitting = true;
                let notificationType = 'standard';
                if (!this.currentUser) {
                    notificationType = 'first';
                }
                this.$store.commit('setPaymentSource', {billType: 'bill', commType: null, notificationType: notificationType, origin: 'print'});
                this.$store.dispatch(this.linkFunction, {
                    billAmount: Number(this.findData.rawBillAmount),
                    secureCode: this.findData.sCode,
                    amount: Number(this.findData.rawBillAmount),
                    sCode: this.findData.sCode,
                }).then((res) => {
                    this.$router.push({name: 'BillSummary'});
                    this.$emit('found');
                }).catch((err) => {
                    if (err && err.errorCode) {
                        if ('SECURECODE_NOT_FOUND' === err.errorCode) {
                            this.searchErrors.notFound = true;
                        } else if ('SECURECODE_BLOCKED' === err.errorCode) {
                            this.searchErrors.throttled = true;
                        }
                    }
                }).finally(() => {
                    this.submitting = false;
                });
            }
        },
        validateForm() {
            this.validateBillAmount();
            this.validateSecureCode();
            this.forms.error = Boolean(this.forms.sCodeForm.error) || Boolean(this.forms.billAmountForm.error);
        },
        validateBillAmount() {
            if (!this.findData.billAmount) {
                this.forms.billAmountForm.error = this.$translate('errors.amountRequired');
            } else {
                this.forms.billAmountForm.error = null;
            }
        },
        validateSecureCode() {
            if (!this.findData.sCode) {
                if (this.subdomain) {
                    this.forms.sCodeForm.error = this.$translate('whitelabel.errors.scodeRequired');
                } else {
                    this.forms.sCodeForm.error = this.$translate('errors.scodeRequired');
                }
            } else if (/[0-9]{3}-[0-9]{3}-[0-9]{3}/.test(this.findData.sCode)) {
                this.forms.sCodeForm.error = this.$translate('errors.allNumericSCode');
            } else {
                this.forms.sCodeForm.error = null;
            }
        },
        resetSearchErrors() {
            this.searchErrors = {};
        },
        reset() {
            this.findData = {
                billAmount: null,
                rawBillAmount: null,
                sCode: null,
            };
            this.submitted = false;
            this.forms = {
                error: false,
                billAmountForm: {
                    dirty: false,
                    focused: false,
                    error: null,
                },
                sCodeForm: {
                    dirty: false,
                    focused: false,
                    error: null,
                },
            };
            this.searchErrors = {};
        },
    },
};
</script>

<style lang="scss">
@import '../styles/vuetify-overrides.scss';
    .find-bill-container {
        .interaction-feedback {
            margin-bottom: 1rem;
            border-radius: 5px;
        }
    }
</style>