<template>
<div v-if="showComponent" class="vuetify">
    <div class="sub-account-details-services-card">
        <content-card :headerTitle="$translate('labels.services')" headerIcon="mdi-stethoscope" padded>
            <sub-account-services :services="services"></sub-account-services>
        </content-card>
    </div>
</div>
</template>

<script>
import ContentCard from './ContentCard.vue';
import SubAccountServices from './SubAccountServices.vue';
export default {
    name: 'SubAccountServicesCard',
    components: {
        ContentCard,
        SubAccountServices,
    },
    props: {
        services: Array,
    },
    computed: {
        showComponent() {
            return this.services && this.services.length;
        },
    }
};
</script>
<style lang="scss">
@import '../styles/variables.scss';

.vuetify {
    .sub-account-details-services-card {
        .card-header {
            .v-icon {
                padding: 0.8rem 0.8rem 0.6rem 0.8rem;
            }
        }

        @include wider-than-tablet {
            .v-card .content-card-content {
                padding-left: 3.8rem;
                padding-right: 3.8rem;
            }
        }

        .content-card-content .data-grid .row {
            margin-bottom: 1.6rem;
            margin-top: 0;
        }
    }
}
</style>