<template>
    <div class="receipt-content" v-if="payment">
        <div v-if="showLinkableNotification" @click="completeAcct()">
            <Notification class="clickable linkable-notification" :showInformationIcon="true">
                <div v-html="$translate('encounterLinking.linkableAccounts')"></div>
            </Notification>
        </div>

        <div class="block-segment">
            <ProviderLogo :provider-id="payment.provider.id" :alt-text="payment.provider.name"></ProviderLogo>

            <div class="receipt-payment-details">
                <div data-testid="patient-name" class="fs-block receipt-payer">{{payment.name}}</div>
                <div v-if="payment.accountNumber" class="fs-block" data-testid="payment-account">{{$translate('labels.accountNumberArg', {number: payment.accountNumber}) }}</div>
                <div v-if="payment.voucherNumber" class="fs-block" data-testid="payment-voucher">
                    {{payment.voucherNumberLabel }} {{ payment.voucherNumber }}
                </div>
                <div data-testid="provider-name">{{payment.provider.name}}</div>
                <div class="fs-block" data-testid="payment-form">{{getMethodDescriptor(payment.paymentForm)}}</div><br>
                <div v-if="payment.cardholderName">
                    <div data-testid="bill-to" class="receipt-payer">{{ $translate('receipt.billTo') }}</div>
                    <div class="fs-block" data-testid="cardholder-name">{{payment.cardholderName}}</div>
                    <div class="fs-block" data-testid="cardholder-address">{{payment.cardholderAddress}}</div>
                    <div class="fs-block" data-testid="cardholder-phone">{{payment.cardholderPhoneNumber}}</div>
                </div>
            </div>
        </div>

        <div class="block-segment">

            <div class="receipt-payment-amount" v-html="callOutAmountStatus"></div>

            <ReceiptLineItems :isPaymentPlan="false" :lineItems="lineItems" :summationTotalFormatted="summationTotalFormatted"/>

            <p class="receipt-vendor-info" v-if="financingVendorInfo" v-html="financingVendorInfo"></p>

        </div>
    </div>
</template>

<script>

import { CardUtils, getMethodDescriptor, MoneyUtils, paymentStates, reversalReasons } from '../utils/utils';
import _ from 'lodash';
import ProviderLogo from './ProviderLogo.vue';
import Notification from './Notification.vue';
import ReceiptLineItems from './ReceiptLineItems.vue';

export default {
    name: 'ReceiptContent',
    components: {
        ProviderLogo,
        Notification,
        ReceiptLineItems,
    },
    computed: {
        payment() {
            return this.$store.getters.payment;
        },
        getHasUnverifiedAccountWithEncounterGroupId() {
            return this.$store.getters.getHasUnverifiedAccountWithEncounterGroupId;
        },
        FS() {
            return this.$store.getters.getFullstory;
        },
        paymentId() {
            return this.$route.params.paymentId;
        },
        isGuestPay() {
            return this.$store.getters.isGuestPay;
        },
    },
    data() {
        return {
            lineItems: [],
            callOutAmountStatus: null,
            summationTotalFormatted: null,
            financingVendorInfo: null,
            showLinkableNotification: false,
        };
    },
    methods: {
        getMethodDescriptor,
        completeAcct() {
            this.emitter.emit('accountVerification:prompt');
        },
        cancelReceiptPayment(){
            if (!(this.payment && this.paymentId)) {
                console.error('tried to cancel receipt payment without payment and/or receipt');
                return;
            }
            this.emitter.emit('simpleModal:showPrompt', {
                header: this.$translate('receipt.confirmCancelDialog'),
                subcontent: this.$translate('receipt.cancelPaymentDesc', {
                    date: this.$formatDate(this.payment.dateScheduled, 'MM/DD/YY'),
                    amount: this.$formatCurrency(this.payment.amount)
                }),
                // includeClasses: '',
                actions:[{
                    label: this.$translate('actions.cancelPayment'),
                    includeClasses: 'button-primary',
                    clickHandler: () => {
                        this.$store.dispatch('flushProviders');
                        this.$store.dispatch('cancelPayment', this.paymentId).then((updatedPayment) => {
                            this.$store.commit('setReceiptPayment', updatedPayment);
                            this.setupUIVariables();
                        });
                    }
                }]
            });
        },
        setupUIVariables() {
            var totalAmount = MoneyUtils.add.apply(null, _.map(this.payment.reversals, (rev) => {
                    return rev.amount;
                }).concat(this.payment.amount)),
                formattedOrigAmount = this.$formatCurrency(this.payment.amount),
                formattedTotalAmount = this.$formatCurrency(totalAmount),
                scheduled = this.payment.status.toUpperCase() === paymentStates.SCHEDULED,
                canceled = this.payment.status.toUpperCase() === paymentStates.CANCELLED;

            if(this.payment.paymentForm !== null && this.payment.paymentForm.formType.toLowerCase() === 'financing') {
                this.financingVendorInfo = this.$translate('payment.financing.success.financingSuccessHeader', this.payment.paymentForm) + ' ' + this.$translate('payment.financing.success.financeSuccessSubContent', this.payment.paymentForm);
            }

            // set up lineItems for table summary
            this.lineItems = [];

            // balance payment
            this.lineItems.push({
                date: scheduled || canceled ? this.payment.dateScheduled : this.payment.dateProcessed,
                friendlyDialog: this.$translate('payment.friendlyType.' + this.payment.paymentType) +
                        '<br /><span>' +
                        (this.payment.transactionId ? this.$translate('receipt.transactionIdArg', {transactionId: this.payment.transactionId}) : '') + '</span>',
                amountDialog: formattedOrigAmount
            });

            switch(this.payment.status.toUpperCase()){

                case paymentStates.SCHEDULED:
                    this.callOutAmountStatus = this.$translate('receipt.argScheduledCallOut', { amount: formattedOrigAmount});
                    break;


                case paymentStates.DECLINED:
                    this.callOutAmountStatus = this.$translate('receipt.argDeclinedCallout', { amount: formattedOrigAmount});
                    this.summationTotalFormatted = this.$formatCurrency(0);

                    // add declined info
                    this.lineItems.push({
                        date: this.payment.dateProcessed,
                        friendlyDialog: this.$translate('labels.paymentDeclined'),
                        amountDialog: '<span>' + formattedOrigAmount + '</span>'
                    });

                    break;


                case paymentStates.CANCELLED:
                case paymentStates.VOIDED:
                    this.callOutAmountStatus = this.$translate('receipt.argCanceledCallout', { amount: formattedOrigAmount});
                    this.summationTotalFormatted = this.$formatCurrency(0);

                    // add declined info
                    this.lineItems.push({
                        date: this.payment.dateCanceled,
                        friendlyDialog: this.$translate('labels.paymentCanceled'),
                        amountDialog: '<span>' + formattedOrigAmount + '</span>'
                    });

                    break;


                default:
                    // left column values
                    // content values
                    this.callOutAmountStatus = this.$translate('receipt.argPaidCallout' , { amount: formattedTotalAmount});
                    break;
            }

            if (!_.isEmpty(this.payment.reversals)) {
                _.forEach(this.payment.reversals, (reversal) => {
                    var reversalDesc = '';
                    switch(reversal.reason.toUpperCase()){
                        case reversalReasons.REFUND:
                            reversalDesc = MoneyUtils.lessThan(-1 * reversal.amount, this.payment.amount) ? this.$translate('receipt.partialRefund') : this.$translate('receipt.fullRefund');
                            break;
                        case reversalReasons.CHARGEBACK:
                            reversalDesc = this.$translate('receipt.chargeback');
                            break;
                        case reversalReasons.FAILED_ECHECK:
                            reversalDesc = this.$translate('receipt.failedECheck');
                            break;
                        case reversalReasons.BOUNCED_CHECK:
                            reversalDesc = this.$translate('receipt.bouncedCheck');
                            break;
                    }

                    this.lineItems.push({
                        date: reversal.date,
                        friendlyDialog: reversalDesc,
                        amountDialog: this.$formatCurrency(reversal.amount)
                    });
                });

                this.summationTotalFormatted = formattedTotalAmount;
            }

            if (!this.isGuestPay) {
                Promise.all([
                    this.$store.dispatch('getProviderById', this.payment.provider.id),
                    this.$store.dispatch('fetchUnverifiedAccounts'),
                ])
                .then((data) => {
                    var provider = data[0]; //results for first promise.  we don't care about the results from the second promise.

                    //only show notification if the provider has a provider group that matches an unverified ML.  also make sure that the ML has at least one
                    //new acct that will be added to the PatientWallet

                    if((provider.encounterGroupId && this.getHasUnverifiedAccountWithEncounterGroupId(provider.encounterGroupId, true))){
                        this.showLinkableNotification = true;
                        this.FS.event('account-linking prompt presented-receipt');
                    }
                });
            }
        },
    },
    async mounted() {
        this.emitter.on('receipt:cancelPayment', () => {
            this.cancelReceiptPayment();
        });

        await this.$nextTick();

        if (this.isGuestPay) {
            const guestPayment = this.$store.getters.processedGuestPayment;
            if (guestPayment.subAccount != null) {
                this.payment.name = guestPayment.subAccount.patientName;
            } else {
                const bill = guestPayment.account.bills[0];
                this.payment.name =  bill.accountInfo.patientName;
            }
            this.$store.commit('setReceiptPayment', this.payment);
            this.setupUIVariables();
        } else {
            this.$store.dispatch('fetchPaymentDetails', this.paymentId).then((payment) => {
                if(!payment){
                    this.$router.push({name: 'ProvidersSummary'});
                    return;
                }

                if(payment.paymentForm !== null && payment.paymentForm.formType.toLowerCase() === 'card' && !payment.paymentForm.cardType){
                    payment.paymentForm.cardType = CardUtils.getIssuer(payment.paymentForm.first6Digits);
                }

                this.$store.commit('setReceiptPayment', payment);

                this.setupUIVariables();
            }, () => {
                console.warn('Could not load payment with id ' + this.paymentId);  //user is probably not allowed to see payment
                this.$router.push({name: 'Home'});
            });
        }

        window.addEventListener('popstate', () => {
            // If the user clicks the back button, make isGuestPay false and take to bill summary page
            if (this.isGuestPay) {
                this.$store.commit('setIsGuestPay', false);
                this.$router.push({name: 'BillSummary'});
            }
        });
    },
    destroyed() {
        // clear store when we leave the page
        this.$store.commit('setReceiptPayment', null);
        this.emitter.off('receipt:cancelPayment');
        this.$store.commit('setIsGuestPay', false);
    },
};

</script>

<style lang="scss">

</style>