import http from '../../utils/http';
import Estimate from '../../models/Estimate';

const state = {
    currentEstimate: null,
};

const getters = {
    currentEstimate(state) {
        return state.currentEstimate;
    },
    estimateServices(state) {
        if (state.currentEstimate.detailLines && state.currentEstimate.detailLines.length) {
            return state.currentEstimate.detailLines;
        }
        let newSummary = [];
        let services = state.currentEstimate.services;
        if (services) {
            services.forEach((d) => {
                if (d.name && !d.description) {
                    d.description = d.name;
                }
                if (d.cost && !d.patientResponsibility) {
                    d.patientResponsibility = d.cost;
                }
                newSummary.push(d);
            });
        }
        return newSummary;
    }
};

const mutations = {
    setCurrentEstimate(state, val) {
        state.currentEstimate = val;
    },
};

const actions = {
    firstEstimateGetCode({}, token) {
        var url = `estimates/firstEstimateGetCode`;
        return http.post(url, {estimateToken: token}).then((resp) => {
            if (resp && resp.hasData() && resp.getData().success === true) {
                return resp.getData();
            } else {
                throw resp;
            }
        }).catch(error => {throw error});
    },
    markOpenedEstimate({}, token) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { token },
            url: 'estimates/markOpened',
            allowNullData: true,
        });
    },
    linkEstimate({dispatch, commit, getters}, estimate){
        var url = `estimates/link`;
        const e = Object.assign({}, estimate);
        // estimate.providerDetails.accounts[0].estimates is in array of estimates including this 
        // one. This causes a circular reference. Usually this is fine and the browser handles it,
        // but when we try to parse it to send in an ajax request, it blows up. Not blowing up 
        // would cause us to send an infinite amount of data to the backend, crashing our 
        // patientapp pods, overloading ec2 instances, bringing all of aws, and thus, the internet 
        // down. An infinite amount of data also requires infinite energy. This would quickly 
        // deplete the world energy reserves and cause global social and ecconomic collpase. That 
        // being said, don't remove this...
        if (e.hasOwnProperty('providerDetails') && e.providerDetails.hasOwnProperty('accounts')) {
            delete e.providerDetails.accounts;
        }
        return http.post(url, {estimate: e}).then((resp) => {
            if (resp && resp.hasData()) {
                var estimate = new Estimate(resp.getData());
                estimate.linked = true;
                commit('setCurrentEstimate', estimate);
                if (getters.currentPatientUserId) {
                    dispatch('syncProviders');
                }
                return estimate;
            } else {
                throw resp;
            }
        }).catch(error => {throw error});
    },
    findNewEstimate({commit}, {token, verificationCode, guarantorLast4SSN, guarantorDOB, zip}) {
        var url = `estimates/findNew`;
        return http.post(url, {token, verificationCode, guarantorLast4SSN, guarantorDOB, zip}).then((resp) => {
            if (resp && resp.hasData()) {
                var estimate = new Estimate(resp.getData());
                estimate.token = token;
                commit('setCurrentEstimate', estimate);
                return estimate;
            } else {
                throw resp;
            }
        }).catch(error => {throw error});
    },
    fetchEstimate({ commit }, id) {
		const url = `estimates/id/${id}`;
        return http.post(url, {
            id: id
        }).then(function(resp) {
            if (resp && resp.hasData()) {
                const estimate = new Estimate(resp.getData());
            	commit('setCurrentEstimate', estimate);
            	return estimate;
            }
            commit('setCurrentEstimate', null);
            return null;
        }).catch((er) => {
        	throw(er);
        });
	},
    findAuthOptionsEstimate({}, token) {
        const url = `token?token=${token}`;
        return http.get(url).then((resp) => {
            if (resp && resp.hasData() && resp.getData().type === 'DFE') {
                return resp.getData();
            }
            throw resp;
        }).catch(error => { throw error; });
    },
};

export default {
    state, getters, mutations, actions,
};
