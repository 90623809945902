<template>
<div class="settings-left-column">
    <div>
        <h2 class="header-hidden">Settings Sections</h2>
        <div class="flow-link-set">
            <h3>
                <a :class="{ current: 'SettingsContact' === route }" @click="goToState('SettingsContact')" class="flow-link" data-testid="settings-contact">
                    {{ $translate('setting.menu.contact') }}
                </a>
            </h3>
            <h3>
                <a :class="{ current: 'SettingsSavedPaymentMethods' === route }" @click="goToState('SettingsSavedPaymentMethods')" class="flow-link" data-testid="settings-savedpaymentmethods">
                    {{ $translate('setting.menu.savedPaymentMethods') }}
                </a>
            </h3>
            <h3>
                <a :class="{ current: 'SettingsAccountManagement' === route }" @click="goToState('SettingsAccountManagement')" class="flow-link" data-testid="settings-accountmanagement">
                    {{ $translate('setting.menu.accountManagement') }}
                </a>
            </h3>
        </div>
    </div>
</div>
</template>
<script>

export default {
    name: 'SettingsLeftColumn',
    computed: {
        route() {
            return this.$route.name;
        }
    },
    methods: {
        goToState(state) {
            this.$router.push({name: state});
		},
    }
};
</script>