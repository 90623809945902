import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import vueEventTransmitter  from './utils/vueEventTransmitter';
import { formatCurrency, formatDate, translate } from './utils/utils';
import SimpleModal from './components/SimpleModal.vue';

if (process.env.NODE_ENV === 'development') {
  window.vueEventTransmitter = vueEventTransmitter;
}

// Initialize config from environment variables
const preferredLanguage = ['en', 'es'].includes(localStorage.getItem('currentLanguage'))
  ? localStorage.getItem('currentLanguage')
  : 'en';
store.commit('setConfig', {
  apiUrl: process.env.VUE_APP_API_URL,
  backendless: false,
  env: process.env.NODE_ENV,
  vueEnv: process.env.VUE_APP_ENV,
  isCypress: 'true' === process.env.VUE_APP_CYPRESS,
  languages: ['en', 'es'],
  pendoEnabled: false,
  preferredLanguage,
  releaseFolder: '/',
  rootUrl: process.env.VUE_APP_BASE_URL +  '/',
});

let paymentSource = new URLSearchParams(window.location.search).get('source');
store.commit('setPaymentSource', { origin: paymentSource });

if (window.Cypress) {
  window.store = store;
}

const app = createApp(App);

app.use(store);
app.use(router);
app.use(vuetify);

app.component('SimpleModal', SimpleModal);

app.config.globalProperties.$translate = translate;
app.config.globalProperties.$formatDate = formatDate;
app.config.globalProperties.$formatCurrency = formatCurrency;
app.config.globalProperties.emitter = vueEventTransmitter;

app.mount('#app');
