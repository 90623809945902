import http from './../../utils/http';
import _ from 'lodash';
import AppStore from './AppStore';

function step1(payment) {
    const payload = {
        isNewContract: 1,
        secureCode: payment.secureCode,
        estimateId: payment.estimateId,
        method: payment.method.card ? 'card' : 'eCheck',
        paymentData: payment.amount ? {
            amount: payment.amount,
            paymentType: payment.amountOptionType,
            installmentCount: payment.installmentCount,
            date: payment.date
        } : null,
        agreementData: payment.agreementAmount ? {
            amount: payment.agreementAmount
        } : null,
        voucherNumber: payment.voucherNumber,
        paymentSource: AppStore.state.paymentSource,
        isGuestPay: payment.isGuestPay ? payment.isGuestPay : false,
        email: payment.isGuestPay ? payment.email : null
    };

    function tryPrep(){
        return http.post('payments/prepGateway', payload);
    }

    window.FS.event('payment gateway step1Start');

    return tryPrep().catch(function(resp){

        // if we did not have a network error,
        // pass through the error
        const isNetworkError = resp.status && !(resp.status >= 200 && resp.status < 400)
        if (!isNetworkError) {
            window.FS.log('error', 'payment gateway step1Ended');
            throw resp;
        }

        // if we have a network error
        // we will retry it once
        console.error('Network error, could not prepGatway, trying again...');

        window.FS.event('payment gateway step1Retry');

        return tryPrep().catch((resp) => {
            window.FS.log('error', 'payment gateway step1Ended');
            throw resp;
        });
    });
};

function step3(ackDetails, commit) {
    window.FS.event('payment gateway step3Start');

    return http.post('payments/confirm', ackDetails).then((resp) => {
        if (resp && resp.hasData()) {
            commit('setReceiptPayment', resp.getData());
            return resp.getData();
        }
    }).catch(function(resp) {

        // if we did not have a network error,
        // or this is attempt 2+
        // pass through the error
        const isNetworkError = resp.status && !(resp.status >= 200 && resp.status < 400)
        if (!isNetworkError || state.acknowledgeResumeData !== null) {
            if (state.acknowledgeResumeData) {
                window.FS.log('error', 'payment gateway step3Resume');
            }
            window.FS.log('error', 'payment gateway step3Ended');
            throw resp;
        }

        console.error('Network error, could not send payment acknowledgement. Request for retry');

        window.FS.event('payment gateway step3RetryRequested');

        state.setAcknowledgeResumeData = ackDetails;

        throw 'GATEWAY_RETRY_REQUESTED';
    });
};


const state = {
    newPaymentId:          null,
    newPlanId:             null,
    acknowledgeResumeData: null,
    newPaymentAgreementId: null,
    newTransactionId:      null,
};

const getters = {
    step1Function() {
        return step1;
    },
    step3Function() {
        return step3;
    },
};

const mutations = {
    setNewPaymentId(state, val) {
        state.newPaymentId = val;
    },
    setNewPlanId(state, val) {
        state.newPlanId = val;
    },
    setAcknowledgeResumeData(state, val) {
        state.acknowledgeResumeData = val;
    },
    setNewPaymentAgreementId(state, val) {
        state.newPaymentAgreementId = val;
    },
    setNewTransactionId(state, val) {
        state.newTransactionId = val;
    },
};

const actions = {
    // Called when we want to submit a process a payment
    // It will decide what form type to process the payment
    // as and will handle retries internally.
    processPayment({ commit, dispatch, state }, { payment, resuming }) {
        if (!resuming && !payment) { 
            throw new Error('GatewayService was passed an invalid payment object');
        }
        if (!resuming && (!payment.method.savedMethod && !payment.method.card && !payment.method.eCheck)){
            throw new Error('Unable to process payment without a payment.method');
        }

        // we we are starting a new process
        // clear the previous attempt if it existed
        if (!resuming){
            commit('setAcknowledgeResumeData', null);
        }

        if (!resuming && payment.method.savedMethod) {
            window.FS.event('payment gateway startingSavedFormProcessing');

            const payload = {
                isNewContract: 1,
                secureCode: payment.secureCode,
                estimateId: payment.estimateId,
                paymentFormId: payment.method.savedMethod.id,
                paymentData: payment.amount ? {
                    amount: payment.amount,
                    paymentType: payment.amountOptionType,
                    installmentCount: payment.installmentCount,
                    date: payment.date
                } : null,
                agreementData: payment.agreementAmount ? {
                    amount: payment.agreementAmount
                } : null,
                voucherNumber: payment.voucherNumber,
                paymentSource: AppStore.state.paymentSource,

            };

            return http.post('payments', payload).then((resp) => {
                if (resp && resp.hasData()){
                    window.FS.log('log', 'success payment gateway insertSaved');
                    return resp.getData();
                } else {
                    window.FS.log('error', 'payment gateway insertSaved');
                    console.error('Payment process came back without a paymentId');
                    throw new Error('UNKNOWN_ERROR');
                }
            }).catch((resp) => {
                window.FS.log('error', 'payment gateway insertSaved');
                throw resp;
            });
        } else {
            if (resuming) {
                window.FS.event('payment gateway resuming3Step');
            } else {
                window.FS.event('payment gateway starting3Step');
            }

            // regarding acknowledge
            return dispatch('threeStepRedirect', { payment, resuming }).then(() => {
                return {
                    paymentId: state.newPaymentId,
                    planId: state.newPlanId,
                    paymentAgreementId: state.newPaymentAgreementId,
                    transactionId: state.newTransactionId
                };
            })
            .finally(() => {
                // clean up service variables
                // if we are not expecting to need them
                // for a acknowledgement resume process
                if (!state.acknowledgeResumeData || resuming){
                    commit('setNewPaymentId', null);
                    commit('setNewTransactionId', null);
                    commit('setNewPaymentAgreementId', null);
                }
            });
        }
    },
    // When we call this#process if it gets to the confirmation
    // step it will request that the user manually resubmit the form
    // this is to buy us some time between NMI being ready and
    // our server call
    resumePayment({ dispatch, state }) {
        if (!state.acknowledgeResumeData){
            throw new Error('UNKNOWN_ERROR');
        }
        return dispatch('processPayment', { resuming: true });
    },
    step2({ dispatch }, { prepResp, payment }) {

        function tryPostToGateway() {
            return dispatch('postToGateway', { gatewayDetails: prepResp, payment: payment });
        }
    
        window.FS.event('payment gateway step2Start');
    
        return tryPostToGateway().catch(function(resp){
    
            // if we did not have a network error,
            // pass through the error
            const isNetworkError = resp.status && !(resp.status >= 200 && resp.status < 400)
            if (!isNetworkError) {
                window.FS.log('error', 'payment gateway step2Ended');
                throw resp;
            }
    
            console.error('Network error, could not post to gateway, trying again...');
    
            window.FS.event('payment gateway step2Retry');
    
            return tryPostToGateway().catch((resp) => {
                window.FS.log('error', 'payment gateway step2Ended');
                throw resp;
            });
        });
    },
    threeStepRedirect({ commit, dispatch, state }, { payment, resuming }) {
        const acknowledgeResumeData = state.acknowledgeResumeData;

        // if we are resuming we are saying that we want
        // to skip the step1 and step2 parts and
        // only complete step3
        if (resuming && acknowledgeResumeData !== null) {
            window.FS.event('payment gateway step3ResumeStart');
            acknowledgeResumeData.isGuestPay = payment.isGuestPay;
            return step3(acknowledgeResumeData, commit).then((resp) => {
                if (resp && resp.hasData()){
                    return resp.getData();
                }
                window.FS.log('log', 'success payment gateway step3Resume');
            });
        }

        return step1(payment).then((resp) => {

            if (!resp || !resp.hasData()) {
                throw new Error('PrepGateway responded with no data');
                return;
            }

            window.FS.log('log', 'success payment gateway step1Ended');

            commit('setNewPaymentId', resp.getData().paymentId);
            commit('setNewPlanId', resp.getData().planId);
            commit('setNewTransactionId', resp.getData().transactionId);
            commit('setNewPaymentAgreementId', resp.getData().paymentAgreementId);
    
            return dispatch('step2', { prepResp: resp.getData(), payment });
        }).then((gatewayRequestProperties) => {
            window.FS.log('log', 'success payment gateway step2Ended');

            const ackDetails = _.assign({
                paymentId: state.newPaymentId,
                paymentAgreementId: state.newPaymentAgreementId,
                // transaction is needed because nmi wont provide
                // a transactionId if it failed on the third step
                // we need this for tracking that state
                transactionId: state.newTransactionId,
                isGuestPay: payment.isGuestPay,
                paymentForm: {}
            }, (gatewayRequestProperties || {}));

            if (payment.method.card) {
                ackDetails.paymentForm.method = 'card';
                ackDetails.paymentForm.first6Digits = payment.method.card.number.toString().slice(0, 6);
                ackDetails.paymentForm.last4Digits = payment.method.card.number.toString().slice(-4);
                ackDetails.paymentForm.exp = payment.method.card.expiration;
                ackDetails.paymentForm.saveMethod = payment.method.card.saveCard;
            } else {
                ackDetails.paymentForm.method = 'echeck';
                // backend needs routing number so they don't have to parse
                // the gateway response
                ackDetails.paymentForm.routing = payment.method.eCheck.routing;
                ackDetails.paymentForm.saveMethod = payment.method.eCheck.saveEcheck;
            }

            return step3(ackDetails, commit);
        }).then(() => {
            window.FS.log('log', 'success payment gateway step3Ended');
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
