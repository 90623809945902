<template>
  <div id="app-wrap">
    <header class="header" :class="{ 'with-notification-banner': shouldShowNotificationBanner }">
      <div class="container">
        <div class="header-persistent-content">
          <!-- negative tabindex to prevent accidental navigation home inside paymentflow -->
          <span tabindex="-1" class="header-home-link" @click="goHome()">
            <patientco-logo color="white" id="header-logo"></patientco-logo>
            <patientco-text color="white" id="header-text"></patientco-text>
          </span>
        </div>
      </div>
      <notification-banner v-if="shouldShowNotificationBanner" :notification="visibleNotificationBanner" @dismiss="notificatioNBannerDismissed = true"></notification-banner>
    </header>

    <div class="back-header sso-header" v-if="showSSOHeader && !isBreadCrumbVisible">
      <div class="context-helper">
        <div class="bread-crumbs" @click="returnToReferrer()">
          <base-icon icon="mdi-chevron-left"></base-icon>
          <span class="back-header">
            {{ $translate('actions.backToSSO', { friendlySSOEnvName }) }}
          </span>
        </div>
      </div>
    </div>

    <div class="context-helper" v-show="isBreadCrumbVisible">
      <div class="bread-crumbs" @click="click()">
        <base-icon icon="mdi-chevron-left"></base-icon>
        {{ currentBreadCrumbLabel }}
      </div>
    </div>

    <div id="main-content" v-if="shouldUseDocumentsLayout">
      <div id="documents-content" class="view-transition">
        <router-view name="content"></router-view>
      </div>
    </div>

    <div id="main-content" v-else-if="!shouldShowNewLayouts">
		  <div id="left-column-persist" class="view-transition">
        <router-view name="leftColumnPersist"></router-view>
      </div>
	
      <div id="main-column" view-scroll-top="true" class="content view-transition">
          <router-view name="content"></router-view>
      </div>

      <div id="left-column" class="view-transition">
        <router-view name="leftColumn"></router-view>
      </div>
    </div>

    <div id="main-content-v2" v-else>
        <div id="left-column-persist-v2" class="view-transition">
          <router-view name="leftColumnPersist"></router-view>
        </div>
    
        <div id="main-column-v2"
          view-scroll-top="true" class="content view-transition">
            <router-view name="content"></router-view>
          </div>

        <div id="left-column-v2" class="view-transition">
          <router-view name="leftColumn"></router-view>
        </div>
    </div>
  </div>
</template>

<script>
import NotificationBanner from './../components/NotificationBanner.vue';
import PatientcoLogo from './../components/PatientcoLogo.vue';
import PatientcoText from './../components/PatientcoText.vue';
import BaseIcon from './../components/BaseIcon.vue';

export default {
  name: 'Home',
  components: {
    NotificationBanner,
    PatientcoLogo,
    PatientcoText,
    BaseIcon
  },
  data: () => ({
    notificatioNBannerDismissed: false,
  }),
  computed: {
    currentBreadCrumbLabel() {
      return this.$store.getters.currentBreadCrumbLabel;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    friendlySSOEnvName() {
      return this.$store.getters.friendlySSOEnvName;
    },
    initialHistoryLength() {
      return this.$store.getters.initialHistoryLength;
    },
    isBreadCrumbVisible() {
      return this.$store.getters.isBreadCrumbVisible;
    },
    showSSOHeader() {
      return this.$store.getters.showSSOHeader;
    },
    visibleNotificationBanner() {
      return this.$store.getters.visibleNotificationBanner;
    },
    brandingConfig() {
      return this.$store.getters.brandingConfig;
    },
    shouldShowNewLayouts() {
      if (this.$route.matched.length > 1) {
        if (this.$route.matched[1].meta && this.$route.matched[1].meta.useNewLayouts == false) {
          return false;
        }
      }
      return true;
    },
    shouldUseDocumentsLayout() {
      if (this.$route.matched.length > 1) {
        return this.$route.matched[1].meta.useDocumentsLayout;
      }
      return false;
    },
    hasExternalAccountManagement() {
      if (this.brandingConfig && this.brandingConfig.externalAccountManagement) {
          return true;
      }
      return false;
    },
    shouldShowNotificationBanner() {
      if (this.visibleNotificationBanner && !this.notificatioNBannerDismissed && !this.hasExternalAccountManagement) {
        if (this.$route.matched.length > 1) {
          if (this.$route.matched[1].meta && this.$route.matched[1].meta.showNotificationBanner !== undefined) {
            return this.$route.matched[1].meta.showNotificationBanner;
          }
        }
      }
      return false;
    },
  },
  methods: {
    click() {
      this.$store.dispatch('breadCrumbClick');
    },
    returnToReferrer() {
      window.history.go(this.initialHistoryLength - window.history.length - 1);
    },
    goHome() {
      this.$router.push({name: 'ProvidersSummary'});
    }
  },
};
</script>
<style lang="scss">
.header-home-link {
  cursor: pointer;
}
</style>