<template>
    <div v-if="showComponent" class="vuetify appointment-info-card" data-testid="appointment-info-card">
        <content-card :headerTitle="$translate('labels.appointmentInfo')" headerIcon="mdi-calendar-clock-outline" padded>
            <AppointmentInfo :appointmentInfo="appointmentInfo"></AppointmentInfo>
        </content-card>
    </div>
</template>

<script>
import AppointmentInfo from './AppointmentInfo.vue';
import ContentCard from './ContentCard.vue';
export default {
    name: 'AppointmentInfoCard',
    components: {
        AppointmentInfo,
        ContentCard,
    },
    props: {
        appointmentInfo: Object,
    },
    computed: {
        showComponent() {
        	return this.appointmentInfo && (this.appointmentInfo.providerLocation || this.appointmentInfo.appointmentDate || this.appointmentInfo.appointmentTime || this.appointmentInfo.inNetworkProvider);
        }
    }
};
</script>
