import http from '../../utils/http';
import axios from 'axios';
import _ from 'lodash';
import { formatDate, translate } from './../../utils/utils.js';

const state = {
    messages: [],
    fetched: false,
    messagingDisabled: false,
    threadId: null,
    thread: null,
};

const getters = {
    reasonsObj() {
        return [{
            id: 2,
            code: 'specificCharge',
            contactName: translate('contact.questionAbout.reasons.specificCharge'),
            messageName: translate('message.reasons.specificCharge')
        }, {
            id: 7,
            code: 'paymentPlan',
            contactName: translate('contact.questionAbout.reasons.paymentPlan'),
            messageName: translate('message.reasons.paymentPlan')
        }, {
            id: 3,
            code: 'duplicate',
            contactName: translate('contact.questionAbout.reasons.duplicate'),
            messageName: translate('message.reasons.duplicate')
        }, {
            id: 4,
            code: 'missingPayments',
            contactName: translate('contact.questionAbout.reasons.missingPayments'),
            messageName: translate('message.reasons.missingPayments')
        }, {
            id: 1,
            code: 'updateInsurance',
            contactName: translate('contact.questionAbout.reasons.updateInsurance'),
            messageName: translate('message.reasons.updateInsurance')
        }, {
            id: 8,
            code: 'insuranceQuestion',
            contactName: translate('contact.questionAbout.reasons.insuranceQuestion'),
            messageName: translate('message.reasons.insuranceQuestion')
        }, {
            id: 10,
            code: 'itemizedBill',
            contactName: translate('contact.questionAbout.reasons.itemizedBill'),
            messageName: translate('message.reasons.itemizedBill')
        }, {
            id: 9,
            code: 'financialAssistance',
            contactName: translate('contact.questionAbout.reasons.financialAssistance'),
            messageName: translate('message.reasons.financialAssistance')
        }, {
            id: 11,
            code: 'estimate',
            contactName: translate('contact.questionAbout.reasons.estimate'),
            messageName: translate('message.reasons.estimate'),
            requiredFeatures: ['estimates']
        }, {
            id: 6,
            code: 'other',
            contactName: translate('contact.questionAbout.reasons.other'),
            messageName: translate('message.reasons.other')
        }]
    },
    contactReasons(state, getters) {
        return _.map(getters.reasonsObj, function(reason) {
            return _.pick(reason, 'id', 'contactName');
        });
    },
    contactReasonById: (state, getters) => (id) => {
        var reason = _.find(getters.contactReasons, { 'id': id });
        if (reason) {
            return reason.contactName;
        }
        return reason;
    },
    reasonByCode: (state, getters) => (code) => {
        return _.find(getters.reasonsObj, { 'code': code });
    },
    threadId(state) {
        return state.threadId;
    },
    thread(state) {
        return state.thread;
    },
    messages(state) {
        return state.messages;
    },
    fetched(state) {
        return state.fetched;
    },
    messagingDisabled(state) {
        return state.messagingDisabled;
    }
};

const mutations = {
    setMessages(state, val) {
        state.messages = val;
    },
    setMessagesFetched(state, val) {
        state.fetched = val;
    },
    setMessagingDisabled(state, val) {
        state.messagingDisabled = val;
    },
    setThreadId(state, val) {
        state.threadId = val;
    },
    setThread(state, val) {
        state.thread = val;
    },
};

const actions = {
    fetchMessages({commit}) {
        commit('setMessagesFetched', false);
        return http.get('messages').then((res) => {
            if (res && res.hasData()) {
                if (res.getData()) {
                    commit('setMessages', res.getData());
                    commit('setMessagesFetched', true);
                    return res.getData();
                } else {
                    //if response is null it sets messages to empty
                    commit('setMessages', []);
                    commit('setMessagesFetched', true);
                    return null;
                }
            } else {
                //returns empty data if request is successful but response is not what's expected
                return null;
            }
        }).catch((er) => {
            throw(er);
        });
    },

    getMessages({state, dispatch}, {forceFetch}) {
        return new Promise((resolve, reject) => {
            if(!state.fetched || forceFetch){
                dispatch('fetchMessages').then(function(messages){
                    // get some complex values in a friendlier form
                  
                    _.forEach(state.messages, function(message){
                        //passes in the format the time is in, then formats in the time format that we want
                        //TODO: move these to a central formatting methods location
                        message.friendlyUpdateTime = formatDate(message.timeUpdated, 'MMM DD, YYYY');
                        message.friendlyAccountNumber = "Account # " + message.accountNumber;
                    });
                    resolve(state.messages);
                })
                .catch(function(er){
                    console.error(er);
                    reject(er);
                });
            } else {
                resolve(state.messages);
            }
        })
    },

    getDetails({state}, {id}){
        id = _.parseInt(id);

        return http.get(`messages/${id}/details`, {params: {id: id}}).then(function(resp){
            var _threadMessages = null;
            
            if(resp && resp.hasData()) {
                // try to cache this data
                _.forEach(state.messages, function(thread){

                    //todo: look into using a merge with a empty thread obj so that we can
                    // cache this response even if we haven't performed getMessages yet
                    if(thread.id === id){
                        _threadMessages = thread.threadMessages = resp.getData();

                    }
                });

                if(!_threadMessages){
                    _threadMessages = resp.getData();
                }

                _.forEach(_threadMessages, function(message){
                    message.friendlyMessageTime = formatDate(message.messageTime, 'MMM DD, YYYY');
                    //$filter('dateFormat')(message.messageTime, 'MMM d, yyyy');
                });

            } else {
                _threadMessages = [];
            }

            return _threadMessages;
        });
    },

    sendMessage({}, {messageDetails, threadId}) {
        var url = '/api/services/v1/messages'
        var body = {
            message: messageDetails.message,
            attachments: messageDetails.attachments
        }
        if (threadId) {
            threadId = _.parseInt(threadId);
            body.id = threadId;
            url = `/api/services/v1/messages/${threadId}/details`;
        } else {
            body.reason = messageDetails.reason;
            body.accountId = messageDetails.accountId;
            if (messageDetails.secureCode) {
                body.secureCode = messageDetails.secureCode;
            }
        }

        var fd = new FormData();
        for (var prop in body) {
            if (Object.prototype.hasOwnProperty.call(body, prop)) {
                if (body[prop] instanceof Array) {
                    // prop is an array
                    if (body[prop].length && body[prop][0] instanceof File) {
                        // prop is a non-empty array whos first element is a File
                        if (body[prop].length == 1) {
                            fd.append(prop, body[prop][0]);
                        } else {
                            _.forEach(body[prop], function(file, i) {
                                fd.append('file' + i, file);
                            });
                        }
                    }
                } else {
                    fd.append(prop, body[prop]);
                }
            }
        }

        var xsrfToken = localStorage.getItem('XSRF-TOKEN');
        fd.append('XSRF-TOKEN', xsrfToken);


        var tmp = new Promise((resolve, reject) => {
            axios.post(url, fd, {
                headers: {
                    'Content-Type': undefined
                }
                }).then((resp) => {
                if(resp && resp.data){
                    if (threadId) {
                        if (resp.data.data) {
                            resolve(resp.data.data);
                        } else {
                            console.error('Unexpected response: ', resp);
                            reject(resp);
                        }
                    } else {
                        if (resp.data.data) {
                            resolve(resp.data.data.messageId);
                        }
                        else {
                            console.error('Unexpected response:', resp);
                            reject(resp); 
                        }
                    }
                } else {
                    console.error('Unexpected response:', resp);
                    reject(resp);
                }
            }).catch((er) => {
                if (threadId) {
                    console.error('Error replying to message:', er);
                    reject(er);
                } else {
                    console.error('Error sending message:', er);
                    reject(er);
                }
            });
        });
        return tmp;
        
    },

    refreshVirusScanStatus({}, attachmentIds) {
        return http.getDataOrThrow({
            method: 'POST',
            data: {
                attachmentIds: attachmentIds,
            },
            url: 'messages/refreshVirusScanStatus',
        });
    },

    setThreadId({ commit }, threadId) {
        commit('setThreadId', threadId);
    },

    clearMessagesData({commit}) {
        commit('setMessages', []);
        commit('setMessagesFetched', false);
    },
    
};

export default {
    state, getters, mutations, actions,
};
