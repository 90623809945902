<template>
    <base-dialog @on-close="close">
    <template #title>{{ $translate('videoBill.title') }}</template>
    <template #text> 
        <span class="feedback-modal-text-content">
            <div>
                <base-icon @click="thumbsUp()" id="thumbsUp" class="thumb-background" icon="mdi-thumb-up-outline"></base-icon>
                <base-icon @click="thumbsDown()" id="thumbsDown" class="thumb-background" icon="mdi-thumb-down-outline"></base-icon>
            </div>
            <div v-if="badReview" id="badReview">
                <h3>{{ $translate('videoBill.negativeSubTitle') }}</h3>
                <base-checkbox v-model="videoFeedback" :label="$translate('videoBill.notInformative')" value="Not Informative" hide-details id="notInformative"></base-checkbox>
                <base-checkbox v-model="videoFeedback" :label="$translate('videoBill.inaccurate')" value="Inaccurate" hide-details id="inaccurate"></base-checkbox>
                <base-checkbox v-model="videoFeedback" :label="$translate('videoBill.tooLong')" value="Too Long" hide-details id="tooLong"></base-checkbox>
                <base-textarea v-model="comments" :placeholder="$translate('videoBill.comments')" rows="2" row-height="25" auto-grow id="comments"></base-textarea>
            </div>
        </span>
    </template>
    <template #actions>
        <base-btn class="cancel-btn" variant="text" @click="close()">{{ $translate('actions.cancel') }}</base-btn>
        <base-btn class="continue-btn v-button-primary" variant="text" @click="submit();">{{ $translate('actions.submit') }}</base-btn>
    </template>
    </base-dialog>
</template>

<script>
import BaseDialog from './BaseDialog.vue';
import BaseIcon from './BaseIcon.vue';
import BaseBtn from './BaseBtn.vue';
import BaseCheckbox from './BaseCheckbox.vue';
import BaseTextarea from './BaseTextarea.vue';

export default {
    name: 'VideoBillFeedbackDialog',
    components: {
        BaseBtn,
        BaseDialog,
        BaseIcon,
        BaseCheckbox,
        BaseTextarea
    },
    data: () => ({
        badReview: false,
        videoFeedback: [],
        comments: '',
    }),
    computed: {
        currentProvider() {
            return this.$store.getters.currentProvider;
        },
        currentUser() {
            return this.$store.getters.currentUser;
        },
    },
    methods: {
        close() {
            this.$emit('on-close-cancel');
        },
        submit() {
            this.$store.dispatch('addVideoBillFeedback', { 
                providerId: this.currentProvider.id, 
                patientUserId: this.currentUser ? this.currentUser.patientUserId : null, 
                score: this.badReview ? 0 : 1, 
                comment: this.comments,
                structuredFeedback: JSON.stringify(this.videoFeedback),
            }).then(() => {
                this.$emit('on-close');
            });
        },
        thumbsUp() {
            var thumbsUp = document.getElementById("thumbsUp");
            thumbsUp.classList.add("selected");
            var thumbsDown = document.getElementById("thumbsDown");
            thumbsDown.classList.remove("selected");
            this.badReview = false;
        },
        thumbsDown() {
            var thumbsDown = document.getElementById("thumbsDown");
            thumbsDown.classList.add("selected");
            var thumbsUp = document.getElementById("thumbsUp");
            thumbsUp.classList.remove("selected");
            this.badReview = true;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';
.feedback-modal-text-content {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.thumb-background {
    border-radius: 100%;
    border-style: solid;
    border-width: 3px;
    border-color: $global-color-secondary;
    height: 70px !important;
    width: 70px !important;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 40px !important;
}

.thumb-background:hover {
    background-color: $global-color-secondary;
    color: white !important;
}

.selected {
    background-color: $global-color-secondary;
    color: white !important;
}

#badReview {
    padding-top: 30px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
}
</style>