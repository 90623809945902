<template>
    <div class="vuetify bill-summary-sub-account-card" data-testid="bill-summary-sub-account-card">
        <content-card clickable padded>
            <div class="data-grid">
                <div class="row">
                    <div class="col-left appointment-date fs-block" v-if="appointmentDate">
                        {{ $formatDate(appointmentDate, 'MM/DD/YYYY') }}
                    </div>
                </div>
            </div>
            <div class="data-grid">
                <div class="row">
                    <div class="col-left">
                        <div v-if="patientName" class="patient-name fs-block">{{ patientName }}</div>
                        <div class="provider-location">{{ providerLocation }}</div>
                    </div>
                    <div class="col-right amount-due">
                        {{ $formatCurrency(amountDue) }}
                    </div>
                </div>
            </div>
        </content-card>
    </div>
</template>

<script>
import SubAccount from './../models/SubAccount';

import SubAccountDetailsSheet from './SubAccountDetailsSheet.vue';
import ContentCard from './ContentCard.vue';

export default ({
    name: 'BillSummarySubAccountCard',
    components: {
        SubAccountDetailsSheet,
        ContentCard,
    },
    props: {
        subAccount: SubAccount,
    },
    computed: {
        appointmentDate() {
            if (this.subAccount) {
                return this.subAccount.serviceDate;
            }
            return null;
        },
        patientName() {
            if (this.subAccount) {
                return this.subAccount.patientName;
            }
            return null;
        },
        providerLocation() {
            if (this.subAccount) {
                return this.subAccount.facilityName;
            }
            return null;
        },
        amountDue() {
            if (this.subAccount) {
                return this.subAccount.amountDue;
            }
            return null;
        },
    },
});
</script>
<style lang="scss">
@import './../styles/variables.scss';
.bill-summary-sub-account-card {
    .patient-name {
        font-size: 1.4rem;
        line-height: 1.6rem;
        letter-spacing: 0.05rem;
        color: $global-color-font-new;
        padding-top: 0.5rem;
        font-weight: 500;

        @extend .two-line-then-ellipsis;

        @include wider-than-tablet {
            font-size: 2rem;
            line-height: 2.4rem;
            padding-top: 1.5rem;
            padding-left: 2.2rem;
        }
    }

    .amount-due {
        font-size: 1.6rem; // mobile font
        line-height: 2.4rem;
        letter-spacing: 0.05rem;
        font-weight: 500;
        color: $global-color-font-new;
        padding-top: 1.2rem;

        @include wider-than-tablet {
            font-size: 2rem;
            line-height: 2.4rem;
            padding-top: 1rem;
            padding-right: 2.2rem;
        }
    }

    .appointment-date {
        font-size: 1.2rem; // mobile font
        line-height: 1.6rem;
        letter-spacing: 0.25px;
        color: $global-color-font-new;
        padding-top: 1.5rem;

        @include wider-than-tablet {
            font-size: 1.4rem;
            line-height: 1.6rem;
            padding-top: 2.5rem;
            padding-left: 2.2rem;
        }
    }

    .provider-location {
        font-size: 1.2rem; // mobile font
        line-height: 1.6rem;
        letter-spacing: 0.25px;
        color: $global-color-font-new;
        padding-top: 0.5rem;

        @extend .two-line-then-ellipsis;

        @include wider-than-tablet {
            font-size: 1.6rem;
            line-height: 2rem;
            padding-top: 1.5rem;
            padding-left: 2.2rem;
            padding-bottom: 1.5rem;
        }
    }

    .bill-summary-sub-account-icon {
        position: absolute;
        right: 0.8rem;
        margin-right: 0.7rem;

        @include wider-than-tablet {
            margin-top: 0.5rem;
            margin-right: 1rem;
        }
    }

    .two-line-then-ellipsis {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: unset;
    }
}
</style>