<template>
    <div class="message-details-attachment">
        <div class="file">
            <base-icon icon="mdi-paperclip"></base-icon>
            <a v-if="'clean' === attachment.virusScanStatus"
                :href="fullUrl"
                target="_blank" :title="attachment.displayName !== attachment.fileName ? attachment.fileName : ''">
                {{attachment.displayName}} ({{attachment.displaySize}}) 
            </a>
            
            
            <a v-if="'clean' !== attachment.virusScanStatus" :title="attachment.displayName !== attachment.fileName ? attachment.fileName : ''">
                {{attachment.displayName}} ({{attachment.displaySize}})
            </a>
        </div>
        <div v-if="'pending' === attachment.virusScanStatus" class="virus-scan-notice">
            <base-icon icon="custom:SpinnerBlueSVG"></base-icon>
            {{ $translate('messages.virusScan.pending') }}
        </div>
        <div v-if="'infected' === attachment.virusScanStatus" class="virus-scan-notice">
            {{ $translate('messages.virusScan.infected') }}
        </div>
    </div>
</template>

<script>
import BaseIcon from './BaseIcon.vue';

    export default {
        name: 'MessageDetailsAttachment',
        props: {
            attachment: Object,
        },
        components: {
            BaseIcon
        },
        computed: {
            fullUrl: function() {
                return 'api/services/v1/messages/downloadAttachment/' + this.attachment.id; 
            }
        }
    };
</script>
